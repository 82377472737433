import {headers} from 'src/services/auth';

const baseURL = (merchantId) => `${window.INTEGRATIONS_ENGINE_URL}integrations/api/merchants/${merchantId}/preferences`;

export function getReviewTrackersStatus(merchantId) {
  const url = new URL(`${baseURL(merchantId)}/review-trackers`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function activate(merchantId) {
  const url = new URL(`${baseURL(merchantId)}/activate-review-trackers`);

  return fetch(url.toString(), {
    method: 'PUT',
    headers: headers,
  }).then(result => result);
}

export function deactivate(merchantId) {
  const url = new URL(`${baseURL(merchantId)}/deactivate-review-trackers`);

  return fetch(url.toString(), {
    method: 'PUT',
    headers: headers
  }).then(result => result);
}