<script>
  import Card, { Content } from "@smui/card";
  import IconButton from "@smui/icon-button";
  import DataTable from "tttl-core-ui/src/components/DataTable.svelte";
  import Pagination from "tttl-core-ui/src/components/Pagination.svelte";
  import { Body, Cell, Head, Row, Label } from "@smui/data-table/styled";
  import { getToastLocations } from "src/services/integrationsEngineApi/integrations";
  import { getLocations } from "src/services/api/locations";
  import { onMount } from "svelte";
  import toastLocationRow from "./toastLocationRow.svelte";
  import CircularProgress from "@smui/circular-progress";
  import Snackbar from "@smui/snackbar";

  export const pageId = "integrations";

  export let managementGroupGuid;
  export let merchantId;
  export let searchText = "";

  let loading = true;

  let snackBar;
  let snackBarClass;
  let snackBarMessage;

  function showSnackbarSuccess() {
    snackBarClass = "toastSuccessSurface";
    snackBarMessage = "Location successfully updated!";
    snackBar.open();
  }

  function showSnackbarError() {
    snackBarClass = "toastErrorSurface";
    snackBarMessage = "Error encountered while updating location";
    snackBar.open();
  }

  onMount(() => {
    loadAllData();
  });

  function loadAllData() {
    let tattlePromise = Promise.resolve();

    return tattlePromise
      .then(() =>
        Promise.allSettled([
          retrieveToastLocations(),
          retrieveTattleLocations(),
        ])
      )
      .then(() => {
        //Error treatment?
        loading = false;
      });
  }

  function handleLocationsUpdated(response) {
    var isSuccess = response?.detail?.isSuccess;

    if(!isSuccess){
      showSnackbarError();     
    }
    else {
      showSnackbarSuccess();
    }

    loading = true;

    let tattlePromise = Promise.resolve();

    return tattlePromise
      .then(() =>
        Promise.allSettled([
          retrieveToastLocations(),
        ])
      )
      .then(() => {
        //Error treatment?
        loading = false;
      });
  }

  function retrieveToastLocations() {
    return getToastLocations(managementGroupGuid, merchantId).then((results) => {
      rows = results;
      if(!sortedByUser) {
        defaultLocationsSort();
      } else {
        handleLocationsSort();
      }
    });
  }

  function retrieveTattleLocations() {
    return getLocations({
      active: 1,
      expand: "address",
      merchants_id: merchantId,
      page: 1,
      size: -1,
    }).then((result) => {
      locations = result;
    });
  }

  function handleEditRow(response) {
    let oldEditingRow = rows.find(row => row.restaurantGuid == editingRow)
    if(oldEditingRow) {
      oldEditingRow.isActive = editingRowStatus
    }
    editingRow = response.detail.restaurantGuid
    editingRowStatus = response.detail.rowActive
    rows = rows
  }
  
  let editingRow = null;
  let editingRowStatus = null;

  let locations = [];
  let takenLocations = [];

  $: takenLocations = rows.map(x => x.tattleLocation?.locationId);

  let rows = [];
  let sort = "isActive";
  let isActiveColumnElement;
  let sortDirection = "ascending";
  let className = "";
  let filteredRows;
  let slice;
  let sortedByUser = false;

  // Pagination variables
  let rowsPerPage = 10;
  let currentPage = 0;
  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, rows.length);
  $: searchText, filteredRows = rows.filter((row) => {
    if(!row.tattleLocation?.label && !row.toastLocation?.locationName) {
      if(row.toastLocation?.addressLine1) {
        row.displayLocation = row.toastLocation?.addressLine1
      } else {
        row.displayLocation = 'Location Name Unavailable'
      }
      return true
    }
    row.displayLocation = row.toastLocation?.locationName
    return (
      row.toastLocation?.locationName
        ?.toLowerCase()
        ?.indexOf(searchText.toLowerCase()) > -1 ||
      row.tattleLocation?.label
        ?.toLowerCase()
        ?.indexOf(searchText.toLowerCase()) > -1
    );
  });
  $: slice = filteredRows.slice(start, end);
  $: lastPage = Math.max(Math.ceil(rows.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  function handleLocationsSort(userSort = true) {
    if(userSort) {
      sortedByUser = true
    }
    rows = rows.sort((a, b) => {
      let tempSortName = sort;
      //Handle nested objects
      if (sort.indexOf(".") > -1) {
        a = a[sort.slice(0, sort.indexOf("."))];
        b = b[sort.slice(0, sort.indexOf("."))];
        tempSortName = sort.slice(sort.indexOf(".")+1);
      }
      let aVal, bVal;
      if (a && a[tempSortName]){
        aVal = a[tempSortName]
      } else {
        aVal = "";
      }
      if (b && b[tempSortName]) {
        bVal = b[tempSortName]
      } else {
        bVal = ""
      }
      // const [aVal, bVal] = [a[tempSortName], b[tempSortName]];
      let first, second;
      if (sortDirection === 'ascending') {
        first = aVal;
        second = bVal;
      } else {
        first = bVal;
        second = aVal;
      }
      if (typeof first === 'boolean' && second === '') {
        second = false
      }
      if (typeof second === 'boolean' && first === '') {
        first = false
      }
      if (typeof first === 'string') {
        return first?.localeCompare(second);
      }
      return first - second;
    });
    rows = rows;
    if(sort == 'toastLocation.restaurantName') {
      sort = 'toastLocation.locationName'
      handleLocationsSort()
    }
  }

  function defaultLocationsSort() {
    sortDirection = 'ascending';
    sort = 'toastLocation.restaurantName'
    handleLocationsSort(false);
    sortDirection = 'descending';
    sort = 'isActive';
    handleLocationsSort(false);
  }
</script>

<div class="toastLocations">
  {#if loading}
    <CircularProgress
      style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px;"
      indeterminate
    />
  {:else}
    <DataTable
      sortable
      bind:sort
      bind:sortDirection
      on:MDCDataTable:sorted={handleLocationsSort}
      class="locations-table"
    >
      <Head slot="head">
        <Row>
          <Cell columnId="isActive" class="align-left integrationSwitchCol" bind:this={isActiveColumnElement}>
            <Label data-cy="integrations-location-header-status-label" class="header-label align-left">
              Status
            </Label>
            <IconButton data-cy="integrations-location-header-status-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell columnId="toastLocation.restaurantName" class="align-left">
            <Label data-cy="integrations-location-header-toast-label" class="header-label align-left"
              >Toast Restaurant/Location Name</Label
            >
            <IconButton data-cy="integrations-location-header-toast-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell columnId="tattleLocation.label" class="align-left">
            <Label data-cy="integrations-location-header-tattle-label" class="header-label align-left"
              >Tattle Location Name</Label
            >
            <IconButton data-cy="integrations-location-header-tattle-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell
            columnId="lastSyncEndDateTime"
            class="align-left"
          >
            <Label data-cy="integrations-location-header-last-synced-label" class="header-label align-left">Last Synced</Label>
            <IconButton data-cy="integrations-location-header-last-synced-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell columnId="actions" class="unsortableHeaderCell integrationActionsCol">
            <!-- blank -->
          </Cell>
        </Row>
      </Head>
      <Body slot="body">
        {#each slice as row}
          <svelte:component
            this={toastLocationRow}
            {row}
            {locations}
            {takenLocations}
            {merchantId}
            restaurantPlusLocationName={row.toastLocation?.restaurantName + "/" + row.displayLocation}
            tattleLocationLabel={row.tattleLocation?.label}
            lastSyncEndDateTime={row.lastSyncEndDateTime}
            editingRowId={editingRow}
            isEditMode={editingRow == row.restaurantGuid}
            on:locationsUpdated={handleLocationsUpdated}
            on:editRow={handleEditRow}
          />
        {/each}
      </Body>

      <Pagination
      autoId="integrations-pagination"
      slot="paginate"
      class="paginator"
      {lastPage}
      bind:currentPage
      totalRecordCount={rows.length}/>
    </DataTable>

    {#if rows.length === 0}
      <div class="no-messages" data-cy="no-manager-replies">
        <div class="no-messages-wrapper">
          <div class="no-messages-title">
            <span> No Locations </span>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</div>
<Snackbar bind:this={snackBar} surface$data-cy="integrations-snackbar" surface$class={snackBarClass}>
  <Label>{snackBarMessage}</Label>
</Snackbar>

<style lang="scss">
  .toastLocations {
    margin: 8px 20px 8px 20px;
  }
  * :global(.locationRow > td) {
    word-wrap: break-word;
    max-width: 180px;
  }
  * :global(.unsortableHeaderCell) {
    cursor: default;
  }
  * :global(.align-left) {
    text-align: left !important;
    padding-left: 8px !important;
  }
  * :global(.mdc-data-table__sort-icon-button) {
    opacity: 1;
  }
  :global(.toastSuccessSurface) {
    background-color: var(--tattle-success-color);
  }
  :global(.mdc-snackbar__label) {
    font-size: 12pt;
  }
  :global(.toastErrorSurface) {
    background-color: var(--tattle-fail-color);
  }
  :global(.paginator .mdc-chip) {
    font-size: 10pt;
  }
  :global(.paginator .page-summary) {
    font-size: 10pt;
  }
</style>
