<script>
  import Dialog, {Actions, Title, Header, Content} from '@smui/dialog';
  import {Label} from '@smui/button';
  import Button from 'tttl-core-ui/src/components/Button.svelte';


  /***** unsaved-changes-modal Component *****
   * Displays a modal that allows a user to discard their unsaved changes or save them as a draft
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param show {Boolean} - If true, displays the modal
   * @param draftTemplate {Object} -  The message template that has unsaved changes
   * @param draftSubject {String} -  The subject of the draft
   * @param draftBody {String} -  The body text of the draft
   */

  export let autoId = 'unsaved-changes-modal';
  export {className as class};

  let className = '';

  export let show = false;
  export let draftTemplate;
  export let draftSubject;
  export let draftBody;

  function saveDraft() {
    draftTemplate.subject = draftSubject;
    draftTemplate.body = draftBody;
  }

</script>

<Dialog class="unsaved-changes-modal {className}" data-cy={autoId} bind:open={show}
        fullscreen>

  <Header>
    <Title tabindex="0" id="fullscreen-title">Unsaved Changes</Title>
  </Header>

  <Content style="text-align: left;">You have unsaved changes. Would you like to save them?</Content>

  <Actions>
    <Button type="outlined" color="secondary" action="close"><Label>Discard</Label></Button>
    <Button type="raised" color="primary" action="close" on:click={saveDraft}><Label>Save as Draft</Label></Button>
  </Actions>

</Dialog>


<style lang="scss">
  :global(.unsaved-changes-modal .mdc-dialog__header) {
    margin-bottom: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecef;
  }

  :global(.unsaved-changes-modal .mdc-dialog__content) {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 14px;
  }

  :global(.unsaved-changes-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.unsaved-changes-modal .mdc-dialog__title) {
    font-size: 16px;
  }

  :global(.unsaved-changes-modal.mdc-dialog .mdc-dialog__surface) {
    max-width: 650px;
    max-height: calc(100% - 70px);
  }

  :global(.unsaved-changes-modal  .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.unsaved-changes-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

</style>
