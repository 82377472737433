<script>
  /***** operational-category-clipboard-content Component *****
   * Formats HTML to meet design specs for the clipboard when a copy event is initiated
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param questionnaireSnapshot {Object} - The snapshot to show
   * @param snapshotReasons {Array} - An array of snapshot reasons for the questionnaire
   */

  export let autoId = "operational-category-copy-button";
  export let questionnaireSnapshot;
  export let snapshotReasons;

  $: percentage = (questionnaireSnapshot.rating - 1) * 25;

  let starRating = "";
  let starColor = "";
  if (questionnaireSnapshot) {
    switch (questionnaireSnapshot.rating) {
      case 0 || 1:
        starRating = "1 Star";
        starColor = "#b70034";
        break;
      case 2:
        starRating = "2 Stars";
        starColor = "#f0495d";
        break;
      case 3:
        starRating = "3 Stars";
        starColor = "#f88245";
        break;
      case 4:
        starRating = "4 Stars";
        starColor = "#ffbc2b";
        break;
      case 5:
        starRating = "5 Stars";
        starColor = "#007244";
        break;
    }
  }
</script>

<div
  class="operational-category-copy-button"
  style="font-family: Roboto, Lato, Helvetica Neue, Arial, sans-serif"
  data-cy={autoId}
>
  <div style="font-size:16px">
    {questionnaireSnapshot.snapshot.label}
  </div>

  <div style="color : {starColor}; font-size:14px" class="rating-wrapper">
    <div style="display:inline">
      {starRating}
    </div>

    <div style="display:inline;">
      {percentage}%
    </div>
  </div>

  <div>
    {#each snapshotReasons as snapshotReason}
      <div
        style="color : {snapshotReason.impact === 1
          ? 'green'
          : 'red'}; display:inline; font-size:14px"
      >
        {snapshotReason.reason.label}
      </div>
    {/each}
  </div>

  <div style="font-size:12px">
    <div class="snapshot-comment-title">Comment Added</div>
    <div>
      {#if !questionnaireSnapshot.comment}
        No Comment Provided
      {/if}
      {questionnaireSnapshot.comment}
    </div>
  </div>
</div>

<style lang="scss">
</style>
