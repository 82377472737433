import {headers, baseURL} from 'src/services/auth';

/**
 * Retrieves all active rewards for a given merchant id
 * @param {String} merchants_id The id for the merchant
 * @returns {Promise<Array>} An array of all the non archived rewards available to a merchant
 */
export function getRewards(merchants_id) {
  const url = new URL(`${baseURL}/v2/api/rewards`);
  url.search = new URLSearchParams({
    merchants_id: merchants_id,
    archived: 0,
    size: -1
  }).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then((result) => {
    return result.json();
  }).then((result) => {
    return result._embedded.rewards;
  });
}
