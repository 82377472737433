<script>
  export let isSuccess;
  export let errorId;
</script>

<div style="margin-bottom: 20px;"><a data-cy="integrations-back-to-integrations-link" href="#/admin/global/integrations"><i class="fa fa-arrow-left"></i> Back to Integrations</a></div>

<h1>Square</h1>
<hr/>

{#if isSuccess}
  <div class="alert alert-success">
    <p data-cy="integrations-square-successfully-connected-text">Tattle has been successfully connected to your Square account!</p>
    <br>
    <p data-cy="integrations-square-click-here-to-view-your-integration-text">
      <a data-cy="integrations-square-click-here-to-view-your-integration-link" href="#/admin/global/integration/square">Click here</a> to view your integration.
    </p>
  </div>
{:else}
  <div class="alert alert-danger">There was a problem enabling your Square integration. <strong>{errorId || ""}</strong></div>
{/if}