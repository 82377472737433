<script>
  import Dialog, {Title, Header, Content} from '@smui/dialog';
  import IconButton from '@smui/icon-button';

  /***** menu-items-modal Component *****
   * Displays a modal containing a list of menu items
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param show {Boolean} - If true, displays the modal
   * @param menuItems {Object} -  The menu items from this response's transaction
   */

  export let autoId = 'menu-items-modal';
  export {className as class};

  let className = '';

  export let show = false;
  export let menuItems;

</script>

<Dialog class="menu-items-modal {className}" sheet data-cy={autoId} bind:open={show} fullscreen>

  <Header>
    <Title tabindex="0" id="fullscreen-title">Menu Items</Title>
    <IconButton action="close" class="material-icons">close</IconButton>
  </Header>

  <Content>
    <div class="menu-items-modal-section">
      {#each menuItems as item}
        <div class="menu-items-modal-item">
          <div class="menu-items-modal-label">{item.itemLabel}</div>
          {#if item.note}
            <div class="menu-items-modal-note">{item.note}</div>
          {/if}
        </div>
      {/each}
    </div>
  </Content>

</Dialog>


<style lang="scss">
  :global(.menu-items-modal .mdc-dialog__header) {
    margin-bottom: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecef;
  }

  :global(.menu-items-modal .mdc-dialog__content) {
    text-align: left;
    padding: 15px;
    margin-left: 15px;
    font-size: var(--mdc-typography-body1-font-size);
    color: #222f48;
  }

  :global(.menu-items-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.menu-items-modal .mdc-dialog__title) {
    font-size: 16px;
  }

  :global(.menu-items-modal.mdc-dialog .mdc-dialog__surface) {
    max-height: 70%;
  }

  :global(.menu-items-modal  .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.menu-items-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

  .menu-items-modal-section {
    font-size: var(--mdc-typography-caption-font-size);
    user-select: none;
    overflow-y: scroll;
    line-height: 14px;
    min-width: 150px;
  }

  .menu-items-modal-item {
    padding-bottom: 15px;
  }

  .menu-items-modal-label {
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding-bottom: 5px;
  }

  .menu-items-modal-note {
    color: #828899;
  }


</style>
