<script>
  import Dialog, {Actions, Title, Content, Header} from '@smui/dialog';
  import Select, {Option} from '@smui/select';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import {Label} from '@smui/button';
  import Radio from '@smui/radio';
  import CircularProgress from "@smui/circular-progress";
  import {sendSurveyMail} from 'src/services/api/survey-mail';
  import {sendSocialMail} from 'src/services/api/social-mail';
  import {sendUsersMerchantsLog} from 'src/services/api/users-merchants-logs';
  import {addCustomerReward, getCustomerRewards} from 'src/services/api/customer-rewards';
  import {replaceTags} from 'src/services/utility/customerReplyParser';
  import {userId} from 'src/services/auth.js';
  import Quill from "quill";
  import QuillEditor from 'src/building-blocks/quill-editor.svelte';
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import {getMessageLogs, updateMessageLog} from 'src/services/api/message-logs';
  import {messageLogsStore, customerRewardsStore} from 'src/services/stores/enhanced-profile-sidebar-store';
  import IconButton from '@smui/icon-button';
  import ToastSuccess from 'src/building-blocks/toast-success.svelte';
  import ToastError from 'src/building-blocks/toast-error.svelte';
  import {toastOptions, toastSuccessTheme, toastErrorTheme} from 'src/building-blocks/toast-constants.js';
  import {toast} from "@zerodevx/svelte-toast";
  import {broadcastEvent} from 'src/services/utility/angularScopeUtility';
  import UnsavedChangesModal from 'src/pages/sidebars/enhanced-profile-sidebar/modals/unsaved-changes-modal.svelte';
  import {get} from 'svelte/store';
  import { EnableSpellCheck } from "src/services/utility/quillEditorUtility";
  import { generateAIReply } from 'src/services/integrationsEngineApi/v3';


  /***** reply-to-customer-modal Component *****
   * Displays a modal that allows a user to reply to a customer's questionnaire response
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param show {Boolean} - If true, displays the modal
   * @param messageTemplates {Array} -  An array of all the message templates available
   * @param merchantRewards {Array} - An array of the available rewards for the merchant
   * @param customer {Object} - The customer object returned from the endpoint
   * @param survey {Object} - The survey this response is for
   * @param actionType {String} - The type of reply this is
   * @param links {Array} - An array of links available to the merchant
   * @param inProgress {Boolean} - If true, the network calls are still in flight
   * @param canUseAiReply {Boolean} - Allows use of AI reply feature
   */

  export let autoId = 'reply-to-customer-modal';
  export {className as class};

  let className = '';

  export let show = false;
  export let messageTemplates = [];
  export let merchantRewards;
  export let customer;

  export let survey;
  export let actionType;
  export let links;
  export let inProgress = false;
  export let canUseAiReply = false; 

  let selectedTemplate;
  let email;
  let subject;
  let sendReward = false;
  let expirationDays = 30;
  let selectedReward;
  let quillInstance;

  //Need to do this so we know when the selected template has changed so we know when to update the subject
  let lastSelectedTemplate;

  // This is used so we only add one text change listener to quill
  let listenerSet = false;

  // Keep track of whether or not the body and/or subject change
  let templateModified = false;
  // Check for invalid tags in the body
  let invalidTemplate = false;

  // Passed to unsavedChangesModal
  let showUnsavedChangesModal = false;
  let draftTemplate;
  let draftSubject;
  let draftBody;

  let replyBy = 'template';
  let loadingAI = false;
  let showConfirmAI = false;
  let formOrigin = '';
  let aiResponse = '';

  Quill.register(EnableSpellCheck, true);

  $: {
    if (selectedTemplate && quillInstance && lastSelectedTemplate !== selectedTemplate) {
      lastSelectedTemplate = selectedTemplate;
      templateSelectedHandler();
      templateChange(lastSelectedTemplate);
      if (!listenerSet) {
        quillInstance.on('text-change', templateModifiedHandler)
        listenerSet = true;
        templateModified = false;
      }
    }
  }

  function showToastSuccess() {
    toast.push({
      ...toastOptions,
      theme: toastSuccessTheme,
      component: {
        src: ToastSuccess,
        props: {
          message: "Message Sent!",
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  function showToastError() {
    toast.push({
      ...toastOptions,
      theme: toastErrorTheme,
      component: {
        src: ToastError,
        props: {
          message: "Message Sending Failed",
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  function templateSelectedHandler() {
    templateModified = false;
    const delta = quillInstance.clipboard.convert(selectedTemplate.body);
    quillInstance.setContents(delta, 'silent');
    email = selectedTemplate.sender_email || survey?.location?.contact_email || survey?.merchant?.contact_email;
    subject = selectedTemplate.subject;
  }

  function closeHandler() {
    if (replyBy == 'ai') {
      return;
    }
    if (templateModified) {
      // Confirm unsaved changes
      draftTemplate = selectedTemplate;
      draftSubject = subject;
      draftBody = quillInstance.root.innerHTML;
      showUnsavedChangesModal = true;
    }
    if (!inProgress) {
      messageTemplates = [];
      selectedTemplate = undefined;
      email = undefined;
      subject = undefined;
      sendReward = false;
      expirationDays = 30;
      selectedReward = undefined;
      lastSelectedTemplate = undefined;
      if (quillInstance) {
        const delta = quillInstance.clipboard.convert('');
        quillInstance.setContents(delta, 'silent');
        quillInstance.off('text-change', templateModifiedHandler);
        listenerSet = false;
        templateModified = false;
      }
    }
  }

  function openHandler() {
    if (formOrigin == '') {
      replyBy = 'template';
    }
  }

  function addReward() {
    return addCustomerReward(survey.merchants_id, survey.customers_id, survey.id,
      selectedReward.id, survey.locations_id, survey.locations_id, 0, expirationDays).then((newCustomerReward) => {

      return getCustomerRewards({
        customers_id: survey.customers_id,
        merchants_id: survey.merchants_id,
        expand: 'rewards',
        size: -1
      }).then((response) => {
        customerRewardsStore.set(response);
        return newCustomerReward;
      });
    })
  }

  function sendReply() {
    discardDraft()
    let sendPromise;

    inProgress = true;

    const fullText = replaceTags(quillInstance.root.innerHTML, survey, customer, selectedReward, links).replace(/\n/g, "<br />");

    const subjectText = replaceTags(subject, survey, customer, selectedReward, links);

    if (actionType === 'yelp'){
      sendPromise = sendSocialMail(survey.id, subjectText, email, fullText, selectedTemplate.id, selectedTemplate.image_url);
    } else {
      sendPromise = sendSurveyMail(survey.id, actionType, subjectText, email, fullText, selectedTemplate.id, selectedTemplate.image_url);
    }

    return sendPromise.then((mailResponse) => {
      const promises = [
        sendUsersMerchantsLog('sent-reply', `mail/${actionType}`, survey.id, survey.merchants_id, 'post', $userId)
      ]

      if (sendReward) {
        promises.push(addReward());
      }

      return Promise.all(promises).then((promiseResults) => {
        const logId = mailResponse.message_logs_id;
        updateMessageLog(logId, {manual_reply: 1})

        if (sendReward) {
          const rewardId = promiseResults[1].id;
          updateMessageLog(logId, {rewards_customers_id: rewardId})
        }

        return getMessageLogs({
          receiver_id: survey.customers_id,
          sender_id: survey.merchants_id,
          size: -1
        }).then(result => {
          broadcastEvent("reloadSurveyList", "reply-to-customer-modal", {
            promiseResult: result,
            survey: survey,
          });
          if (get(messageLogsStore).receiverId === survey.customers_id){
            messageLogsStore.set({
              receiverId: survey.customers_id,
              data: result
            });
          }
        });
      })
    }).then(() => {
      showToastSuccess();
    }).catch(() => {
      showToastError();
    }).finally(() => {
      inProgress = false;
      closeHandler();
    });
  }

  function templateModifiedHandler() {
    templateModified = true;
    invalidTemplate = quillInstance && quillInstance.root && quillInstance.root.innerHTML && quillInstance.root.innerHTML.includes("[[link:yelp]]")
  }

  function templateChange(messageTemplate) {
    if (messageTemplate.rewards_id !== null) {
      merchantRewards.map((item) => {
        if (item.id === messageTemplate.rewards_id) {
          selectedReward = item;
          sendReward = true;
        }
      });
    } else {
      sendReward = false;
    }
    invalidTemplate = quillInstance && quillInstance.root && quillInstance.root.innerHTML && quillInstance.root.innerHTML.includes("[[link:yelp]]")
  }

  function saveDraft() {
    // Save draft before closing
    selectedTemplate.subject = subject;
    selectedTemplate.body = quillInstance.root.innerHTML;
    templateModified = false;
  }

  function discardDraft() {
    // Discard draft before closing
    templateModified = false;
  }

  $: selectedTemplate = !selectedTemplate ? messageTemplates[0] : selectedTemplate;
  $: {
    if (!selectedReward && merchantRewards && merchantRewards.length > 0) {
      selectedReward = merchantRewards[0];
    }
  }

  function getAIReply() {
    if(!canUseAiReply)
      return;

    loadingAI = true;
    quillInstance.enable(false);
    generateAIReply(survey.merchants_id, survey.id, actionType)
      .then((response) => {
        loadingAI = false;
        quillInstance.enable(true);
        aiResponse = response;
        quillInstance.root.innerHTML = response;
      })
      .then(() => {
        loadingAI = false;
        quillInstance.enable(true);
      });;
  }

  function prepareSend() {
    if (replyBy == 'ai') {
      showConfirmAI = true;
      aiResponse = quillInstance.root.textContent || quillInstance.root.innerText || aiResponse;
    } else {
      sendReply();
    }
  }

  function sendAIReply() {
    sendReply();
    showConfirmAI = false;
  }

  function backToForm() {
    formOrigin = 'modal-ai';
    showConfirmAI = false;
    show = true;
  }

</script>

<Dialog class="reply-to-customer-modal {className}" data-cy={autoId} bind:open={show}
        fullscreen
        on:MDCDialog:opened={openHandler} on:MDCDialog:closed={closeHandler}>

  <Header>
    <Title id="fullscreen-title">Reply to Customer</Title>
    <IconButton action="close" class="material-icons">close</IconButton>
  </Header>

  <Content tabindex="0" style="text-align: left;">
    <div class="inputs">
      {#if (actionType == 'thankyou' || actionType == 'apology') && canUseAiReply }
        <div class="reply-row-wrapper">
          <div class="reply-label">
            <div>
              Reply by:
            </div>
          </div>
          <FormField label$class="m-b-none">
            <Radio class="my-colored-radio" bind:group={replyBy} value="template" />
            <span slot="label">Template</span>
          </FormField>
            <FormField label$class="m-b-none">
              <Radio class="my-colored-radio ml-6" bind:group={replyBy} value="ai" on:click={() => getAIReply()} />
              <span slot="label">
                AI
                <span class="beta-badge">BETA</span>
              </span>
            </FormField>
            {#if loadingAI}
              <FormField class="ml-4">
                <CircularProgress style="height: 16px; width: 16px; margin-right: 5px;" indeterminate/>
              </FormField>
            {/if}
        </div>
      {/if}
      {#if replyBy === 'template'}
        <div class="reply-row-wrapper">
          <div class="reply-label">
            <div>
              Template:
            </div>
          </div>

          <Select bind:value={selectedTemplate} class="reply-input-select">
            {#each messageTemplates as messageTemplate}
              <Option data-cy="reply-to-customer-modal-template-{messageTemplate.id}-select" on:click={() => templateChange(messageTemplate)} value={messageTemplate}>{messageTemplate.label}</Option>
            {/each}
          </Select>
        </div>
      {/if}

      <div class="message-metadata reply-row-wrapper">
        <div class="reply-label">
          <div>
            From:
          </div>
        </div>
        <input type="email" bind:value={email} disabled class="reply-input"/>
      </div>

      <div class="reply-row-wrapper">
        <div class="reply-label">
          <div>
            Subject:
          </div>
        </div>
        <input on:input={templateModifiedHandler} type="text" bind:value={subject} class="reply-input"/>
      </div>

    </div>

    <div class="message-contents">
      <QuillEditor bind:quillInstance/>
      <div class="checkbox-details reply-input" style="border: none;">
        Any images attached to the original template will not appear here, but will be appended during sending. Attempting to add images directly in this editor is not supported and may lead to unexpected results.
      </div>
      {#if invalidTemplate}
        <div class="checkbox-details reply-input" style="border: none; padding-top: 15px; color: red;">
          Error: Sending an email with link:yelp tags is not supported.
        </div>
      {/if}
    </div>

    {#if merchantRewards && merchantRewards.length > 0}
      <div class="rewards">
        <div class="reply-row-wrapper checkbox-row">
          <div class="reply-label include-reward">
            <div>
              Include Reward:
            </div>
          </div>
          <div class="send-reward-checkbox-wrapper">
            <FormField>
              <Checkbox bind:checked={sendReward}/>
              <span slot="label">Send a reward</span>
            </FormField>

            <div class="checkbox-details reply-input" style="border: none;">
              This reward is sent in a separate email, it is not attached directly to this message.
            </div>
          </div>
        </div>

        {#if sendReward}
          <div class="select-reward">
            <div class="reply-row-wrapper">
              <div class="reply-label include-reward">
                <div>Select Reward:</div>
              </div>
              <Select bind:value={selectedReward} class="reply-input-select">
                {#each merchantRewards as merchantReward}
                  <Option value={merchantReward}>{merchantReward.label}</Option>
                {/each}
              </Select>
            </div>
            <div class="reply-row-wrapper">
              <div class="reply-label include-reward">
                <div> Expiration:</div>
              </div>
              <div class="reply-input reply-input-number" style="border: none;">
                <input class="expiration-input" type="number" min="0" bind:value={expirationDays}/>
                <span>days</span>
              </div>
            </div>
          </div>
        {/if}
      </div>
    {/if}

  </Content>

  <Actions>
    {#if templateModified}
      <Button on:click={saveDraft}><Label>Save as Draft</Label></Button>
    {/if}
    <Button type="outlined" color="secondary" on:click={discardDraft}><Label>Cancel</Label></Button>
    <Button type="raised" color="primary" disabled={invalidTemplate} on:click={prepareSend}><Label>Send</Label></Button>
  </Actions>

</Dialog>

{#if canUseAiReply}
<Dialog class="reply-to-customer-modal {className} confirm-modal" data-cy="confirm-ai-reply-to-customer-modal" bind:open={showConfirmAI}
        fullscreen>

  <Content tabindex="0">
    <div style="text-align: center;">
      <i class="v-icon notranslate v-theme--light" aria-hidden="true" style="font-size: 55px; height: 55px; width: 55px; color: rgb(241, 142, 71); caret-color: rgb(241, 142, 71);">
        <svg class="v-icon__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true">
          <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"></path>
        </svg>
      </i>
      <div class="action-header">Confirmation</div>
      <div class="action-content" style="padding-bottom: 30px;">
        <p class="main-question"><b>Are you sure you want to send your AI generated reply?</b></p>
        <p>
          Please be sure and proofread your reply before sending it, <br />
          once you send it you can't recall it.
        </p>
      </div>
    </div>
    <div style="text-align: left;">
      <p class="confirm-form-fields"><b>From: </b>{email}</p>
      <p class="confirm-form-fields"><b>Subject: </b>{subject}</p>
      <textarea class="reply-input confirm-form-textarea" style="width: 100%; height: 200px;" disabled>{aiResponse}</textarea>
    </div>
  </Content>

  <Actions>
    <Button on:click={backToForm}><Label>Cancel</Label></Button>
    <Button type="raised" color="primary" on:click={sendAIReply}><Label>Confirm</Label></Button>
  </Actions>
</Dialog>
{/if}

<UnsavedChangesModal bind:show={showUnsavedChangesModal}
                     draftTemplate={draftTemplate}
                     draftSubject={draftSubject}
                     draftBody={draftBody}/>


<style lang="scss">
  :global(.reply-to-customer-modal .mdc-dialog__header) {
    margin-bottom: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecef;
  }

  :global(.reply-to-customer-modal .expiration-input) {
    width: 85%;
    text-align: right;
    height: 40px;
  }

  :global(.reply-to-customer-modal .reply-input.reply-input-number) {
    padding-left: 0;
  }

  :global(.reply-to-customer-modal .reply-label.include-reward) {
    min-width: 120px;
    margin-right: 0;
  }

  :global(.reply-to-customer-modal .reply-label) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    color: black;
    width: 75px;
    margin-right: 30px;
  }

  :global(.reply-to-customer-modal .smui-select--standard .mdc-menu-surface--is-open-below) {
    max-height: 70vh;
  }

  :global(.reply-to-customer-modal .mdc-dialog__content) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  :global(.reply-to-customer-modal .send-reward-checkbox-wrapper label) {
    line-height: 35px;
    font-size: 14px;
    font-weight: normal;
  }

  :global(.reply-to-customer-modal .send-reward-checkbox-wrapper .mdc-checkbox) {
    margin-bottom: 6px;
  }

  :global(.reply-to-customer-modal .reply-input) {
    flex-grow: 1;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #e0e0e0;
    border-style: solid;
  }

  :global(.reply-to-customer-modal .reply-input-select) {
    flex-grow: 1;
  }

  :global(.reply-to-customer-modal .reply-input-select .mdc-select__anchor) {
    border-color: grey;
    border-width: 1px;
    border-style: solid;
    height: 40px;
    width: 100%;
    padding-left: 10px;
    border-radius: 5px;
    border-bottom: none;
  }

  :global(.reply-to-customer-modal .reply-input-select .mdc-select__anchor .mdc-select__selected-text) {
    font-size: 16px;

  }

  :global(.reply-to-customer-modal .reply-row-wrapper) {
    display: flex;
    height: 40px;
    margin-bottom: 25px;
  }

  :global(.reply-to-customer-modal .message-contents) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  :global(.reply-to-customer-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.reply-to-customer-modal .mdc-dialog__title) {
    font-size: 20px;
  }

  :global(.reply-to-customer-modal.mdc-dialog .mdc-dialog__surface) {
    max-width: 650px;
    max-height: calc(100% - 70px);
  }

  :global(.reply-to-customer-modal  .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.reply-to-customer-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

  :global(.reply-to-customer-modal .checkbox-details) {
    color: black;
    font-size: 10px;
  }

  :global(.reply-to-customer-modal .checkbox-row) {
    margin-bottom: 35px;
  }

  .confirm-form-fields {
    color: #353535;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 18px !important;
  }

  .confirm-form-textarea {
    color: #353535;
    font-size: 12px;
    font-style: normal;
    line-height: 21px;
    margin-bottom: 18px !important;
  }

  :global(.confirm-modal .mdc-dialog__surface) {
    width: 605px !important;
  }

  .action-content p.main-question {
    margin-bottom: 0 !important;
  }

  :global(.confirm-modal .mdc-dialog__actions) {
    justify-content: space-between !important;
    border-top: none;
    padding: 8px 15px 28px;
  }

  :global(.confirm-modal .mdc-dialog__actions button) {
    border-radius: 4px !important;
  }

  :global(.beta-badge) {
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    border: 1px solid #EE7723;
    padding: 4px 25px;
    border-radius: 100px;
    margin-left: 8px;

    background-image: linear-gradient(to right, #F5AE34, #EE7723);
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

</style>
