<script>
  import { onMount } from "svelte";
  import { fade } from 'svelte/transition';
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import { Label } from "@smui/data-table/styled";
  import Textfield from "@smui/textfield";
  import HelperText from '@smui/textfield/helper-text';
  import Select, {Option} from '@smui/select';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import ToastSuccess from 'src/building-blocks/toast-success.svelte';
  import ToastError from 'src/building-blocks/toast-error.svelte';
  import { toastOptions, toastSuccessTheme, toastErrorTheme } from 'src/building-blocks/toast-constants.js';
  import {SvelteToast, toast} from "@zerodevx/svelte-toast";
  import CircularProgress from '@smui/circular-progress';
  import ConfirmSurveyConfigurationModal from './confirm-survey-configuration-modal.svelte';
  import * as surveyApi from "src/services/integrationsEngineApi/surveyConfiguration"

  let showConfirm = false
  let initiallyLoading = true
  let inProgress = false
  
  let invalidValues = true

  let confirmTitle = ""
  let confirmMessage = ""

  let frequencyCount = "";
  let frequencyUnit = "";
  let delayCount = "";
  let delayUnit = "";
  let deviceLimitCount = "";
  let deviceLimitUnit = "";
  let disableSendingFeedback = false;
  let enableReceiptScanning = true

  $: formData = {
    frequencyCount: frequencyCount,
    frequencyUnit: frequencyUnit,
    delayCount: delayCount,
    delayUnit: delayUnit,
    deviceLimitCount: deviceLimitCount,
    deviceLimitUnit: deviceLimitUnit,
    disableSendingFeedback: disableSendingFeedback,
    enableReceiptScanning: enableReceiptScanning
  };

  $: if (formData.frequencyCount === '' || formData.frequencyCount === undefined || isNaN(formData.frequencyCount) ||
      formData.delayCount === '' || formData.delayCount === undefined || isNaN(formData.delayCount) ||
      formData.deviceLimitCount === '' || formData.deviceLimitCount === undefined || isNaN(formData.deviceLimitCount)) {
        invalidValues = true
      } else {
        invalidValues = false
      };

  $: frequencyChanged = formData.frequencyCount != frequencyCount || formData.frequencyUnit != frequencyUnit
  $: delayChanged = formData.delayCount != delayCount || formData.delayUnit != delayUnit
  $: deviceLimitChanged = formData.deviceLimitCount != deviceLimitCount || formData.deviceLimitUnit != deviceLimitUnit
  $: disableSendingFeedbackChanged = formData.disableSendingFeedback != disableSendingFeedback
  $: enableReceiptScanningChanged = formData.enableReceiptScanning != enableReceiptScanning
  $: noChanges = !frequencyChanged && !delayChanged && !deviceLimitChanged && !disableSendingFeedbackChanged && !enableReceiptScanningChanged

  $: frequencyInMinutes = convertToMinutes(formData.frequencyCount, formData.frequencyUnit)
  $: delayInMinutes = convertToMinutes(formData.delayCount, formData.delayUnit)
  $: deviceLimitInMinutes = convertToMinutes(formData.deviceLimitCount, formData.deviceLimitUnit)

  $: invalidFrequency = frequencyInMinutes < 1440 || frequencyInMinutes > 43200
  $: invalidDelay = delayInMinutes < 0 || delayInMinutes > 43200
  $: invalidDeviceLimit = deviceLimitInMinutes < 1440 || deviceLimitInMinutes > 43200
  $: invalidForm = invalidFrequency || invalidDelay || invalidDeviceLimit || invalidValues;


  export let merchantId;

  function callSave() {
    if(frequencyChanged) {
      confirmTitle = 'Save Updated Survey Frequency'
      confirmMessage = 'Are you sure you want to update the survey frequency?'
    } else if(delayChanged) {
      confirmTitle = 'Save Updated Survey Delay'
      confirmMessage = 'Are you sure you want to update the survey delay?'
    } else if(deviceLimitChanged) {
      confirmTitle = 'Save Updated Device Limit'
      confirmMessage = 'Are you sure you want to update the device limit?'
    } else if(disableSendingFeedbackChanged) {
      if(formData.disableSendingFeedback) {
        confirmTitle = 'Disable Sending Feedback Request Emails'
        confirmMessage = 'Are you sure you want to disable sending feedback request emails?'
      } else {
        confirmTitle = 'Enable Sending Feedback Request Emails'
        confirmMessage = 'Are you sure you want to enable sending feedback request emails?'
      }
    } else if(enableReceiptScanningChanged) {
      if(formData.enableReceiptScanning) {
        confirmTitle = 'Enable Receipt Scanning'
        confirmMessage = 'Are you sure you want to enable receipt scanning?'
      } else {
        confirmTitle = 'Disable Receipt Scanning'
        confirmMessage = 'Are you sure you want to disable receipt scanning?'
      }
    }
    showConfirm = true
  }

  async function callConfirm() {
    inProgress = true
    try {
      if(invalidForm) {
        return
      }
      if(frequencyChanged) {
        await surveyApi.updateSurveyFrequency(merchantId, frequencyInMinutes)
      }
      if(delayChanged) {
        await surveyApi.updateSurveyDelay(merchantId, delayInMinutes)
      }
      if(deviceLimitChanged) {
        await surveyApi.updateDeviceLimit(merchantId, deviceLimitInMinutes)
      }
      if(disableSendingFeedbackChanged) {
        let action = 'deactivate'
        if(formData.disableSendingFeedback) {
          action = 'activate'
        }
        await surveyApi.updateSendingFeedback(merchantId, action)
      }
      if(enableReceiptScanningChanged) {
        let action = 'deactivate'
        if(formData.enableReceiptScanning) {
          action = 'activate'
        }
        await surveyApi.updateReceiptScanning(merchantId, action)
      }
      showToastSuccess("Survey Configuration saved successfully")
      initData()
    } catch (err) {
      showToastError(err.message)
    } finally {
      inProgress = false
    }
  }

  const showToastSuccess = (toastMessage) => {
    toast.push({
      ...toastOptions,
      theme: toastSuccessTheme,
      component: {
        src: ToastSuccess,
        props: {
          message: toastMessage,
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  const showToastError = () => {
    toast.push({
      ...toastOptions,
      theme: toastErrorTheme,
      component: {
        src: ToastError,
        props: {
          message: "",
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  async function initData() {
    inProgress = true
    try {
      const getSurveyFrequencyTask = surveyApi.getSurveyFrequency(merchantId);
      const getSurveyDelayTask = surveyApi.getSurveyDelay(merchantId);
      const getDeviceLimitTask = surveyApi.getDeviceLimit(merchantId);
      const getSendingFeedbackTask = surveyApi.getSendingFeedback(merchantId);
      const getReceiptScanningTask = surveyApi.getReceiptScanning(merchantId);

      let { value: frequency } = await getSurveyFrequencyTask;
      let frequencyObject = convertFromMinutes(frequency)
      frequencyCount = frequencyObject.count
      frequencyUnit = frequencyObject.unit

      let { value: delay } = await getSurveyDelayTask;
      let delayObject = convertFromMinutes(delay)
      delayCount = delayObject.count
      delayUnit = delayObject.unit

      let { value: deviceLimit } = await getDeviceLimitTask;
      let deviceLimitObject = convertFromMinutes(deviceLimit)
      deviceLimitCount = deviceLimitObject.count
      deviceLimitUnit = deviceLimitObject.unit

      let { value: sendingFeedback } = await getSendingFeedbackTask;
      disableSendingFeedback = sendingFeedback

      let { value: receiptScanning } = await getReceiptScanningTask;
      enableReceiptScanning = receiptScanning
    } catch (err) {
      showToastError(err.message)
    } finally {
      inProgress = false
    }
  }

  function convertFromMinutes(valueInMinutes) {
    let returnObject = {
      count: null,
      unit: null
    }
    if(valueInMinutes % 1440 == 0) {
      returnObject.count = valueInMinutes / 1440
      returnObject.unit = 'day'
    } else if(valueInMinutes % 60 == 0) {
      returnObject.count = valueInMinutes / 60 
      returnObject.unit = 'hour'
    } else {
      returnObject.count = valueInMinutes
      returnObject.unit = 'minute'
    }
    return returnObject
  }

  function convertToMinutes(value, unit) {
    let returnValue = value
    if(unit == 'hour') {
      returnValue = value * 60
    } else if (unit == 'day') {
      returnValue = value * 60 * 24
    }
    return returnValue
  }

  onMount(async () => {
    await initData();
    initiallyLoading = false;
  });
</script>

{#if !initiallyLoading}

<div in:fade class="page survey-configuration-admin">
  <div class="panel panel-default">
    <div class="panel-heading-flex">
        <div>
          <h4 data-cy="survey-configuration-heading-title">
            <i data-cy="survey-configuration-heading-icon" class="fa fa-sliders fa-rotate-90"></i> Survey Configuration
          </h4>
          <span data-cy="survey-configuration-heading-description" class="subheading">Manage the survey configuration for the entire brand.</span>
        </div>
    </div>
    <div class="panel-body">
      
      <div class="panel panel-default panel-no-shadow">
        <div class="panel-heading">
            <div data-cy="survey-configuration-survey-frequency-title" class="subpanel-title">
              Survey Frequency
            </div>
            <div data-cy="survey-configuration-survey-frequency-description" class="subpanel-subheading">Manage the frequency of email and SMS surveys.</div>
        </div>
        <div class="panel-body">
          <h6 data-cy="survey-configuration-survey-frequency-instruction">Choose a minimum of 24 hours to a maximum of 30 days.</h6>
          <span data-cy="survey-configuration-survey-frequency-label" class="m-r-sm">No more than once every  </span>
          <Textfield
            type="number"
            data-cy="survey-configuration-survey-frequency-value-field"
            input$data-cy="survey-configuration-survey-frequency-value-input"
            variant="outlined"
            bind:value={formData.frequencyCount}
            class="survey-input-text m-r-md"
            input$oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            disabled={inProgress}
            invalid={invalidFrequency}
          />
          <Select data-cy="survey-configuration-survey-frequency-unit-select" bind:value={formData.frequencyUnit} variant="outlined" class="survey-input-select" disabled={inProgress}>
            <Option data-cy="survey-configuration-survey-frequency-unit-hour" value="hour">Hour(s)</Option>
            <Option data-cy="survey-configuration-survey-frequency-unit-day" value="day">Day(s)</Option>
          </Select>
          <HelperText data-cy="survey-configuration-survey-frequency-error-text" persistent slot="helper" class="helper-error">
            {#if invalidFrequency}
              Invalid value for frequency
            {/if}
          </HelperText>
        </div>
      </div>

      <div class="panel panel-default panel-no-shadow">
        <div class="panel-heading">
          <div data-cy="survey-configuration-survey-delay-title" class="subpanel-title">
            Survey Delay
          </div>
          <div data-cy="survey-configuration-survey-delay-description" class="subpanel-subheading">Manage email and SMS survey timing.</div>
        </div>
        <div class="panel-body">
          <h6 data-cy="survey-configuration-survey-delay-instruction">Choose a minimum of 0 minutes to a maximum of 30 days.</h6>
          <Textfield
            type="number"
            data-cy="survey-configuration-survey-delay-value-field"
            input$data-cy="survey-configuration-survey-delay-value-input"
            variant="outlined"
            bind:value={formData.delayCount}
            class="survey-input-text m-r-md"
            input$oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            disabled={inProgress}
            invalid={invalidDelay}
          />
          <Select data-cy="survey-configuration-survey-delay-unit-select" bind:value={formData.delayUnit} variant="outlined" class="survey-input-select" disabled={inProgress}>
              <Option data-cy="survey-configuration-survey-delay-unit-minute" value="minute">Minute(s)</Option>
              <Option data-cy="survey-configuration-survey-delay-unit-hour" value="hour">Hour(s)</Option>
              <Option data-cy="survey-configuration-survey-delay-unit-day" value="day">Day(s)</Option>
          </Select>
          <HelperText data-cy="survey-configuration-survey-delay-error-text" persistent slot="helper" class="helper-error">
            {#if invalidDelay}
            Invalid value for delay
            {/if}
          </HelperText>
        </div>
      </div>

      <div class="panel panel-default panel-no-shadow">
        <div class="panel-heading">
            <div data-cy="survey-configuration-device-limit-title" class="subpanel-title">
              Device Limit
            </div>
            <div data-cy="survey-configuration-device-limit-description" class="subpanel-subheading">Manage how frequently a single device can submit a survey. However, this can be overridden at the survey level.</div>
        </div>
        <div class="panel-body">
          <h6 data-cy="survey-configuration-device-limit-instruction">Choose a minimum of 24 hours to a maximum of 30 days.</h6>
          <span data-cy="survey-configuration-device-limit-label" class="m-r-sm">No more than once every  </span>
          <Textfield
            type="number"
            data-cy="survey-configuration-device-limit-value-field"
            input$data-cy="survey-configuration-device-limit-value-input"
            variant="outlined"
            bind:value={formData.deviceLimitCount}
            class="survey-input-text m-r-md"
            input$oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            disabled={inProgress}
            invalid={invalidDeviceLimit}
          />
          <Select data-cy="survey-configuration-device-limit-unit-select" bind:value={formData.deviceLimitUnit} variant="outlined" class="survey-input-select" disabled={inProgress}>
            <Option data-cy="survey-configuration-device-limit-unit-hour" value="hour">Hour(s)</Option>
            <Option data-cy="survey-configuration-device-limit-unit-day" value="day">Day(s)</Option>
          </Select>
          <HelperText data-cy="survey-configuration-device-limit-error-text" persistent slot="helper" class="helper-error">
            {#if invalidDeviceLimit}
              Invalid value for device limit
            {/if}
          </HelperText>
        </div>
      </div>

      <div class="panel panel-default panel-no-shadow">
        <div class="panel-heading">
            <div data-cy="survey-configuration-feedback-requests-title" class="subpanel-title">
              Feedback Requests
            </div>
            <div data-cy="survey-configuration-feedback-requests-description" class="subpanel-subheading">Manage the dispatch of customer feedback requests. Enable or disable email communications based on your engagement strategy.</div>
        </div>
        <div class="panel-body">
          <div class="survey-configuration-checkbox-wrapper">
            <FormField>
              <Checkbox
                data-cy="survey-configuration-feedback-requests-disable-checkbox"
                bind:checked={formData.disableSendingFeedback}
              />
              <span data-cy="survey-configuration-feedback-requests-label" slot="label">Disable sending customer feedback request emails</span>
            </FormField>
          </div>
        </div>
      </div>
      
      <div class="panel panel-default panel-no-shadow">
        <div class="panel-heading">
            <div data-cy="survey-configuration-receipt-scanning-title" class="subpanel-title">
              Receipt Scanning
            </div>
            <div data-cy="survey-configuration-receipt-scanning-description" class="subpanel-subheading">Manage the feature allowing customers to upload receipt photos for the collection of item-level feedback when a partner order is not available.</div>
        </div>
        <div class="panel-body">
          <div class="survey-configuration-checkbox-wrapper">
            <FormField>
              <Checkbox
                data-cy="survey-configuration-receipt-scanning-enable-checkbox"
                bind:checked={formData.enableReceiptScanning}
              />
              <span data-cy="survey-configuration-receipt-scanning-label" slot="label">Enable Receipt Scanning for Item-Level Feedback</span>
            </FormField>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <div class="pull-right">
        <Button
          autoId="survey-configuration-save-button"
          type="raised"
          disabled={inProgress || noChanges || invalidForm}
          on:click={callSave}
        >
          <Label>
            <div class="label-contents">
              {#if inProgress}
                <CircularProgress style="height: 16px; width: 16px; margin-right: 5px;" indeterminate/>
              {/if}
              <div>Save</div>
            </div>
          </Label>
        </Button>
      </div>
    </div>
  </div>
</div>

{/if}

<div class="toast-wrapper"><SvelteToast /></div>

<ConfirmSurveyConfigurationModal bind:show={showConfirm} title={confirmTitle} message={confirmMessage} on:actionConfirm={() => callConfirm()}/>


<style>
.panel-footer {
  contain: content;
}

* :global(button) {
  height: 30px;
  font-size: 14px;
  font-weight: normal;
}

.label-contents {
  display: flex;
  align-items: center;
}

:global(.strong) {
  font-weight: bold;
}

:global(.panel-no-shadow) {
  box-shadow: none;
}

:global(.survey-configuration-admin .subpanel-title) {
  font-size: 14px;
  font-weight: 500;
}

:global(.survey-configuration-admin .subpanel-subheading) {
  font-size: 12px;
}

:global(.survey-configuration-admin .survey-input-select) {
  position: absolute;
}

:global(.survey-configuration-admin .survey-input-select .mdc-select__anchor) {
  height: 40px;
  width: 130px;
}

:global(.survey-configuration-admin .survey-input-text) {
  height: 40px;
  width: 80px;
}

:global(.survey-configuration-admin .survey-input-select .mdc-select__selected-text),
:global(.survey-configuration-admin .survey-input-select .mdc-deprecated-list) {
  font-size: 14px;
}

:global(.survey-configuration-admin .survey-configuration-checkbox-wrapper label) {
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
}

:global(.survey-configuration-admin .survey-configuration-checkbox-wrapper .mdc-checkbox) {
  margin-bottom: 6px;
}
</style>
