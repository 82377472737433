<script>
    import { onMount } from "svelte";
    import Button from "tttl-core-ui/src/components/Button.svelte";
    import IconButton from '@smui/icon-button';
    import { Label } from '@smui/button';
    import CircularProgress from "@smui/circular-progress";
    import Card from "@smui/card";
    import Dialog, { Title, Content, Actions, InitialFocus } from '@smui/dialog';
    import {
      getPunchhIntegration,
      addPunchhIntegration,
      updatePunchhIntegration
    } from "src/services/integrationsEngineApi/integrations";
    import { getLocations } from "src/services/api/locations";
    import LocationMappings from "../locationMappings.svelte";
    import Snackbar from "@smui/snackbar";
  
    export let merchantId;
    let isLoading = true;
    let isActive = false;
    let webhookUrl = '';
    let isOAuthRevokeDialogOpen = false;
    let punchhLocations;
    let locations;
    let snackBar;
    let snackBarClass;
    let snackBarMessage;
  
    onMount(() => {
      loadAllData();
    });
  
    async function loadAllData() {
      // Retrieve Tattle Locations
      isLoading = true
      const result = await getLocations({
        active: 1,
        expand: "address",
        merchants_id: merchantId,
        page: 1,
        size: -1,
      });
      locations = result
  
      await retrievePunchhIntegration();
      isLoading = false;
    }
    
  
    function retrievePunchhIntegration() {
      return getPunchhIntegration(merchantId).then(result => {
        let punchhIntegration = result
        isActive = punchhIntegration.isActive;
        webhookUrl = punchhIntegration.webhookUrl;
        
        punchhLocations = [];
        punchhIntegration.locations.forEach(item => {
          let tattleLocation = locations.find(x => x.id == item.locationId)
          punchhLocations.push({
            integrationLocationLabel: item.punchhStoreNumber,
            integrationLocationId: item.locationId,
            isActive: item.isActive ? true : false,
            tattleLocationId: tattleLocation?.id,
            tattleLocationLabel: tattleLocation?.label
          })
        });
      }).catch(() => {
        isActive = false;
      })
    }
  
    function activateIntegration() {
      addPunchhIntegration(merchantId, true).then(async () => {
        await loadAllData()
        showSnackbarSuccess(true)
      }).catch(() => {
        showSnackbarError();
      })
    }
    
    function revokeAccess() {
      updatePunchhIntegration(merchantId, false).then(async () => {
        await loadAllData()
        showSnackbarSuccess()
      }).catch(() => {
        showSnackbarError();
      })
    }
  
    function copyWebhookToClipboard() {
      navigator.clipboard.writeText(webhookUrl);
    }
  
    function showSnackbarSuccess(create = false) {
      snackBarClass = "integrationSuccessSurface";
      snackBarMessage = "Integration successfully deactivated!";
      if(create) {
        snackBarMessage = "Integration successfully created!";
      }
      snackBar.open();
    }
  
    function showSnackbarError() {
      snackBarClass = "integrationErrorSurface";
      snackBarMessage = "Error encountered while adding Integration";
      snackBar.open();
    }
  </script>
  
  <h1 data-cy="integrations-punchh-heading">Punchh</h1>
  <hr/>
  
  {#if isLoading}
    <CircularProgress
      style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px; left: 50%;"
      indeterminate
    />
  {:else}
    <div class="m-t-lg">
      <p data-cy="integrations-punchh-activation-status-label">
        This integration is
        {#if isActive}
          <strong data-cy="integrations-punchh-activation-status-value" class="color-success">active</strong>.
        {:else}
          <strong data-cy="integrations-punchh-activation-status-value" class="color-danger">inactive</strong>.
          <p data-cy="integrations-punchh-activate-text" class="m-t-lg" style="display: flex; align-items: baseline;">
            To activate your Punchh integration,
            <Button autoId="integrations-punchh-activate-link" on:click={() => activateIntegration() }>
              <Label style="font-size: 14px; min-width: 0">Click here</Label>
            </Button>
          </p>
        {/if}
      </p>
  
      {#if isActive}
        <p class="m-t-lg">
          <Button autoId="integrations-punchh-revoke-access-link" on:click={() => isOAuthRevokeDialogOpen = true}>
            <Label>Revoke Access</Label>
          </Button>
        </p>
        {#if webhookUrl}
          <Card data-cy="integrations-punchh-webhook-url-card" variant="outlined" padded class="p-a-md">
            <p class="m-a-0">
              <strong data-cy="integrations-punchh-webhook-url-label">Webhook URL</strong>
            </p>
            <div data-cy="integrations-punchh-webhook-url-text" class="webhook__container">
              <IconButton data-cy="integrations-punchh-webhook-url-copy-button" class="material-icons" on:click={() => copyWebhookToClipboard()}>content_copy</IconButton>
              { webhookUrl }
            </div>
          </Card>
        {/if}
      {/if}
      
    </div>
  
    {#if isActive}
      <LocationMappings locations={punchhLocations} hideLastSynced />
    {/if}
    
  {/if}
  
  <Snackbar
    bind:this={snackBar}
    surface$data-cy="integrations-snackbar"
    surface$class={snackBarClass}
  >
    <Label>{snackBarMessage}</Label>
  </Snackbar>
  
  <Dialog
    bind:open={isOAuthRevokeDialogOpen}
    aria-labelledby="punchh-revoke-access-dialog"
    aria-describedby="punchh-revoke-access-dialog-content"
  >
    <Title data-cy="punchh-revoke-access-dialog-title" id="punchh-revoke-access-dialog-title">Confirm</Title>
    <Content data-cy="punchh-revoke-access-dialog-content" id="punchh-revoke-access-dialog-content">
      This will revoke Tattle's access to your Punchh account. Do you want to continue?
    </Content>
    <Actions>
      <Button autoId="punchh-revoke-access-dialog-button-continue" on:click={revokeAccess}>
        <Label>Continue</Label>
      </Button>
      <Button autoId="punchh-revoke-access-dialog-button-cancel" use={[InitialFocus]}>
        <Label>Cancel</Label>
      </Button>
    </Actions>
  </Dialog>
  
  <style lang="scss">

  </style>