import { writable } from 'svelte/store';

/*
    To show or hide, use the feedbackTooltipStore with a payload of:

    anchorElem: target,
    isVisible: true,
    data: {
      isFlagged: survey.flagged > 0,
      reason: survey.flagged_reason,
      flaggedBy: survey.flagged_by,
      callback: flagFeedback
    }


*/
export const feedbackTooltipStore = writable({anchorElem: null, isVisible: false, data: null});
