<script>
  import OperationalCategorySnapshot
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/operational-category/operational-category-snapshot.svelte';

  /***** enhanced-profile-operational-category-section.svelte Component *****
   * Displays all the operational category snapshots for the survey
   *
   ******Parameters ******************************************************************************
   * @param class {string} - Any classes to add to the component
   * @param customerQuestionnaireSnapshots {Object} - The response from the customer questionnaire snapshots endpoint
   * @param customerSnapshotReasons {Object} - The response from the customer snapshot reasons endpoint
   * @param survey {Object} - The response from the survey endpoint
   */

  const autoId = 'enhanced-profile-operational-category-section';
  export {className as class};
  export let customerQuestionnaireSnapshots;
  export let customerSnapshotReasons;
  export let survey;

  let className = '';

  function getSnapshotReasons(reasonId){
    return customerSnapshotReasons.filter((e) => e?.customer_questionnaire_snapshot?.snapshot_id === reasonId && e.impact !== 0);
  }

</script>

<div class="operational-category-section {className}" data-cy={autoId}>
  <div class="profile-title">
    Operational Category Snapshots ({survey.category.length})
  </div>
  {#if customerQuestionnaireSnapshots}
    {#each customerQuestionnaireSnapshots as questionnaireSnapshot}
      <OperationalCategorySnapshot class="operational-category-snapshot" {questionnaireSnapshot} snapshotReasons={getSnapshotReasons(questionnaireSnapshot.snapshot_id)}/>
    {/each}
  {/if}
</div>


<style lang="scss">
  .operational-category-section {
    margin: 20px;
  }
  * :global(.operational-category-snapshot) {
    margin-bottom: 20px;
  }
  .profile-title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 14px;
  }

</style>

