<script>
  import {onMount} from "svelte";
  import Quill from "quill";

  /***** quill-editor Component *****
   * Provides an instantiated quill editor
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param quillInstance {Object} - Bind to this if you need to make any edits/access the contents of the quill editor
   */

  export let autoId = 'quill-editor';
  export {className as class};
  export let quillInstance;

  let className = '';
  let wrapperElement;


  onMount(() => {
    const editorDiv = wrapperElement.querySelector('.editor');
    const icons = Quill.import("ui/icons");

    icons["undo"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>`;
    icons["redo"] = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"/></svg>`;

    const toolbarOptions = [
      [
        'bold',
        'italic',
        {'list': 'bullet'},
        {'list': 'ordered'},
        'redo',
        'undo',
        'clean'
      ]
    ];
    quillInstance = new Quill(editorDiv, {
      modules: {
        toolbar: {
          container: toolbarOptions,
          handlers: {
            undo: undo,
            redo: redo
          }
        },
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true
        }
      },
      theme: 'snow'
    });
  });


  function undo() {
    return quillInstance.history.undo();
  }

  function redo() {
    return quillInstance.history.redo();
  }

</script>

<div class="quill-editor {className}" data-cy={autoId} bind:this={wrapperElement}>
  <div class="editor"></div>
</div>


<style lang="scss">
  .quill-editor, .editor {
    min-height: 150px;
  }

  * :global(.ql-editor){
    min-height: 150px;
  }

</style>

