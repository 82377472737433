import {headers} from 'src/services/auth';
import {HandleResponseJson} from 'src/services/shared';

const baseURL = () => window.INTEGRATIONS_ENGINE_URL;

export function getIntegrations(merchantId) {
  const url = new URL(`${baseURL()}integrations?merchantId=${merchantId}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(async result => await HandleResponseJson(result));
}

export function addOrUpdateToastIntegration(merchantId, managementGroupGUID, isActive, isNewMgg) {
  const url = new URL(`${baseURL()}Toast/integration`);
  var method = 'PUT';

  if(isNewMgg)
    method = 'POST';

  return fetch(url.toString(), {
    method: method,
    headers: headers,
    body: JSON.stringify({
      "tattleMerchantId": merchantId,
      "managementGroupGUID": managementGroupGUID,
      "isActive": isActive
    })
  }).then(result => result);
}

export function getToastLocations(managementGroupGuid, merchantId) {
  const url = new URL(`${baseURL()}Toast/locations?managementGroupGuid=${managementGroupGuid}&merchantId=${merchantId}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function editToastLocation(isActive, toastRestaurantGUID, tattleLocationId, merchantId) {
  const url = new URL(`${baseURL()}Toast/location`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "restaurantGuid": toastRestaurantGUID,
      "isActive": isActive,
      "tattleLocationId": tattleLocationId,
      "tattleMerchantId": merchantId
    })
  }).then(result => result);
}

export function getAllIntegrations(merchantId) {
  const url = new URL(`${baseURL()}integrations/partners?merchantId=${merchantId}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function getSquareOAuthAuthorizeUrl(merchantId) {
  const url = new URL(`${baseURL()}square/oauth-authorize?merchantId=${merchantId}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function revokeSquareOAuthToken(merchantId) {
  const url = new URL(`${baseURL()}square/oauth-revoke`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ merchantId })
  }).then(result => true);
}

export function getSquareIntegration(merchantId) {
  const url = new URL(`${baseURL()}square/integration?merchantId=${merchantId}`);
  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function getCovaIntegration(merchantId) {
  const url = new URL(`${baseURL()}cova/integration?merchantId=${merchantId}`);
  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function addCovaIntegration(merchantId, covaDetails) {
  const url = new URL(`${baseURL()}cova/integration`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "username": covaDetails.username,
      "password": covaDetails.password,
      "clientId": covaDetails.clientId,
      "clientSecret": covaDetails.clientSecret,
      "companyId": covaDetails.companyId,
      "isActive": true
    })
  }).then(result => result);
}

export function deactivateCovaIntegration(merchantId) {
  const url = new URL(`${baseURL()}cova/integration`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": false
    })
  }).then(result => result);
}

export function getCovaLocations(merchantId) {
  const url = new URL(`${baseURL()}cova/location-integrations?merchantId=${merchantId}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function editCovaLocation(isActive, covaLocationId, locationId, covaCompanyId, merchantId) {
  const url = new URL(`${baseURL()}cova/location-integration`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "covaCompanyId": covaCompanyId,
      "covaLocationId": covaLocationId,
      "isActive": isActive,
      "tattleLocationId": locationId,
      "tattleMerchantId": merchantId
    })
  }).then(result => result);
}

export function removeCovaLocation(covaLocationId, locationId) {
  const url = new URL(`${baseURL()}cova/location-integration`);

  return fetch(url.toString(), {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify({
      "covaLocationId": covaLocationId,
      "tattleLocationId": locationId
    })
  }).then(result => result);
}

export function getThanxIntegration(merchantId) {
  const url = new URL(`${baseURL()}thanx/integration?merchantId=${merchantId}`);
  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function addThanxIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}thanx/integration`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function updateThanxIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}thanx/integration`);

  return fetch(url.toString(), {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function getPunchhIntegration(merchantId) {
  const url = new URL(`${baseURL()}punchh/integration?merchantId=${merchantId}`);
  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function addPunchhIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}punchh/integration`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function updatePunchhIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}punchh/integration`);

  return fetch(url.toString(), {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function getLunchboxIntegration(merchantId) {
  const url = new URL(`${baseURL()}lunchbox/integration?merchantId=${merchantId}`);
  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function addLunchboxIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}lunchbox/integration`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function updateLunchboxIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}lunchbox/integration`);

  return fetch(url.toString(), {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function getChowlyIntegration(merchantId) {
  const url = new URL(`${baseURL()}chowly/integration?merchantId=${merchantId}`);
  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function addChowlyIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}chowly/integration`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function updateChowlyIntegration(merchantId, isActive) {
  const url = new URL(`${baseURL()}chowly/integration`);

  return fetch(url.toString(), {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive
    })
  }).then(result => result);
}

export function getIntegrationDetails(integration, merchantId) {
  const url = new URL(`${baseURL()}${integration}/integration?merchantId=${merchantId}`);
  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function updateIntegration(isNew, integration, merchantId, isActive, extraFields) {
  const url = new URL(`${baseURL()}${integration}/integration`);

  return fetch(url.toString(), {
    method: isNew ? 'POST' : 'PUT',
    headers: headers,
    body: JSON.stringify({
      "merchantId": merchantId,
      "isActive": isActive,
      ...extraFields
    })
  }).then(({status}) => status);
}

export function externalIntegrationAuthorization(integration, merchantId) {
  const url = new URL(`${baseURL()}${integration}/sso?merchantId=${merchantId}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers
  }).then(response => response.text());
}
