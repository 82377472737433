/**
 * permission granted check functionality
 * @param permissions {Object} permission object returned from an api request to users/{userId}/permissions
 * @param permissionToCheck {String} the permisison title to check for
 * @returns {bool} whether or not the permission is granted
 */
export function checkPermission(permissions, permissionToCheck){
    const permissionTitles = _.pluck(permissions, 'title');
    return _.contains(permissionTitles, permissionToCheck)
}

