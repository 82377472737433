<script>
    import DataTable from '@smui/data-table/styled';
    import './../theme/colors.css';

    /***** DataTable.svelte Component *****
     * For the Data Table, we import Material's data table as a base
     *
     * Style Guide for Material with Svelte: https://sveltematerialui.com/demo/data-table/
     *
     ******Parameters ******************************************************************************
     * @param autoId (string) - The auto id for Cypress E2E testing for the table
     * @param class {String} - Any classes to add to the component
     * @param sortable (boolean) -  if true, allows sorting
     * @param sort (string) - String set to which column the sort is currently on
     * @param sortDirection (string) - String set to which direction the sort is currently on
     * @param stickyHeader (boolean) - if true, the header is treated as sticky when scrolling
     *
     * @slot head
     * @slot body
     * @slot paginate
     */

    export {className as class};
    let className;

    export let autoId;
    // Boolean, if true, allows sorting
    export let sortable;
    // String set to which column the sort is currently on
    export let sort;
    // String set to which direction the sort is currently on
    export let sortDirection;
    // Boolean, if true, the header is treated as sticky when scrolling
    export let stickyHeader;

    function preventPropogation(event){
        event.stopPropagation();
    }

</script>

<div>
    <DataTable
            class="tattle-table {className}"
            data-cy={autoId}
            {sortable}
            {stickyHeader}
            bind:sort
            bind:sortDirection
            on:MDCDataTable:sorted
            on:SMUI:data-table:header:click={preventPropogation}
            on:SMUI:data-table:header:mount={preventPropogation}
    >
        <slot name="head"/>
        <slot name="body"/>
        <div slot="paginate">
            <slot name="paginate" style="width: 100%;"/>
        </div>
    </DataTable>
</div>

<style lang="scss">
  * :global(.tattle-table) {
    width: 100%;
  }

  * :global(.tattle-table > table) {
    border-collapse: separate;
    border-spacing: 0 2px;
    background-color: var(--mdc-theme-background);
  }

  * :global(.tattle-table table tbody tr) {
    height: 77px;
  }

  * :global(.tattle-table table tbody tr td) {
    border-width: 0 !important;
  }

  * :global(.tattle-table th.mdc-data-table__header-cell) {
    background: var(--mdc-theme-background);
    font-size: 13px;
    color: var(--mdc-theme-text-primary-on-light);
    padding: 12px 24px;
    font-weight: 400;
    text-transform: none;
    height: auto;
    border-bottom: 1px solid var(--mdc-theme-background);
    text-align: center;
  }

  * :global(.tattle-table td.mdc-data-table__cell) {
    background-color: var(--mdc-theme-background);
    font-weight: 400;
    font-size: 13px;
    color: var(--mdc-theme-text-primary-on-background);
    text-align: center;
  }

  * :global(.tattle-table td.mdc-data-table__cell--label) {
    min-width: 240px !important;
    max-width: 300px !important;
    width: 100% !important;
  }


</style>
