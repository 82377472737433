<script>
  import AutoComplete from "simple-svelte-autocomplete";
  import Dialog, {Actions, InitialFocus} from '@smui/dialog';
  import Button, {Label} from '@smui/button';
  import {parseServerLocalDate, getDateEnding} from "src/services/utility/dateParser";
  import {updateSurveyLocation} from 'src/services/api/survey';

  /***** feedback-details Component *****
   * Contains all the feedback details for a given tattle
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param tattle {Object} - The full tattle object
   * @param survey {Object} - Returned from the survey endpoint
   * @param transaction {Object} - Returned from the transaction endpoint, it's possible for this to be undefined if a merchant doesn't use a transaction processor
   */

  export let autoId = 'feedback-details';
  export {className as class};
  export let tattle;
  export let survey;
  export let transaction;
  // for autocomplete choices
  export let locations;

  let className = '';

  let newSelectedLoc = null;
  let isShowingLocs = false;
  let isShowingDialog = false;

  function formatDate(date) {
    const dateObject = parseServerLocalDate(date);
    return dateObject.toFormat('MMMM d') + getDateEnding(dateObject.day) + ' at ' + dateObject.toFormat('h:mm a');
  }

  // onChange is getting fired just by making the dropdown visible!
  const onLocsChange = () => {
    // prevent unwanted "change" events
    if (!newSelectedLoc) {
      return;
    }
    // show confirmation dialog
    isShowingDialog = true;
  }

  const confirmLoc = () => {
    // API call
    updateSurveyLoc(newSelectedLoc);
    // hide dialog and dropdown
    cancelLoc();
  }

  const cancelLoc = () => {
    // hide dialog
    isShowingDialog = false;
    // hide dropdown
    isShowingLocs = false;
    // clear dropdown
    newSelectedLoc = null;
  }

  // API call
  const updateSurveyLoc = (loc) => {
    updateSurveyLocation(survey.id, newSelectedLoc.id).then(result => {
      // replace with new loc
      survey = result;
      // from returned result
      tattle.location.label = survey.location.label;
    });
  }

  $: verifiedLocation = survey?.location?.id === transaction?.locations_id && survey?.location?.id !== undefined;
  $: verifiedExperience = survey?.date_time_experienced_local === transaction?.date_time_order_completed_local && survey?.date_time_experienced_local !== undefined;

</script>


<div data-cy="feedback-details-section" class="feedback-details-outer-wrapper {className}">
  <div data-cy="feedback-details-title" class="feedback-header-title">
    Feedback Details
  </div>

  <div class="feedback-section">
    <!-- dialog only appears when new loc is selected in auto-complete dropdown-->
    <Dialog
      bind:open={isShowingDialog}
      class="confirm-dialog"
      aria-labelledby="default-focus-title"
      aria-describedby="default-focus-content"
    >
      <div data-cy="feedback-details-confirm-dialog-title" class="dialog-title" id="default-focus-title">
        Confirm Response Location Re-Assignment
      </div>
      <div data-cy="feedback-details-confirm-dialog-text" class="dialog-content" id="default-focus-content">
        {newSelectedLoc ? `Are you sure you want to re-assign this response to the "${newSelectedLoc.label}" location?` : ``}
      </div>

      <Actions>
        <Button 
            autoId="feedback-details-confirm-dialog-cancel-button"
            on:click={() => cancelLoc()}>
          <Label>Cancel</Label>
        </Button>
        <Button
          autoId="feedback-details-confirm-dialog-confirm-button"
          default
          use={[InitialFocus]}
          on:click={() => confirmLoc()}
        >
          <Label>Confirm</Label>
        </Button>
      </Actions>
    </Dialog>

    <div data-cy="feedback-details-location-label" class="feedback-section-title">
      Location
      {#if !isShowingLocs}
        (<span data-cy="feedback-details-change-location-change-link" class="change-location" on:click={() => isShowingLocs = true}>change</span>)
      {/if}
      {#if isShowingLocs}
        (<span data-cy="feedback-details-change-location-cancel-link" class="change-location" on:click={() => cancelLoc()}>cancel</span>)
        <div>
          <AutoComplete autoId="feedback-details-change-location-dropdown"
                        class="locs-dropdown"
                        placeholder="Type to filter locations"
                        items={locations}
                        bind:selectedItem={newSelectedLoc}
                        onChange={onLocsChange}
                        labelFieldName="label"/>
        </div>
      {/if}
    </div>

    <div data-cy="feedback-details-location-text" class="feedback-section-value">
      {tattle.location.label}
      {#if verifiedLocation}
        <span data-cy="feedback-details-location-verified-checkmark" class="material-icons-outlined verified-icon">
          check
        </span>
      {/if}
    </div>
  </div>

  <div class="feedback-section">
    <div data-cy="feedback-details-questionnaire-label" class="feedback-section-title">
      Questionnaire
    </div>
    <div data-cy="feedback-details-questionnaire-text" class="feedback-section-value">
      {tattle.questionnaire.title}
    </div>
  </div>

  <div class="feedback-section">
    <div data-cy="feedback-details-feedback-provided-on-label" class="feedback-section-title">
      Feedback provided on
    </div>
    <div data-cy="feedback-details-feedback-provided-on-text" class="feedback-section-value">
      {formatDate(tattle.date_time_created_local)}
    </div>
  </div>

  <div class="feedback-section">
    <div data-cy="feedback-details-experience-occurred-on-label" class="feedback-section-title">
      Experience occurred on
    </div>
    <div data-cy="feedback-details-experience-occurred-on-text" class="feedback-section-value">
      {formatDate(tattle.date_time_experienced_local)}
      {#if verifiedExperience}
        <span data-cy="feedback-details-experience-time-verified-checkmark" class="material-icons-outlined verified-icon">
          check
        </span>
      {/if}
    </div>
  </div>
</div>


<style lang="scss">
  .feedback-header-title {
    font-weight: 500;
    padding-bottom: 10px;
    user-select: none;
    height: 52px;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 52px;
    margin-bottom: 20px;
  }

  .feedback-section-value {
    font-weight: bold;
  }

  .feedback-section {
    font-size: var(--mdc-typography-subtitle2-font-size);
    user-select: none;
    padding-bottom: 15px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .feedback-details-outer-wrapper {
    min-width: 175px;
    max-width: 240px;
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .change-location {
    color: var(--mdc-theme-primary);
    cursor: pointer;
  }

  /* this is not working */
  * :global(.locs-dropdown .autocomplete) {
    cursor: pointer;
    font-size: 14px;
  }

  .confirm-dialog {
    cursor: pointer;
    height: 50%;
    width: 50%;
  }

  .verified-icon {
    color: white;
    background-color: #0071e8;
    border-radius: 50%;
    font-size: 14px;
    vertical-align: text-top;
  }

  .feedback-section-title {
    padding-bottom: 5px;
    font-weight: normal;
  }

  .dialog-title {
    padding: 8px 15px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    background-color: var(--tattle-slideout-secondary-header-color);
  }

  .dialog-content {
    padding: 15px 15px 0 15px;
    width: 500px;
    text-align: left;
    font-size: 14px;
    line-height: 21px;
  }

</style>
