<script>
  import Dialog, {Actions, Title, Content, Header} from '@smui/dialog';
  import QuillEditor from 'src/building-blocks/quill-editor.svelte';
  import {Label} from '@smui/button';
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import {addMessageLog, getMessageLogs} from 'src/services/api/message-logs';
  import {user} from 'src/services/auth.js';
  import {messageLogsStore} from 'src/services/stores/enhanced-profile-sidebar-store';
  import IconButton from '@smui/icon-button';
  import ToastSuccess from 'src/building-blocks/toast-success.svelte';
  import ToastError from 'src/building-blocks/toast-error.svelte';
  import {toastOptions, toastSuccessTheme, toastErrorTheme} from 'src/building-blocks/toast-constants.js';
  import {toast} from "@zerodevx/svelte-toast";


  /***** handled-externally-modal Component *****
   * The modal for when a user is marking an issue as resolved externally, prompts them for details
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param survey {Object} - The survey this response is for
   * @param inProgress {Boolean} - If true, the network call is in progress
   */

  export let autoId = 'handled-externally-modal';
  export {className as class};
  export let show = false;
  export let survey;
  export let inProgress = false;

  let className = '';
  let quillInstance;
  let disableInput = true;

  //This is used so we only add one text change listener to quill
  let listenerSet = false;

  $: {
    if (quillInstance && !listenerSet) {
      quillInstance.on('text-change', textChangeListener)
      listenerSet = true;
    }
  }

  function textChangeListener() {
    disableInput = (quillInstance?.getText().replaceAll('\n', '') === '' || quillInstance?.getText() === undefined);
  }

  function formatMessage() {
    return `<strong>Handled Externally By:</strong><br/>${$user.full_name}<br/><strong>Add'l Comments:</strong><br/>${quillInstance.root.innerHTML}`;
  }


  //TODO: Move these to a central spot
  function showToastSuccess() {
    toast.push({
      ...toastOptions,
      theme: toastSuccessTheme,
      component: {
        src: ToastSuccess,
        props: {
          message: "Message Sent!",
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  function showToastError() {
    toast.push({
      ...toastOptions,
      theme: toastErrorTheme,
      component: {
        src: ToastError,
        props: {
          message: "Message Sending Failed",
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  function closeHandler() {
    if (!inProgress) {
      if (quillInstance) {
        const delta = quillInstance.clipboard.convert('');
        quillInstance.off('text-change', textChangeListener);
        quillInstance.setContents(delta, 'silent');
        disableInput = true;
        listenerSet = false;
      }
    }
  }

  function sendExternalMessage(event) {
    if (disableInput) {
      event.preventDefault();
      event.stopPropagation();
      return
    }
    inProgress = true;
    return addMessageLog(survey.merchants_id, 2, survey.customers_id, 1, 9, survey.id, formatMessage()).then(() => {
      inProgress = false;
      showToastSuccess();
      closeHandler();
      return getMessageLogs({
        receiver_id: survey.customers_id,
        sender_id: survey.merchants_id,
        size: -1
      }).then(result => {
        messageLogsStore.set({
          receiverId: survey.customers_id,
          data: result
        });
      });
    }).catch(()=>{
      inProgress = false;
      showToastError();
    });
  }


</script>

<Dialog class="handled-externally-modal {className}" data-cy={autoId} bind:open={show}
        fullscreen
        on:MDCDialog:closed={closeHandler}>
  <Header>
    <Title id="fullscreen-title">External Handling of Customer Issue</Title>
    <IconButton action="close" class="material-icons">close</IconButton>
  </Header>

  <Content style="text-align: left;">
    <div class="pre-editor-message">
      Please briefly describe how you handled this customer issue outside of the Tattle messaging platform.
    </div>

    <div class="message-contents" tabindex="0">
      <QuillEditor bind:quillInstance/>
    </div>

    <div class="post-editor-message">
      <div class="notice">
        NOTICE:
      </div>
      <div>
        By agreeing to this form, you agree that you spoke with the customer directly, and handled their issues to their
        satisfaction.
        Furthermore, you agree that all comments written here are as they happened. <br/>
        Also note that marking this issue as handled externally will cancel any automated responses for this experience.
      </div>

    </div>

  </Content>

  <Actions>
    <Button type="outlined" color="secondary"><Label>Cancel</Label></Button>
    <Button type="raised" disabled={disableInput} color="primary" on:click={sendExternalMessage}><Label>Agree</Label>
    </Button>
  </Actions>

</Dialog>


<style lang="scss">
  :global(.handled-externally-modal .mdc-dialog__title) {
    font-size: 20px;
  }

  :global(.handled-externally-modal .pre-editor-message) {
    font-size: 10px;
  }

  :global(.handled-externally-modal .post-editor-message) {
    font-size: 10px;
  }

  :global(.handled-externally-modal .notice) {
    font-weight: 500;
    color: black;
  }

  :global(.handled-externally-modal .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.handled-externally-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

  :global(.handled-externally-modal.mdc-dialog .mdc-dialog__surface) {
    max-height: calc(100% - 70px);
  }

  :global(.handled-externally-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.handled-externally-modal .message-contents) {
    margin-top: 10px;
    margin-bottom: 10px;
  }


</style>

