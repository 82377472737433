import {headers, baseURL} from 'src/services/auth';

/**
 * Returns the locations
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<Response>} A promise resolving to the array of locations
 */
export function getLocations(params) {
  const url = new URL(`${baseURL}/v2/api/locations`);
  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.locations);
}
