<script>
  import { fade } from 'svelte/transition';
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import { Label } from "@smui/data-table/styled";
  import ToastSuccess from 'src/building-blocks/toast-success.svelte';
  import ToastError from 'src/building-blocks/toast-error.svelte';
  import { toastOptions, toastSuccessTheme, toastErrorTheme } from 'src/building-blocks/toast-constants.js';
  import {SvelteToast, toast} from "@zerodevx/svelte-toast";
  import CircularProgress from '@smui/circular-progress';
  import ConfirmDeactivationModal from './confirm-deactivation-modal.svelte';
  import { getSMSConfig, activate, deactivate } from "../../services/smsApi/sms";

  let hasSMS = true
  let isActive
  let showConfirmDeactivate = false
  let inProgress = false
  let messageTemplateString = "Thank you for choosing {{business_name}}. We'd love to hear your feedback! {{survey_url}}. Reply STOP to unsubscribe.";

  export let merchantId;

  getSMSConfig(merchantId).then(result => {
    isActive = result.isActive
  });

  async function callActivate() {
    inProgress = true
    const result = await activate(merchantId, messageTemplateString)
    if(result.ok) {
      showToastSuccess('SMS activated successfully')
      isActive = true
      inProgress = false
    } else {
      const errorResponse = await result.json()
      inProgress = false
      showToastError(errorResponse.message)
    }
  }

  async function callDeactivate() {
    inProgress = true
    const result = await deactivate(merchantId);
    if(result.ok) {
      showToastSuccess('SMS deactivated successfully')
      isActive = false
      inProgress = false
    } else {
      const errorResponse = await result.json()
      inProgress = false
      showToastError()
    }
  }
  const showToastSuccess = (toastMessage) => {
    toast.push({
      ...toastOptions,
      theme: toastSuccessTheme,
      component: {
        src: ToastSuccess,
        props: {
          message: toastMessage,
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  const showToastError = () => {
    toast.push({
      ...toastOptions,
      theme: toastErrorTheme,
      component: {
        src: ToastError,
        props: {
          message: "",
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }
</script>

<div in:fade class="page">
  <div class="panel panel-default" ng-show="!progress.loading">
    <div class="panel-heading-flex">
        <div>
            <h4><i class="fa fa-comments"></i> SMS Surveys</h4>
        </div>
        <div class="pull-right">
          {#if isActive}
          <div class="statusLabelSms activeSms">
            Active
          </div>
          {:else}
          <div class="statusLabelSms inactiveSms">
            Inactive
          </div>
          {/if}
        </div>
    </div>
    <div class="panel-body">
      {#if !hasSMS}
        <p>Coming soon!</p>
      {:else}
        <p class="m-b-xl">Activating SMS surveys will allow sending feedback request text messages to guests when an email address has not been collected.</p>
        <p class="m-b-xl">Please make sure the customer has signed the SMS agreement prior to activating them.</p>
        <p class="m-b-xl strong">The following text message will be sent:</p>
        <blockquote>
          <cite>
            <p>
              {messageTemplateString}
            </p>
          </cite>
        </blockquote>
      {/if}
    </div>
    <div class="panel-footer">
      <div class="pull-right">
        {#if isActive}
          <Button
          autoId="sms-config-save-button"
          type="outlined"
          disabled={inProgress}
          on:click={() => showConfirmDeactivate = true}
          >
            <Label>
              <div class="label-contents">
                {#if inProgress}
                  <CircularProgress style="height: 16px; width: 16px; margin-right: 5px;" indeterminate/>
                {/if}
                <div>Deactivate</div>
              </div>
            </Label>
          </Button>
        {:else}
          <Button
            autoId="sms-config-save-button"
            type="raised"
            on:click={() => callActivate()}
            disabled={inProgress}
          >
            <Label>
              <div class="label-contents">
                {#if inProgress}
                  <CircularProgress style="height: 16px; width: 16px; margin-right: 5px;" indeterminate/>
                {/if}
                <div>Activate</div>
              </div>
            </Label>
          </Button>
        {/if}
      </div>
    </div>
  </div>
  <div class="toast-wrapper"><SvelteToast /></div>
</div>

<ConfirmDeactivationModal bind:show={showConfirmDeactivate} merchantId={merchantId} on:actionDeactivate={() => callDeactivate()}/>


<style>
.panel-footer {
  contain: content;
}

blockquote p {
    font-size: 0.8em !important;
}

* :global(button) {
  height: 30px;
  font-size: 14px;
  font-weight: normal;
}

.label-contents {
  display: flex;
  align-items: center;
}

:global(.statusLabelSms) {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 800;
}
:global(.activeSms) {
  color: #127622;
}

:global(.inactiveSms) {
  color: #E80000;
}

:global(.strong) {
  font-weight: bold;
}
</style>
