import {headers, baseURL} from 'src/services/auth';

/**
 * Returns the group locations
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<Response>} A promise returning the group locations
 */
export function getGroupLocations(params) {
  const url = new URL(`${baseURL}/v2/api/groups-locations`);
  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.groups_locations);
}
