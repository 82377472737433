<script>

  /***** toast-error.svelte Component *****
   * Description goes here
   *
   ******Parameters ******************************************************************************
   * @param autoId (string) - The auto id for Cypress E2E testing
   */

  export let autoId = 'toast-error';
  export let message = '';
  export let onClose = null;    // user must pass in function

</script>

<div data-cy={autoId} class="toast-content" >
  <div class="toast-pair">
    <div data-cy="toast-icon" class="material-icons-outlined toast-icon" tabindex="0">error</div>
    <div data-cy="toast-message" class="toast-message">{message}</div>
  </div>
  <div data-cy="toast-close" class="toast-close" on:click={() => {if (onClose) onClose()}}>CLOSE</div>
</div>


<style lang="scss">

  .toast-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 15px 15px;
    color: #ffffff;
    background-color: #f0495d;
    cursor: pointer;
  }

  .toast-pair {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toast-message {
    margin-left: 8px;
  }

  * :global(.toast-close) {
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 1.5px;
  }

</style>
