import Quill from "quill";

const Inline = Quill.import('blots/inline');

export class EnableSpellCheck extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    domNode.style.color = domNode.color;

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach(child => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    this.remove();

    return span;
  }
}

EnableSpellCheck.blotName = "EnableSpellCheck";
EnableSpellCheck.tagName = "FONT";