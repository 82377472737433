import {headers} from 'src/services/auth';

/**
 * Returns the menu item level feedback for a given transaction
 * @param params {Object} An object containing parameters to add to the url
 * @param {int} params.MerchantId - Merchant ID of the transaction
 * @param {int} params.TransactionId - ID of the transaction
 * @param {string} [params.StartDateUtc] - Optional start date to filter on
 * @param {string} [params.EndDateUtc] - Optional end date to filter on
 * @param {int} [params.Page=1] - Optional page
 * @param {int} [params.PageSize=25] - Optional page size
 * @returns {Promise<any>} A promise that resolves to a paginated array of menu item level feedback
 */
export function getMenuItemLevelFeedback(params) {
  const baseURL = window.INTEGRATIONS_ENGINE_URL;

  const url = new URL(`${baseURL}surveys/menu-level-feedback`);
  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

