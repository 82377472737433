<script>
  import {getScoresAndTotalsByTimePeriod} from 'src/services/api/scores-and-totals';
  import {DateTime} from 'luxon';
  import Chart from "chart.js/auto";
  import {onMount, onDestroy} from 'svelte';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import {merchantId} from 'src/services/auth.js';
  import {globalFilterBarStore} from 'src/stores/globalFilterBarStore';
  import SummaryTile from 'src/pages/landing/ratingTrends/summaryTile.svelte';
  import CircularProgress from "@smui/circular-progress";
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';


  /***** ratingTrendsTab Component *****
   * Displays a rating trends chart
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   */

  export let autoId = 'ratingTrendsTab';
  export {className as class};

  let className = '';

  let loading = true;

  let chartElement;
  let chartReference;
  let dateResolution = 'week';
  let tickCount = 0;
  let lastRefreshTimestamp;

  // Chart/data variables
  let dateLabels = [];
  let totalResponses = [];
  let cers = [];
  let incidents = [];
  let maxResponse = 0;
  //This is used so the graph isn't maxed out at the top
  const maxResponseMultiplier = 2;
  let startDate;
  let endDate;

  // Settings for the overview tiles
  let reviewsEnabled = true;
  let incidentsEnabled = true;
  let ratingsEnabled = true;

  let selectedLocationCount;
  let allLocationCount;

  let totalReviews;
  let totalIncidents;
  let ratingSum;
  let ratingCount;
  let totalExperiences;

  let unsubscribe;

  $: setVisibility(reviewsEnabled, incidentsEnabled, ratingsEnabled);


  function setVisibility(reviews, incidents, ratings) {
    if (chartReference) {
      chartReference.data.datasets[0].hidden = !ratings;
      chartReference.data.datasets[1].hidden = !incidents;
      chartReference.data.datasets[2].hidden = !reviews;
      chartReference.update();
    }
  }

  function retrieveData(storeValues) {
    allLocationCount = storeValues?.allLocations?.length;

    if (storeValues.lastRefreshTimestamp <= lastRefreshTimestamp) {
      return;
    }

    if (!storeValues.dateRange.startDate || !storeValues.dateRange.endDate) {
      return;
    }

    loading = true;

    const parameters = {};
    selectedLocationCount = 0;


    if (storeValues.selectedLocations && storeValues.selectedLocations.length > 0) {
      parameters.locationIds = storeValues.selectedLocations;
      selectedLocationCount = storeValues.selectedLocations.length;
    }
    if (storeValues.questionnaires && storeValues.questionnaires.length > 0) {
      parameters.questionnaireIds = storeValues.questionnaireIds;
    }
    if (storeValues.shifts && storeValues.shifts.start_time && storeValues.shifts.end_time) {
      parameters.shiftStart = storeValues.shifts.start_time;
      parameters.shiftEnd = storeValues.shifts.end_time;
    }
    if (storeValues.selectedGroups && storeValues.selectedGroups.length > 0) {
      parameters.groupIds = storeValues.selectedGroups;
    }

    lastRefreshTimestamp = storeValues.lastRefreshTimestamp;
    // by default moment.toISOString returns UTC time
    // by default luxon.fromISO converts to local time
    // switch luxon.fromISO to utc time zone
    const opts = {};
    opts.zone = 'utc';
    startDate = DateTime.fromISO(storeValues.dateRange.startDate.toISOString(), opts);
    endDate = DateTime.fromISO(storeValues.dateRange.endDate.toISOString(), opts);

    dateResolution = determineDateResolution(startDate, endDate)

    const promises = [];

    promises.push(getScoresAndTotalsByTimePeriod(+$merchantId, dateResolution, startDate, endDate, parameters).then((data) => {
      generateData(data);
      if (!chartReference) {
        createGraph();
      } else {
        chartReference.options.scales.totalExperiencesY.suggestedMax = maxResponse * maxResponseMultiplier;
        chartReference.update();
      }
      loading = false;
    }));

    const responseCountParameters = Object.assign({dateField: 'date_time_created'}, parameters);
    promises.push(getScoresAndTotalsByTimePeriod(+$merchantId, null, startDate, endDate, responseCountParameters).then((data) => {
      if (!data || data.length !== 1) {
        totalReviews = 0;
      } else {
        totalReviews = +data[0].total;
      }
    }));


    return Promise.all(promises);
  }


  /**
   * Returns the time resolution to use for the chart
   * @param startDate {DateTime}
   * @param endDate {DateTime}
   * @returns {("year", "quarter", "month", "week")} - The type of time to bucket the results in
   */
  function determineDateResolution(startDate, endDate) {
    const maxTicks = 20;
    const dateDifference = endDate.diff(startDate);

    if (dateDifference.as('weeks') < maxTicks) {
      tickCount = Math.ceil(dateDifference.as('weeks'));
      return 'week';
    }
    if (dateDifference.as('months') < maxTicks) {
      tickCount = Math.ceil(dateDifference.as('month'));
      return 'month';
    }
    if (dateDifference.as('quarters') < maxTicks) {
      tickCount = Math.ceil(dateDifference.as('quarter'));
      return 'quarter';
    }

    tickCount = Math.ceil(dateDifference.as('year'));
    return 'year';
  }

  function parseDateString(item) {
    if (dateResolution === 'week') {
      return DateTime.fromFormat(`${item.week}`, 'kkkkWW').set({weekday: 6});
    }
    if (dateResolution === 'month') {
      return DateTime.fromFormat(`${item.month}`, 'yyyyMM');
    }
    if (dateResolution === 'quarter') {
      return DateTime.fromFormat(`${item.quarter}`, 'yyyyq');
    }
    if (dateResolution === 'year') {
      return DateTime.fromFormat(`${item.year}`, 'yyyy');
    }
  }


  function createDateLabel(dateObject) {
    if (dateResolution === 'week') {
      return dateObject.toFormat("MMM d");
    }
    if (dateResolution === 'month') {
      return dateObject.toFormat("MMM yyyy");
    }
    if (dateResolution === 'quarter') {
      return 'Q' + dateObject.toFormat("q yyyy");
    }
    if (dateResolution === 'year') {
      return dateObject.year + '';
    }
  }

  function generateData(data) {
    //Clear out all the previous data
    dateLabels.length = 0;
    totalResponses.length = 0;
    cers.length = 0;
    incidents.length = 0;
    maxResponse = 0;
    totalReviews = 0;
    totalIncidents = 0;
    ratingSum = 0;
    ratingCount = 0;
    totalExperiences = 0;

    let bucketStartDate = startDate;

    if (dateResolution === 'week') {
      // If the resolution is weekly, we need to start the bucket on a saturday
      bucketStartDate = bucketStartDate.set({weekday: 6});
      if (endDate.weekday < 6){
        ++tickCount;
      }
    }

    // First create all the arrays, since the backend will only return buckets with data
    for (let i = 0; i < tickCount; ++i) {
      dateLabels.push(createDateLabel(bucketStartDate.plus({[dateResolution]: i})));
      totalResponses.push(null);
      cers.push(null);
      incidents.push(null);
    }

    //Now go through the backend response and add it to the correct data bucket
    data.forEach((item) => {
      const currentDate = parseDateString(item);
      const currentIndex = dateLabels.findIndex((element) => element == createDateLabel(currentDate));

      totalResponses[currentIndex] = +item.total;
      totalExperiences += +item.total;
      cers[currentIndex] = formatCER(item.avg_rating);
      ratingSum += item.avg_rating;
      ++ratingCount;
      incidents[currentIndex] = +item.negatives;
      totalIncidents += +item.negatives;

      if (+item.total > maxResponse) {
        maxResponse = +item.total;
      }
    })
  }

  function formatCER(fullCERNumber){
    if (!fullCERNumber) {
      return "0.0";
    }
    return Math.round(fullCERNumber * 10 + Number.EPSILON) / 10;
  }


  function createGraph() {
    chartReference = new Chart(chartElement, {
      data: {
        labels: dateLabels,
        datasets: [{
          hidden: !ratingsEnabled,
          type: 'line',
          data: cers,
          spanGaps: true,
          label: 'CER',
          yAxisID: 'cersY',
          backgroundColor: '#114796',
          borderColor: '#114796',
          pointRadius: 10,
          pointHoverRadius: 5,
          datalabels: {
            color: 'white',
            font: {
              size: 10
            }
          }
        }, {
          hidden: !incidentsEnabled,
          type: 'bar',
          data: incidents,
          borderRadius: 5,
          backgroundColor: 'red',
          borderColor: 'red',
          borderWidth: 1,
          maxBarThickness: 30,
          label: 'Incidents',
          yAxisID: 'totalExperiencesY',
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            offset: -5,
            font: {
              size: 10
            }
          }
        }, {
          hidden: !reviewsEnabled,
          type: 'bar',
          data: totalResponses,
          borderRadius: 5,
          backgroundColor: '#4DCCD2',
          borderColor: '#4DCCD2',
          borderWidth: 1,
          maxBarThickness: 30,
          label: 'Experiences',
          yAxisID: 'totalExperiencesY',
          datalabels: {
            color: 'black',
            anchor: 'end',
            align: 'end',
            offset: -5,
            font: {
              size: 10
            }
          }
        }

        ]
      },
      plugins: [ChartDataLabels],
      options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              drawOnChartArea: false
            }
          },
          totalExperiencesY: {
            beginAtZero: true,
            suggestedMax: maxResponse * maxResponseMultiplier,
            title: {
              display: false
            },
            grid: {
              drawTicks: false
            },
            ticks: {
              display: false
            },
          },
          cersY: {
            position: 'right',
            min: -7,
            max: 6,
            grid: {
              drawOnChartArea: false,
              drawTicks: false
            },
            ticks: {
              display: false
            },
            title: {
              display: false,
              text: 'CER'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            mode: 'index',
            position: 'average'
          }
        }
      }
    });
  }

  onMount(async () => {
    unsubscribe = globalFilterBarStore.subscribe(value => {
      retrieveData(value);
    })
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<div class="ratingTrendsTab {className} panel panel-default panel-top-box" data-cy={autoId}>
  {#if loading}
    <CircularProgress
      style="height: 64px; width: 64px; left: 50%;"
      indeterminate
    />
  {:else}
    <div class="header">
      <div class="title">
        Reviews and Ratings Over Time <span> | Graph Grouped by <span
        style="text-transform: capitalize;">{dateResolution}</span> </span>

        <div class="responses-link">
          <a class="text-primary" href="#/survey/list">View Responses ></a>
        </div>
      </div>
    </div>
    <div class="summaries">
      <SummaryTile class="summary-tile" title="Responses" count={totalReviews ? totalReviews?.toLocaleString() : totalIncidents?.toLocaleString()}>
        <svelte:fragment slot="tooltip">
          <div class="text-small">
            <strong>How are Responses counted?</strong>
            <div class="m-t-md m-b-md">
              This is the number of customer responses submitted during the date range selected above.
            </div>
            <strong>When is the response date used?</strong>
            <div class="m-t-md m-b-md">
              We only use the response date when listing your Customer Responses and Incidents and in your summary emails.  For all other metrics, insights, and analysis, we use <strong>experience date</strong>. This provides the most accurate picture of the guest experience.
            </div>
          </div>
        </svelte:fragment>
      </SummaryTile>
      <SummaryTile class="summary-tile" title="Experiences"  count={totalExperiences?.toLocaleString()}>
        <svelte:fragment slot="tooltip">
          <div class="text-small">
            <strong>How are Experiences counted?</strong>
            <div class="m-t-md m-b-md">
              This is the number of customer responses based on <strong>an experience that occurred</strong> during the date range selected above.
            </div>
            <strong>Why is the experience date used?</strong>
            <div class="m-t-md m-b-md">
              While it is important to know how much feedback you receive each day, the date and time of the experience is more crucial in understanding trends and identifying opportunities.
            </div>
            <strong>When is the experience date used?</strong>
            <div class="m-t-md m-b-md">
              We use the experience date instead of the response date for all metrics, insights and analysis.
            </div>
          </div>
        </svelte:fragment>

      </SummaryTile>
      <SummaryTile class="summary-tile" title="Incidents" count={totalIncidents?.toLocaleString()}/>
      <SummaryTile class="summary-tile" title="Locations" count={selectedLocationCount || allLocationCount}/>
      <SummaryTile class="summary-tile" title="Average Rating" count={formatCER(ratingSum/ratingCount)}
                   includeStar={true}/>
    </div>
  {/if}
  <div class="main-content">
    <div class="canvas-wrapper">
      <canvas bind:this={chartElement} width="650" height="200" class:hide-chart={loading}></canvas>
    </div>
    <div class="checkbox-container">
      {#if !loading}
        <FormField>
          <Checkbox
            bind:checked={reviewsEnabled}
            disabled={true}
          />
          <span slot="label" style="font-size: medium; font-weight: normal; user-select: none;">Experiences</span>
        </FormField>

        <FormField>
          <Checkbox
            bind:checked={incidentsEnabled}
          />
          <span slot="label" style="font-size: medium; font-weight: normal; user-select: none;">Incidents</span>
        </FormField>

        <FormField>
          <Checkbox
            bind:checked={ratingsEnabled}
          />
          <span slot="label" style="font-size: medium; font-weight: normal; user-select: none;">Average Rating</span>
        </FormField>
      {/if}
    </div>

  </div>
</div>


<style lang="scss">
  .summaries {
    display: flex;
  }

  .checkbox-container :global(label) {
    margin-bottom: 0 !important;
  }

  .responses-link {
    float: right;
  }

  .main-content {
    display: flex;
    flex-direction: row;

    .canvas-wrapper {
      flex-shrink: 1;
    }
  }

  .hide-chart {
    visibility: hidden;
  }
  * :global(.checkbox-container) {
    flex-grow: 1;
    margin-top: 30px;

  }

  * :global(.summary-tile) {
    padding: 10px 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

</style>

