import {headers, baseURL} from 'src/services/auth';

/**
 * Returns an address associated with an id
 * @param addressId {String} The address to look up
 * @returns {Promise<any>} A promise returning the address information
 */
export function getAddress(addressId) {
  const url = new URL(`${baseURL}/v2/api/addresses/${addressId}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}
