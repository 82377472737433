<script>
  import QuickPeek from 'tttl-core-ui/src/components/QuickPeek.svelte';
  import {onDestroy} from 'svelte';
  import {sidebarOpenStore} from 'src/stores/sidebarOpenStore';

  import EnhancedProfileHeader
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/enhanced-profile-header.svelte';
  import EnhancedProfileFeedbackSection
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/enhanced-profile-feedback-section.svelte';
  import EnhancedProfileOperationalCategorySection
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/enhanced-profile-operational-category-section.svelte';
  import EnhancedProfileRepliesSection
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/enhanced-profile-replies-section.svelte';
  import EnhancedProfileSurveySection
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/enhanced-profile-survey-section.svelte';
  import EnhancedProfileComments
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/enhanced-profile-comments-section.svelte';
  import EnhancedProfileFooter
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/enhanced-profile-footer.svelte';

  import {getSnapshots} from 'src/services/api/customer-questionnaire-snapshots';
  import {
    getSurvey,
    getSurveyWithParameters,
    getSingleSurveyWithParameters,
  } from 'src/services/api/survey';
  import {getAddress} from 'src/services/api/addresses';
  import {getCustomerRewards} from 'src/services/api/customer-rewards';
  import {getTransactions} from 'src/services/api/transactions';
  import CircularProgress from '@smui/circular-progress';
  import {getCustomer} from "src/services/api/customers";
  import {getMessageLogs} from 'src/services/api/message-logs';
  import {getLocations} from 'src/services/api/locations';
  import {getMerchantLinks} from 'src/services/api/merchant-links';
  import {getCustomerSnapshotReasons} from 'src/services/api/customer-snapshot-reasons';
  import {getMessageTemplates} from 'src/services/api/message-templates';
  import {getGroupLocations} from 'src/services/api/groups-locations';
  import {getCustomerQuestionnaireComments} from 'src/services/api/customer-questionnaire-comments';
  import {getRewards} from 'src/services/api/rewards';
  import {messageLogsStore, customerRewardsStore} from 'src/services/stores/enhanced-profile-sidebar-store';
  import ReplyTooltip from 'src/building-blocks/reply-tooltip.svelte';
  import {replyTooltipStore} from "src/stores/replyTooltipStore";
  import {feedbackTooltipStore} from "src/stores/feedbackTooltipStore";
  import {userId} from 'src/services/auth.js';
  import {getPermissions} from "src/services/api/permissions";

  import { SvelteToast, toast } from '@zerodevx/svelte-toast'
  import ToastError from 'src/building-blocks/toast-error.svelte';
  import { toastOptions, toastErrorTheme } from 'src/building-blocks/toast-constants.js';
  import { getMenuItemLevelFeedback } from "src/services/integrationsEngineApi/surveys";
  import { getMenuItems } from "src/services/heatmapApi/order-lookup";
  import { getTagsFromSurvey, getActiveTags } from 'src/services/integrationsEngineApi/v3';

  /***** enhanced-profile-sidebar.svelte Component *****
   * Contains the enhanced profile sidebar which is launched from the customer responses table.
   * To open or close, use the sidebarOpenStore with a payload of {id: 'enhanced-profile-sidebar', isOpen: true}
   *
   ******Parameters ******************************************************************************
   * @param class {string} - Any classes to add to the component
   */

  export const sidebarId = 'enhanced-profile-sidebar';
  export {className as class};

  const autoId = 'enhanced-profile-sidebar';

  $: anonymous = typeof(networkData.customerMerchant) === "undefined" || networkData.customerMerchant === null;

  let className = '';
  let isOpen = false;
  let loading = true;
  let lastPayload = {};

  // Passed in from the subscribe function
  let tattle;
  let surveyId;
  let transaction;
  // Set from network calls
  let networkData = {
    customerQuestionnaireSnapshots: undefined,
    //TODO: Might be able to set this from the get all call
    survey: undefined,
    allSurveys: undefined,
    //Contains all the surveys the current customer has written for the current merchant
    filteredMerchantSurveys: undefined,
    flaggedMerchantSurveys: undefined,
    address: undefined,
    //This is now stored in the customerRewardsStore
    // customerRewards: undefined,
    transactions: undefined,
    customerMerchant: undefined,
    //This is now stored in the messageLogsStore
    // messageLogs: undefined,
    locations: undefined,
    merchantLinks: undefined,
    customerSnapshotReasons: undefined,
    messageTemplates: undefined,
    groupLocations: undefined,
    comments: undefined,
    merchantRewards: undefined,
    permissions: undefined,
    menuItemLevelFeedback: undefined,
    menuItems: undefined,
    tags: undefined,
    merchantTags: undefined
  };

  function retrieveMerchantRewards(merchantId) {
    return getRewards(merchantId).then(results => {
      networkData.merchantRewards = results;
    });
  }

  function retrieveSnapshot(questionnaireId) {
    return getSnapshots(questionnaireId).then(results => {
      networkData.customerQuestionnaireSnapshots = results;
    });
  }

  function retrieveSurvey(surveyId) {
    return getSingleSurveyWithParameters(surveyId,{expand:["brand,partner"]}).then(result => {
      networkData.survey = result;
      if (result._embedded.brand != null){
        networkData.survey.brand = result._embedded.brand;
      }
    });
  }

  function retrieveAllSurveys(customerId, merchantId) {
    return getSurveyWithParameters({
      customers_id: customerId,
      expand: 'location,questionnaire',
      order: 'date_time_created+DESC',
      scores_only: true,
      size: -1,
      flagged: 'IN:0,1',
      merchants_id: merchantId
    }).then(result => {
      networkData.allSurveys = result;
      // This contains all the surveys for this customer and this merchant
      networkData.filteredMerchantSurveys = result.filter(item => item.location.merchants_id === merchantId && item.flagged === 0);
      networkData.flaggedMerchantSurveys = result.filter(item => item.location.merchants_id === merchantId && item.flagged === 1);
    });
  }

  function retrieveSingleSurveyWithParams(surveyId, params) {
    return getSingleSurveyWithParameters(surveyId, params).then(result => {
      tattle = result;
    });
  }

  function retrieveAddress(addressId) {
    return getAddress(addressId).then(result => {
      networkData.address = result
    });
  }


  function retrieveCustomerRewards(customerId, merchantId) {
    return getCustomerRewards({
      customers_id: customerId,
      merchants_id: merchantId,
      expand: 'rewards',
      size: -1
    }).then(result => {
      customerRewardsStore.set(result);
    })
  }

  function retrieveTransactions(questionnaireId, locationsId) {
    return getTransactions({
      'customer_questionnaire_id': questionnaireId,
      'locations_id': locationsId,
      'expand': 'partners'
    }).then(transactions => {
      if (!transactions[0]){
        if (networkData.survey.partners_id){
          transaction = {
            partners_id: networkData.survey.partners_id,
            partners: {
              id: networkData.survey.partners_id,
              label: networkData.survey.partner.label
            }
          };
        }
      } else {
        networkData.transactions = transactions;

        _.each(transactions, (trans) => {
          if (trans.partners_id === networkData.survey.partners_id) {
            transaction = trans;
          }
        });
      }
      // Attempt to retrieve menu items and ratings from transaction
      if (transactions[0]) {
        return retrieveMenuItems(tattle.merchants_id, transactions[0].id).then(() => {
          return retrieveMenuItemLevelFeedback(tattle.merchants_id, transactions[0].id);
        });
      } else {
        return;
      }
    });
  }

  function retrieveCustomerMerchant(customerId, merchantId) {
    if (!(customerId === undefined || customerId === null)) {
      return getCustomer(customerId, {'merchants_id': merchantId}).then(result => {
        networkData.customerMerchant = result
      })
    }
  }

  function retrieveMessageLogs(customerId, merchantId) {
    return getMessageLogs({receiver_id: customerId, sender_id: merchantId, size: -1}).then(result => {
      messageLogsStore.set({
        receiverId: customerId,
        data: result
      });
    });
  }

  function retrieveLocations(merchantId) {
    return getLocations({active: 1, expand: 'address', 'merchants_id': merchantId, page: 1, size: -1}).then(result => {
      networkData.locations = result
    });
  }

  function retrieveMerchantLinks(locationId, merchantId) {
    return getMerchantLinks({
      expand: 'overrideCoalesce',
      'merchants_id': merchantId,
      'locations_id': locationId
    }).then(result => {
      networkData.merchantLinks = result
    });
  }

  function retrieveCustomerSnapshotReasons(questionnaireId) {
    return getCustomerSnapshotReasons({
      expand: ['customer_questionnaire_snapshot', 'reason'],
      'customer_questionnaire_id': questionnaireId
    }).then(result => {
      networkData.customerSnapshotReasons = result
    });

  }

  function retrieveMessageTemplates(merchantId) {
    return getMessageTemplates({'merchants_id': merchantId, sort: 'share+ASC'}).then(result => {
      networkData.messageTemplates = result
    });

  }

  function retrieveGroupLocations(locationId) {
    return getGroupLocations({'locations_id': locationId, expand: 'group'}).then(result => {
      networkData.groupLocations = result
    });
  }

  function retrieveComments(questionnaireId) {
    return getCustomerQuestionnaireComments(questionnaireId).then(result => {
      networkData.comments = result;
    })
  }

  function retrievePermissions(userId){
    networkData.permissions = JSON.parse(localStorage.getItem("ngStorage-permissions"));

    if (networkData.permissions != null)
      return Promise.resolve();

    return getPermissions(userId).then(result => {
      networkData.permissions = result.permissions;
    });
  }

  function retrieveMenuItems(merchantId, transactionId) {
    return getMenuItems(merchantId, transactionId).then(menuItems => {
      networkData.menuItems = menuItems;
    })
  }

  function retrieveMenuItemLevelFeedback(merchantId, transactionId) {
    return getMenuItemLevelFeedback({'MerchantId': merchantId, 'TransactionId': transactionId, 'PageSize': 100}).then(menuItemLevelFeedback => {
      // Add item notes to menuItemLevelFeedback
      _.each(menuItemLevelFeedback.data, (mlfItem) => {
        var matchingItem = _.find(networkData.menuItems, (item) => { return item.itemId == mlfItem.itemId });
        if (!!matchingItem) {
          mlfItem.itemNote = matchingItem.note;
        }
      });

      networkData.menuItemLevelFeedback = menuItemLevelFeedback
    });
  }

  function retrieveSurveyTags(merchantId, surveyId) {
    return getTagsFromSurvey(merchantId, surveyId).then(tags => {
      networkData.tags = tags;
    })
  }

  function retrieveMerchantTags(merchantId) {
    return getActiveTags(merchantId).then(tags => {
      networkData.merchantTags = tags.data;
    })
  }

  function loadAllData() {
    let tattlePromise = Promise.resolve();

    if (surveyId) {
      tattlePromise = retrieveSingleSurveyWithParams(
        surveyId,
        { expand: ['message_logs', 'customers', 'location', 'questionnaire', 'rewards'].toString() }
      );
    }

    return tattlePromise.then(() => Promise.allSettled([
      retrieveSnapshot(tattle.id),
      retrieveSurvey(tattle.id),
      retrieveAllSurveys(tattle.customers_id, tattle.merchants_id),
      retrieveAddress(tattle.location.addresses_id),
      retrieveCustomerRewards(tattle.customers_id, tattle.merchants_id),
      retrieveCustomerMerchant(tattle.customers_id, tattle.merchants_id),
      retrieveMessageLogs(tattle.customers_id, tattle.merchants_id),
      retrieveLocations(tattle.merchants_id),
      retrieveMerchantLinks(tattle.locations_id, tattle.merchants_id),
      retrieveCustomerSnapshotReasons(tattle.id),
      retrieveMessageTemplates(tattle.merchants_id),
      retrieveGroupLocations(tattle.location.id),
      retrieveComments(tattle.id),
      retrieveMerchantRewards(tattle.merchants_id),
      retrievePermissions($userId),
      retrieveMerchantTags(tattle.merchants_id),
      retrieveSurveyTags(tattle.merchants_id, tattle.id)
    ])).then(() => {
      return retrieveTransactions(tattle.id, tattle.location.id)
    }).then(() => {
      //Error treatment?
      if(!networkData.survey) {
        isOpen = false
        window.setTimeout(() => {
          closeClicked();
          showToastError();
        }, 500)
      }
      loading = false;
    })
  }

  const unsubscribe = sidebarOpenStore.subscribe(payload => {
    if (payload.id === sidebarId) {
      lastPayload = payload;
      networkData = {};
      transaction = undefined;
      surveyId = undefined;
      tattle = undefined;
      loading = true;
      isOpen = payload.isOpen;
      surveyId = payload.data.surveyId;
      tattle = payload.data.tattle;
      if (isOpen) {
        loadAllData();
      }
    }
  });

  const showToastError = () => {
    toast.push({
      ...toastOptions,
      theme: toastErrorTheme,
      component: {
        src: ToastError,
        props: {
          message: "Survey Loading Failed",
          onClose: closeToast
        }
      }
    });
  }

  const closeToast = () => {
    toast.pop();
  }

  // This is triggered if the user clicks off of the modal
  $: if(!isOpen){closeClicked()}

  function closeClicked() {
    sidebarOpenStore.set({
      id: sidebarId,
      isOpen: false,
      data: {}
    })
    replyTooltipStore.set({
      anchorElem: null,
      isVisible: false,
      data: null
    })
  }

  // hide the two custom tooltips
  function drawerClicked(e) {
    replyTooltipStore.set({
      anchorElem: null,
      isVisible: false,
      data: null
    })
    feedbackTooltipStore.set({
      anchorElem: null,
      isVisible: false,
      data: null
    })
  }

  onDestroy(() => {
    // Need to do this so it doesn't re-open when we come back to the page
    closeClicked();
    unsubscribe();
  });

</script>

<div class="enhanced-profile-sidebar {className}" on:click={drawerClicked}>
  <QuickPeek {autoId} bind:open={isOpen}>
    <div slot="title">
      {#if !loading}
        <EnhancedProfileHeader
          class="header"
          customerMerchant={networkData.customerMerchant}
          filteredMerchantSurveys={networkData.filteredMerchantSurveys}
          survey={networkData.survey}
          tattle={tattle}
          onClose={closeClicked}
          messageTemplates={networkData.messageTemplates}
          groupLocations={networkData.groupLocations}
          merchantRewards={networkData.merchantRewards}
          links={networkData.merchantLinks}
          permissions={networkData.permissions}
          anonymous={anonymous}
          surveyTags={networkData.tags}
          merchantTags={networkData.merchantTags}
          on:tagsAdded={retrieveSurveyTags(tattle.merchants_id, tattle.id)}
        />
      {/if}
    </div>
    <div slot="content" class="content" style="position: relative;">
      {#if loading}
        <CircularProgress style="height: 32px; width: 32px; margin-top: 50vh; left: 50%;" indeterminate/>
      {/if}
      {#if !loading}
        <!--
          Should we double check the transaction call to make sure that we're grabbing the right one since it's returning an array?
          I think this should be fine since you'd hopefully never have two transactions associated with a single survey response
        -->
        <EnhancedProfileFeedbackSection class="secondary-header"
                                        {tattle}
                                        survey={networkData.survey}
                                        transaction={transaction}
                                        locations={networkData.locations}
                                        permissions={networkData.permissions}
                                        menuItems={networkData.menuItems}
                                        menuItemLevelFeedback={networkData.menuItemLevelFeedback}/>

        <EnhancedProfileRepliesSection
          messageLogs={$messageLogsStore.data}
          {tattle}
          address={networkData.address}
          incident={networkData.survey?.incident}
          customerRewards={$customerRewardsStore}
          filteredMerchantSurveys={networkData.filteredMerchantSurveys}
          flaggedSurveys={networkData.flaggedMerchantSurveys}
        />

        <EnhancedProfileOperationalCategorySection
          customerQuestionnaireSnapshots={networkData.customerQuestionnaireSnapshots}
          customerSnapshotReasons={networkData.customerSnapshotReasons}
          survey={networkData.survey}
        />

        <EnhancedProfileSurveySection
          answers={networkData.survey.answers}
        />

        <EnhancedProfileComments
          questionnaireId={tattle.id}
          comments={networkData.comments}
        />

        <EnhancedProfileFooter surveyId={networkData.survey.id}/>

        <ReplyTooltip/>

      {/if}
    </div>
  </QuickPeek>
  <div class="toast-wrapper"><SvelteToast /></div>
</div>

<style lang="scss">

  * :global(.secondary-header) {
    background-color: #ffffff;
  }

  * :global(.content) {
    background-color: var(--mdc-theme-background);
  }

</style>

