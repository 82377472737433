<script>
  import { onMount } from "svelte";
  import Tooltip, {Wrapper} from '@smui/tooltip';

  import CircularProgress from "@smui/circular-progress";
  import DataTable from "tttl-core-ui/src/components/DataTable.svelte";
  import { Body, Cell, Head, Row, Label } from "@smui/data-table/styled";
  import Pagination from "tttl-core-ui/src/components/Pagination.svelte";
    
  export let locations;
  export let hideLastSynced;
  export let showPhone; 
  export let tooltipPhone;

  let loading = false;
  let rows = [];
  let slice;

  // Pagination variables
  let rowsPerPage = 10;
  let currentPage = 0;
  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, rows.length);
  $: slice = rows.slice(start, end);
  $: lastPage = Math.max(Math.ceil(rows.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  onMount(() => {
    loadLocations();
  });

  async function loadLocations() {
    rows = locations;
  }
</script>

<div class="integrationLocations">
    {#if loading}
      <CircularProgress
        style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px;"
        indeterminate
      />
    {:else}
      <h1 data-cy="integrations-location-mappings-heading">Location Mappings</h1>
      <DataTable
        autoId="integrations-location-mappings-table"
        class="locations-table"
      >
        <Head slot="head">
          <Row>
            <Cell columnId="isActive" class="statusCol">
              <Label data-cy="integrations-location-header-status-label" class="header-label">
                Status
              </Label>
            </Cell>
            <Cell columnId="covaLabel" class="align-left">
              <Label data-cy="integrations-location-header-integration-label" class="header-label"
                >Integration Identifier</Label
              >
            </Cell>
            <Cell columnId="tattleLocationLabel" class="align-left">
              <Label data-cy="integrations-location-header-tattle-label" class="header-label"
                >Tattle Location Name</Label
              >
            </Cell>
            {#if !hideLastSynced}
              <Cell columnId="lastSyncEndDateTime" class="align-left">
                <Label data-cy="integrations-location-header-last-synced-label" class="header-label"
                  >Last Synced</Label
                >
              </Cell>
            {/if}
            {#if showPhone}
            <Cell columnId="tattleLocationPhone" class="align-left">
              <Label data-cy="integrations-location-header-tattle-phone" class="header-label">Tattle Location Phone</Label>
              <Wrapper>
                <div class="question-mark material-icons-outlined">help</div>
                <Tooltip style="z-index: 9999;">
                  <slot name="tooltip">
                    {tooltipPhone}        
                  </slot>
                </Tooltip>
              </Wrapper>
            </Cell>
            {/if}
          </Row>
        </Head>
        <Body slot="body">
          {#each slice as row}
            <Row>
              <Cell style="font-weight: 500;">
                {#if row.isActive}
                  <span data-cy="integrations-location-row-{row.integrationLocationId}-integration-status" class="badge badge-success">Active</span>
                {:else}
                  <span data-cy="integrations-location-row-{row.integrationLocationId}-integration-status" class="badge badge-danger">Inactive</span>
                {/if}
              </Cell>
              <Cell data-cy="integrations-location-row-{row.integrationLocationId}-integration-label" class="align-left locationRowCell"
                >{row.integrationLocationLabel}
              </Cell>
              <Cell data-cy="integrations-location-row-{row.integrationLocationId}-tattle-label" class="align-left locationRowCell">
                {row.tattleLocationLabel}
              </Cell>
              {#if !hideLastSynced}
                <Cell data-cy="integrations-location-row-{row.integrationLocationId}-last-synced" class="align-left locationRowCell">
                  {row.lastSyncEndDateTime ?? ""}
                </Cell>
              {/if}
              {#if showPhone}
                <Cell data-cy="integrations-location-row-{row.integrationLocationId}-tattle-phone" class="align-left locationRowCell">
                  {row.tattleLocationPhone ?? ""}
                </Cell>
              {/if}
            </Row>
          {/each}
        </Body>

        <Pagination
          autoId="integrations-pagination"
          slot="paginate"
          class="paginator"
          {lastPage}
          bind:currentPage
          totalRecordCount={rows.length}/>
      </DataTable>
    {/if}
</div>

<style>
  :global(.locations-table .statusCol) {
    width: 125px;
  }

  :global(.locations-table .align-left) {
    text-align: left !important;
    padding-left: 8px !important;
  }

  :global(.integrationLocations) {
    margin-top: 32px;
  }

  .question-mark {
    font-size: 14px;
    margin-left: 2px;
    margin-bottom: 3px;
    cursor: pointer;
  }
</style>
