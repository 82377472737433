<script>
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import {Label} from '@smui/button';
  import {addComment} from 'src/services/api/customer-questionnaire-comments';
  import {userId, user} from 'src/services/auth.js';
  import Comment
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/comments-section/comment.svelte';


  /***** enhanced-profile-comments-section Component *****
   * Displays any comments on the questionnaire along with allowing editing/adding additional comments
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param questionnaireId {String} - The unique id for the customer's questionnaire
   * @param comments {Array} - An array of comments returned from the customer questionnaire comment endpoint with user expanded
   */

  export let autoId = 'enhanced-profile-comments-section';
  export {className as class};

  export let questionnaireId;
  export let comments;

  let commentInput = '';


  let className = '';

  function submitComment() {
    if (commentInput === '') {
      return;
    }
    addComment($userId, questionnaireId, commentInput).then((result) => {
      result.user = $user;
      comments.push(result);
      comments = comments;
      commentInput = '';
    })
  }

</script>

<div class="enhanced-profile-comments-section {className}" data-cy={autoId}>
  <div class="comments-title">
    Comments
  </div>

  <div class="comments-content">
    {#each comments as comment}
      <Comment {comment}/>
    {/each}
  </div>

  <div class="comments-input">
    <textarea data-cy="${autoId}-comment-input" bind:value={commentInput}></textarea>
  </div>

  <div class="add-comment">
    <Button
      autoId="${autoId}-add-comment"
      type="raised"
      color="primary"
      on:click={() => {
        submitComment();
      }}
    >
      <Label>
        <div class="label-contents">
          <div>
            Add Comment
          </div>
        </div>
      </Label>
    </Button>

  </div>

</div>


<style lang="scss">

  textarea {
    width: 100%;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border-color: rgba(127, 135, 152, 0.5);
    resize: none;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
  }

  .enhanced-profile-comments-section {
    padding: 20px;
  }

  * :global(button) {
    border-radius: 30px;
    height: 30px;
    padding: 20px 0;
  }

  .label-contents {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 18px;
  }

  .comments-title {
    font-weight: 500;
  }

  .add-comment {
    display: flex;
    justify-content: end;
  }

</style>

