import {headers} from 'src/services/auth';

/**
 * Retrieves an AI reply to a given merchant id, survey id and type
 * @param {String} merchantId The id for the merchant
 * @param {String} surveyId The id for the survey
 * @param {String} type The type of the reply
 * @returns {Promise<String>} An string with the AI reply
 */
export function generateAIReply(merchantId, surveyId, type) {
  const baseURL = window.INTEGRATIONS_ENGINE_URL;

  const data = {type: type, regenerate: true};
  return fetch(`${baseURL}v3/api/merchants/${merchantId}/Surveys/${surveyId}/generate-ai-reply`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  })
  .then(result => result.text());
}

export function getActiveTags(merchantId) {
  const baseURL = window.INTEGRATIONS_ENGINE_URL;

  const query =
  `?TagType=SurveyResponses` +
  `&Active=true` +
  `&PageSize=2500`

  return fetch(`${baseURL}v3/api/merchants/${merchantId}/tags${query}`, {
    method: 'GET',
    headers: headers
  })
  .then(result => result.json());
}

export function getTagsFromSurvey(merchantId, surveyId) {
  const baseURL = window.INTEGRATIONS_ENGINE_URL;

  return fetch(`${baseURL}v3/api/merchants/${merchantId}/Surveys/${surveyId}/tags`, {
    method: 'GET',
    headers: headers
  })
  .then(result => result.json());
}

export function addTagsToSurvey(merchantId, surveyId, tags) {
  const baseURL = window.INTEGRATIONS_ENGINE_URL;

  return fetch(`${baseURL}v3/api/merchants/${merchantId}/Surveys/${surveyId}/tags`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(tags)
  })
  .then(result => result);
}
