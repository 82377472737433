<script>
  /***** low-category-performance-details Component *****
   * Description goes here
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param categories {String[]} - An array of strings to display as categories with a less than 50% rating
   */

  export let autoId = 'low-category-performance-details';
  export {className as class};
  export let categories;

  let className = '';

</script>

<div class="low-category-performance-details {className}" data-cy={autoId}>
  <div class="category-performance-header">
    Low Category Performance
  </div>
  <div class="category-performance-description">
    The following categories received at least one response with a score of
    <span class="rating">50% or less</span>.
  </div>
  <div class="low-ratings">
    <ol class="low-ratings-categories">
      {#each categories as category}
        <li>
          {category}
        </li>
      {/each}
    </ol>
  </div>
</div>


<style lang="scss">
  .low-category-performance-details {
    max-width: 250px;
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    border-top-width: 0;
    border-bottom-width: 0;
  }
  .category-performance-header {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: 52px;
    background-color: #f0f0f0;
    text-align: center;
    margin-bottom: 20px;
  }

  .category-performance-description {
    font-size: var( --mdc-typography-subtitle2-font-size);
    margin-left: 30px;
    margin-right: 30px;

    .rating {
      color: #b80536;
    }
  }

  .low-ratings {
    padding-top: 20px;
    font-weight: bold;
    font-size: var( --mdc-typography-subtitle2-font-size);
  }

  .low-ratings-categories {
    padding-left: 40px;
  }

  li {
    margin-bottom: 3px;
  }

</style>

