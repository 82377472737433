<script>
  import {onMount} from "svelte";
  import DataTable from "tttl-core-ui/src/components/DataTable.svelte";
  import {Body, Cell, Head, Label, Row} from '@smui/data-table/styled';
  import IconButton from '@smui/icon-button';
  import TattleScoreDisplay from 'src/building-blocks/tattle-score-display.svelte';
  import {getDateEnding, parseServerDate} from "src/services/utility/dateParser";

  import {replyTooltipStore} from "src/stores/replyTooltipStore";
  import {feedbackTooltipStore} from "src/stores/feedbackTooltipStore";

  /***** manager-replies-table Component *****
   * Displays the manager's replies to a given customer's survey
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param address {Object} - The object returned from the address endpoint
   * @param incident {Object} - The object returned from the incident endpoint, this is optional
   * @param filteredMerchantSurveys {Array} - An array containing all the customer's surveys for this merchant minus flagged feedback
   * @param flaggedSurveys {Array} - An array containing all the flagged customer's surveys for this merchant
   * @param element {Element} - The dom element for this component (used to calculate height for the resizer)
   */

  export let autoId = 'manager-replies-table';
  export {className as class};
  export let messageLogs;
  export let address;
  export let incident;
  export let filteredMerchantSurveys;
  export let flaggedSurveys;
  export let element;

  let className = '';

  let sort = 'date_time_sent';
  let sortDirection = 'ascending';
  let dateColumnElement;

  let rows = [];

  onMount(() =>{
    // Setting sortDirection descending doesn't update the internal store, so we cheat and set it on mount
    dateColumnElement?.getElement()?.click();
  });

  function getSatisfiedIcon(customerResolvedValue) {
    if (customerResolvedValue === 0) {
      return 'thumb_down';
    } else {
      return 'thumb_up';
    }
  }

  function getSatisfiedType(customerResolvedValue) {
    if (customerResolvedValue === 0) {
      return 'negative';
    } else if (customerResolvedValue === 1) {
      return 'positive';
    } else {
      return 'neutral';
    }
  }

  $: {
    rows = [];
    if (messageLogs?.length > 0) {
      for (let i = 0; i < messageLogs.length; ++i) {
        const survey = filteredMerchantSurveys?.find((survey) => survey.id === messageLogs[i].survey_id) || flaggedSurveys?.find((survey) => survey.id === messageLogs[i].survey_id);
        rows.push(messageLogs[i]);
        rows[i].cer = survey?.rating;
        rows[i].location = survey?.location.label;

        rows[i].date_sent = formatToDate(rows[i].date_time_sent);
        rows[i].time_sent = formatToTime(rows[i].date_time_sent);
        rows[i].month_date_sent = formatToMonthName(rows[i].date_time_sent);    // for email Tooltip

        rows[i].satisfiedIcon = getSatisfiedIcon(messageLogs[i]?.customer_resolved);
        rows[i].satisfiedType = getSatisfiedType(messageLogs[i]?.customer_resolved);
      }
      handleSort();
      rows = rows;
    }
  }

  function formatToDate(datetimeString) {
    const dateObject = parseServerDate(datetimeString);
    return dateObject.toFormat('MM/dd/yy');
  }

  function formatToTime(datetimeString) {
    let dateObject = parseServerDate(datetimeString);
    // If there is no address timezone, just don't display it, should never happen
    if (address?.timezone) {
      dateObject = dateObject.setZone(address.timezone);
    }
    return dateObject.toFormat('h:mm a ZZZZ');
  }

  // August 20th, 2021
  function formatToMonthName(datetimeString) {
    const dateObject = parseServerDate(datetimeString);
    const month = dateObject.toFormat('MMMM');
    const date = dateObject.toFormat('d');
    const ending = getDateEnding(parseInt(date));   // date is a string here
    const year = dateObject.toFormat('yyyy');
    return `${month} ${date + ending}, ${year}`;
  }

  function handleSort() {
    rows = rows.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]][
        sortDirection === 'ascending' ? 'slice' : 'reverse'
        ]();
      if (typeof aVal === 'string') {
        return aVal.localeCompare(bVal);
      }
      return aVal - bVal;
    });
    rows = rows;
  }


  // Reply Tooltip - poaitioning now in component

  function formatTooltipData(row) {
    return {
      messageType: row.message_type,
      htmlContent: row.message,
      dateSent: row.month_date_sent   // pre-formatted to month name
    };
  }

  // this runs when envelope icon is clicked
  // this tooltip is on the main drawer, not this component

  const showReplyTooltip = (e, row) => {
    const envelopeIcon = e.target;
    e.preventDefault();
    e.stopPropagation();                // do not interpret this as a drawer click which will hide it
    // hide the FeedbackTooltip
    feedbackTooltipStore.set({
      anchorElem: null,
      isVisible: false,
      data: null
    })
    // show the ReplyTooltip
    replyTooltipStore.set({
      anchorElem: envelopeIcon,
      isVisible: true,
      data: formatTooltipData(row),
    })
  }

</script>

<div class="{className}" data-cy={autoId} bind:this={element}>
  <DataTable sortable bind:sort bind:sortDirection on:MDCDataTable:sorted={handleSort} class="manager-reply-table">
    <Head slot="head">
      <Row>
        <Cell columnId="message_type" class="align-left">
          <Label class="header-label align-left">
            Type
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="location" class="align-left">
          <Label class="header-label align-left">
            Location
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="date_time_sent" class="align-left" bind:this={dateColumnElement}>
          <Label class="header-label align-left">
            Date
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="time_sent" class="align-left">
          <Label class="header-label align-left">
            Time
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="email">
          <Label class="header-label">
            Email
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="satisfied">
          <Label class="header-label">
            Satisfied
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="cer">
          <Label class="header-label">
            CER
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
      </Row>
    </Head>
    <Body slot="body">
    {#each rows as row}
      <Row>
        <Cell style="font-weight: 500;" class="align-left">{row.message_type}</Cell>
        <Cell class="align-left">{row.location}</Cell>
        <Cell class="align-left">{row.date_sent}</Cell>
        <Cell class="align-left">{row.time_sent}</Cell>
        <Cell>
          <span class="material-icons round-icon email-icon"
                on:click={(e) => showReplyTooltip(e, row)}
          >
            email
          </span>
        </Cell>
        <Cell>
          <span class="material-icons round-icon satisfied-icon {row.satisfiedType}">{row.satisfiedIcon}</span>
        </Cell>
        <Cell>
          <TattleScoreDisplay class="score-display" starRating={row.cer}/>
        </Cell>
      </Row>
    {/each}
    </Body>
  </DataTable>

  {#if rows.length === 0}
    <div class="no-messages" data-cy="no-manager-replies">
      <div class="no-messages-wrapper">
        <div class="no-messages-title">
          <span>
            No Messages
          </span>
        </div>
      </div>
    </div>
  {/if}

</div>


<style lang="scss">
  * :global(.manager-reply-table) {
    border-style: none;
  }

  * :global(.manager-reply-table .align-left) {
    text-align: left !important;
  }

  * :global(.manager-reply-table th.align-left span) {
    padding-left: 0 !important;
  }

  * :global(.manager-reply-table.tattle-table th.mdc-data-table__header-cell) {
    padding: 0;
  }

  * :global(.manager-reply-table.tattle-table table tbody tr) {
    height: 40px;
  }

  * :global(.manager-reply-table.tattle-table table tbody tr td.mdc-data-table__cell) {
    padding: 0;
    height: 40px;
  }

  * :global(.manager-reply-table.tattle-table .header-label) {
    font-size: 10px;
    //This is needed because the sort button is 28 px
    padding-left: 28px;
  }

  * :global(.manager-reply-table .score-display) {
    height: auto;
    border: none;
    width: auto;
  }

  * :global(.manager-reply-table .score-display .score-box) {
    height: 23px;
    width: 36px;
  }

  .no-messages {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
  }

  .no-messages-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: var(--tattle-subtitle-font-size);
  }

  .round-icon {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
  }

  .email-icon {
    color: var(--tattle-link-color);
    cursor: pointer;
  }

  .satisfied-icon {
    color: darkgray;

    &.positive {
      color: var(--tattle-success-color);
    }

    &.negative {
      color: var(--tattle-fail-color);
    }
  }

</style>
