import {DateTime} from 'luxon';

/**
 * This function takes a date from the server (do not use dates that have _local at the end of them) and parses it into a luxon date object
 * @param serverDateString {String} The date string returned from the server (does not include a timezone, but is in New York timezone)
 * @return {DateTime} a date time populated with the parsed date string
 */
export function parseServerDate(serverDateString) {
  return DateTime.fromFormat(serverDateString + ' America/New_York', 'yyyy-LL-dd hh:mm:ss z');
}

/**
 * This function takes a date from the server that has _local at the end of it and parses it into a luxon date object
 * @param localDateString {String} The date string returned from the server (does not include a timezone, but is in the submitting user's local timezone)
 * @return {DateTime} a date time object populated with the local date
 */
export function parseServerLocalDate(localDateString) {
  return DateTime.fromFormat(localDateString, 'yyyy-LL-dd hh:mm:ss');
}


/**
 * Returns the format string for the given day of the month
 * @param dayOfMonth {Number} - The day of the month
 * @returns {string} A string that can be appended to the number to make it the "nth"
 */
export function getDateEnding(dayOfMonth) {
  // 1st, 2nd, 3rd, 4th, 5th, 6th, 7th, 8th, 9th, 10th, 11th, 12th, 13th, 14th, 15th, 16th, 17th, 18th, 19th, 20th, 21st, 22nd, 23rd, 24th, 25th, 26th, 27th, 28th, 29th, 30th, 31st
  if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
    return 'st';
  } else if (dayOfMonth === 2 || dayOfMonth === 22) {
    return 'nd';
  } else if (dayOfMonth === 3 || dayOfMonth === 23) {
    return 'rd';
  } else {
    return 'th';
  }
}
