<script>
    export let size = 60;
  
    $: ratio = size / 54;
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
    <circle cx="27" cy="27" r="26" fill="#E5E5E5" stroke="#666666" stroke-width="2" stroke-dasharray="3 3"/>
    <path d="M26.8012 17.1818C24.5921 17.1818 22.8739 17.6727 21.6466 18.409C20.1739 19.3909 19.4375 20.8636 19.683 22.5818H24.5921C24.5921 21.8454 24.8375 21.3545 25.3284 21.109C25.8194 20.8636 26.3103 20.6181 27.0466 20.6181C27.783 20.6181 28.5194 20.8636 29.0103 21.3545C29.5012 21.8454 29.7466 22.3363 29.7466 23.0727C29.7466 23.809 29.5012 24.2999 29.2557 24.7909C28.7648 25.2818 28.2739 25.7727 27.783 26.0181C26.5557 26.7545 25.5739 27.4909 25.083 27.9818C24.1012 28.7181 23.8557 29.4545 23.8557 30.6818H28.7648C28.7648 29.9454 29.0103 29.4545 29.0103 28.9636C29.2557 28.4727 29.7466 28.2272 30.2375 27.7363C31.4648 27.2454 32.2012 26.509 32.9375 25.5272C33.6739 24.5454 33.9194 23.5636 33.9194 22.5818C33.9194 20.8636 33.183 19.3909 31.9557 18.409C30.9739 17.6727 29.0103 17.1818 26.8012 17.1818ZM23.8557 33.1363V38.0454H28.7648V33.1363H23.8557Z" fill="#666666"/>
</svg>

<style lang="scss">
    svg {
        transform: scale(var(--ratio, 1));
    }
</style> 
