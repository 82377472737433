<script>
  import { onMount } from "svelte";
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import IconButton from '@smui/icon-button';
  import { Label } from '@smui/button';
  import CircularProgress from "@smui/circular-progress";
  import Card from "@smui/card";
  import Dialog, { Title, Content, Actions, InitialFocus } from '@smui/dialog';
  import {
    getThanxIntegration,
    addThanxIntegration,
    updateThanxIntegration
  } from "src/services/integrationsEngineApi/integrations";
  import { getLocations } from "src/services/api/locations";
  import LocationMappings from "../locationMappings.svelte";
  import Snackbar from "@smui/snackbar";

  export let merchantId;
  let isLoading = true;
  let isActive = false;
  let webhookUrl = '';
  let isOAuthRevokeDialogOpen = false;
  let thanxLocations;
  let locations;
  let snackBar;
  let snackBarClass;
  let snackBarMessage;

  onMount(() => {
    loadAllData();
  });

  async function loadAllData() {
    // Retrieve Tattle Locations
    isLoading = true
    const result = await getLocations({
      active: 1,
      expand: "address",
      merchants_id: merchantId,
      page: 1,
      size: -1,
    });
    locations = result

    await retrieveThanxIntegration();
    isLoading = false;
  }

  function retrieveThanxIntegration() {
    return getThanxIntegration(merchantId).then(result => {
      let thanxIntegration = result
      isActive = thanxIntegration.isActive;
      webhookUrl = thanxIntegration.webhookUrl;
      
      thanxLocations = [];
      thanxIntegration.locations.forEach(item => {
        let tattleLocation = locations.find(x => x.id == item.locationId)
        thanxLocations.push({
          integrationLocationLabel: item.thanxLocationName,
          integrationLocationId: item.thanxLocationId,
          isActive: item.isActive ? true : false,
          tattleLocationId: tattleLocation?.id,
          tattleLocationLabel: tattleLocation?.label
        })
      });
    }).catch(() => {
      isActive = false;
    })
  }

  function activateIntegration() {
    addThanxIntegration(merchantId, true).then(async () => {
      await loadAllData()
      showSnackbarSuccess(true)
    }).catch(() => {
      showSnackbarError();
    })
  }
  
  function revokeAccess() {
    updateThanxIntegration(merchantId, false).then(async () => {
      await loadAllData()
      showSnackbarSuccess()
    }).catch(() => {
      showSnackbarError();
    })
  }

  function copyWebhookToClipboard() {
    navigator.clipboard.writeText(webhookUrl);
  }

  function showSnackbarSuccess(create = false) {
    snackBarClass = "integrationSuccessSurface";
    snackBarMessage = "Integration successfully deactivated!";
    if(create) {
      snackBarMessage = "Integration successfully created!";
    }
    snackBar.open();
  }

  function showSnackbarError() {
    snackBarClass = "integrationErrorSurface";
    snackBarMessage = "Error encountered while adding Integration";
    snackBar.open();
  }
</script>

<h1 data-cy="integrations-thanx-heading">Thanx</h1>
<hr/>

{#if isLoading}
  <CircularProgress
    style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px; left: 50%;"
    indeterminate
  />
{:else}
  <div class="m-t-lg">
    <p data-cy="integrations-thanx-activation-status-label">
      This integration is
      {#if isActive}
        <strong data-cy="integrations-thanx-activation-status-value" class="color-success">active</strong>.
      {:else}
        <strong data-cy="integrations-thanx-activation-status-value" class="color-danger">inactive</strong>.
        <p data-cy="integrations-thanx-activate-text" class="m-t-lg" style="display: flex; align-items: baseline;">
          To activate your Thanx integration,
          <Button autoId="integrations-thanx-activate-link" on:click={() => activateIntegration() }>
            <Label style="font-size: 14px; min-width: 0">Click here</Label>
          </Button>
        </p>
      {/if}
    </p>

    {#if isActive}
      <p class="m-t-lg">
        <Button autoId="integrations-thanx-revoke-access-link" on:click={() => isOAuthRevokeDialogOpen = true}>
          <Label>Revoke Access</Label>
        </Button>
      </p>
      {#if webhookUrl}
        <Card data-cy="integrations-thanx-webhook-url-card" variant="outlined" padded class="p-a-md">
          <p class="m-a-0">
            <strong data-cy="integrations-thanx-webhook-url-label">Webhook URL</strong>
          </p>
          <div data-cy="integrations-thanx-webhook-url-text" class="webhook__container">
            <IconButton data-cy="integrations-thanx-webhook-url-copy-button" class="material-icons" on:click={() => copyWebhookToClipboard()}>content_copy</IconButton>
            { webhookUrl }
          </div>
        </Card>
      {/if}
    {/if}
    
  </div>

  {#if isActive}
    <LocationMappings locations={thanxLocations} hideLastSynced />
  {/if}
  
{/if}

<Snackbar
  bind:this={snackBar}
  surface$data-cy="integrations-snackbar"
  surface$class={snackBarClass}
>
  <Label>{snackBarMessage}</Label>
</Snackbar>

<Dialog
  bind:open={isOAuthRevokeDialogOpen}
  aria-labelledby="thanx-revoke-access-dialog"
  aria-describedby="thanx-revoke-access-dialog-content"
>
  <Title data-cy="thanx-revoke-access-dialog-title" id="thanx-revoke-access-dialog-title">Confirm</Title>
  <Content data-cy="thanx-revoke-access-dialog-content" id="thanx-revoke-access-dialog-content">
    This will revoke Tattle's access to your Thanx account. Do you want to continue?
  </Content>
  <Actions>
    <Button autoId="thanx-revoke-access-dialog-button-continue" on:click={revokeAccess}>
      <Label>Continue</Label>
    </Button>
    <Button autoId="thanx-revoke-access-dialog-button-cancel" use={[InitialFocus]}>
      <Label>Cancel</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  :global(.p-a-md) {
    padding: 8px;
  }
  :global(.m-a-0) {
    margin: 0;
  }

  :global(.webhook__container) {
    display: flex;
    align-items: center;
  }

  :global(.integrationSuccessSurface) {
    background-color: var(--tattle-success-color);
  }
  :global(.integrationErrorSurface) {
    background-color: var(--tattle-fail-color);
  }
</style>