<script>
  import Card, { Content } from "@smui/card";
  import IconButton from "@smui/icon-button";
  import DataTable from "tttl-core-ui/src/components/DataTable.svelte";
  import Pagination from "tttl-core-ui/src/components/Pagination.svelte";
  import { Body, Cell, Head, Row, Label } from "@smui/data-table/styled";
  import { getCovaLocations } from "src/services/integrationsEngineApi/integrations";
  import { getLocations } from "src/services/api/locations";
  import { onMount } from "svelte";
  import covaLocationRow from "./covaLocationRow.svelte";
  import CircularProgress from "@smui/circular-progress";
  import Snackbar from "@smui/snackbar";

  export const pageId = "integrations";

  export let merchantId;
  export let companyId;
  export let integrationLocationsList;

  let loading = true;

  let snackBar;
  let snackBarClass;
  let snackBarMessage;

  function showSnackbarSuccess() {
    snackBarClass = "integratonSuccessSurface";
    snackBarMessage = "Location successfully updated!";
    snackBar.open();
  }

  function showSnackbarError() {
    snackBarClass = "integrationErrorSurface";
    snackBarMessage = "Error encountered while updating location";
    snackBar.open();
  }

  onMount(() => {
    loadAllData();
  });

  async function loadAllData() {
    loading = true;

    // Retrieve Tattle Locations
    const result = await getLocations({
      active: 1,
      expand: "address",
      merchants_id: merchantId,
      page: 1,
      size: -1,
    });
    locations = result

    await retrieveCovaLocations();
    loading = false
  }

  async function handleLocationsUpdated(response) {
    var isSuccess = response?.detail?.isSuccess;

    if(!isSuccess){
      showSnackbarError();
    }
    else {
      showSnackbarSuccess();
    }

    loading = true;
    await retrieveCovaLocations();
    loading = false;
  }

  function retrieveCovaLocations() {
    return getCovaLocations(merchantId).then((results) => {
      rows = [];
      integrationLocationsList.forEach((item) => {
        let existingMap = results.find(x => x.covaLocationId == item.locationId)
        rows.push({
          covaLabel: item.label,
          covaLocationId: item.locationId,
          isActive: existingMap?.isActive ? true : false,
          locationId: existingMap?.locationId,
          tattleLocationLabel: locations.find(x => x.id == existingMap?.locationId)?.label,
          lastSyncEndDateTime: existingMap?.lastSyncTimeUTC
        })
      })
      if(!sortedByUser) {
        defaultLocationsSort();
      } else {
        handleLocationsSort();
      }
    });
  }

  function handleEditRow(response) {
    let oldEditingRow = rows.find(row => row.covaLocationId == editingRow)
    if(oldEditingRow) {
      oldEditingRow.isActive = editingRowStatus
    }
    editingRow = response.detail.locationId
    editingRowStatus = response.detail.rowActive
    rows = rows
  }

  let editingRow = null;
  let editingRowStatus = null;

  let locations = [];
  let takenLocations = [];

  $: takenLocations = rows.map(x => x.locationId && x.locationId);

  let rows = [];
  let sort = "isActive";
  let isActiveColumnElement;
  let sortDirection = "ascending";
  let slice;
  let sortedByUser = false;

  // Pagination variables
  let rowsPerPage = 10;
  let currentPage = 0;
  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, rows.length);
  $: slice = rows.slice(start, end);
  $: lastPage = Math.max(Math.ceil(rows.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  function handleLocationsSort(userSort = true) {
    if(userSort) {
      sortedByUser = true
    }
    rows = rows.sort((a, b) => {
      let tempSortName = sort;
      //Handle nested objects
      if (sort.indexOf(".") > -1) {
        a = a[sort.slice(0, sort.indexOf("."))];
        b = b[sort.slice(0, sort.indexOf("."))];
        tempSortName = sort.slice(sort.indexOf(".")+1);
      }
      let aVal, bVal;
      if (a && a[tempSortName]){
        aVal = a[tempSortName]
      } else {
        aVal = "";
      }
      if (b && b[tempSortName]) {
        bVal = b[tempSortName]
      } else {
        bVal = ""
      }
      // const [aVal, bVal] = [a[tempSortName], b[tempSortName]];
      let first, second;
      if (sortDirection === 'ascending') {
        first = aVal;
        second = bVal;
      } else {
        first = bVal;
        second = aVal;
      }
      if (typeof first === 'boolean' && second === '') {
        second = false
      }
      if (typeof second === 'boolean' && first === '') {
        first = false
      }
      if (typeof first === 'string') {
        return first?.localeCompare(second);
      }
      return first - second;
    });
    rows = rows;
  }

  function defaultLocationsSort() {
    sortDirection = 'ascending';
    sort = 'covaLabel'
    handleLocationsSort(false);
    sortDirection = 'descending';
    sort = 'isActive';
    handleLocationsSort(false);
  }
</script>

<div class="integrationLocations">
  {#if loading}
    <div class="text-center">
      <CircularProgress
        style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px;"
        indeterminate
      />
    </div>
  {:else}
    <DataTable
      sortable
      bind:sort
      bind:sortDirection
      on:MDCDataTable:sorted={handleLocationsSort}
      class="locations-table"
    >
      <Head slot="head">
        <Row>
          <Cell columnId="isActive" class="align-left integrationSwitchCol" bind:this={isActiveColumnElement}>
            <Label data-cy="integrations-location-header-status-label" class="header-label align-left">
              Status
            </Label>
            <IconButton data-cy="integrations-location-header-status-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell columnId="covaLabel" class="align-left">
            <Label data-cy="integrations-location-header-integration-label" class="header-label align-left">
              Cova Location Name
            </Label>
            <IconButton data-cy="integrations-location-header-integration-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell columnId="tattleLocationLabel" class="align-left">
            <Label data-cy="integrations-location-header-tattle-label" class="header-label align-left">
              Tattle Location Name
            </Label>
            <IconButton data-cy="integrations-location-header-tattle-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell columnId="lastSyncEndDateTime" class="align-left">
            <Label data-cy="integrations-location-header-last-synced-label" class="header-label align-left">
              Last Synced
            </Label>
            <IconButton data-cy="integrations-location-header-last-synced-arrow" class="material-icons">arrow_drop_up</IconButton>
          </Cell>
          <Cell columnId="actions" class="unsortableHeaderCell integrationActionsCol">
            <!-- blank -->
          </Cell>
        </Row>
      </Head>
      <Body slot="body">
        {#each slice as row}
          <svelte:component
            this={covaLocationRow}
            {row}
            {locations}
            {takenLocations}
            {merchantId}
            {companyId}
            editingRowId={editingRow}
            isEditMode={editingRow == row.covaLocationId}
            on:locationsUpdated={handleLocationsUpdated}
            on:editRow={handleEditRow}
          />
        {/each}
      </Body>

      <Pagination
      autoId="integrations-pagination"
      slot="paginate"
      class="paginator"
      {lastPage}
      bind:currentPage
      totalRecordCount={rows.length}/>
    </DataTable>

    {#if rows.length === 0}
      <div class="no-messages m-t-lg" data-cy="no-manager-replies">
        <div class="no-messages-wrapper">
          <div class="no-messages-title">
            <span> No Locations </span>
            <p>
              <small>Cova Integration has been added, but we're still syncing the locations. Please refresh your browser or check back later.</small>
            </p>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</div>
<Snackbar bind:this={snackBar} surface$data-cy="integrations-snackbar" surface$class={snackBarClass}>
  <Label>{snackBarMessage}</Label>
</Snackbar>

<style lang="scss">
  * :global(.locationRow > td) {
    word-wrap: break-word;
    max-width: 180px;
  }
  * :global(.unsortableHeaderCell) {
    cursor: default;
  }
  * :global(.align-left) {
    text-align: left !important;
    padding-left: 8px !important;
  }
  * :global(.mdc-data-table__sort-icon-button) {
    opacity: 1;
  }
  :global(.integratonSuccessSurface) {
    background-color: var(--tattle-success-color);
  }
  :global(.mdc-snackbar__label) {
    font-size: 12pt;
  }
  :global(.integrationErrorSurface) {
    background-color: var(--tattle-fail-color);
  }
  :global(.paginator .mdc-chip) {
    font-size: 10pt;
  }
  :global(.paginator .page-summary) {
    font-size: 10pt;
  }
</style>
