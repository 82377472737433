<script>
  import Dialog, {Actions, Title, Header, Content} from '@smui/dialog';
  import {Label} from '@smui/button';
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import { createEventDispatcher } from 'svelte';

  export let show = false;
  export {className as class};

  let className = '';

  const dispatch = createEventDispatcher();

</script>

<Dialog class="confirm-sms-deactivation-modal {className}" data-cy='confirm-sms-deactivation-modal' bind:open={show}
        fullscreen>

  <Header>
    <Title tabindex="0" id="fullscreen-title">Deactivate SMS</Title>
  </Header>

  <Content style="text-align: left;">This will stop text messages from being sent. Are you sure you want to deactivate SMS?</Content>

  <Actions>
    <Button type="raised" color="primary" on:click={() => dispatch('actionDeactivate')}><Label>Deactivate</Label></Button>
    <Button type="outlined" color="secondary" action="close"><Label>Cancel</Label></Button>
  </Actions>

</Dialog>

<style lang="scss">
  :global(.confirm-sms-deactivation-modal .mdc-dialog__header) {
    margin-bottom: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecef;
  }

  :global(.confirm-sms-deactivation-modal .mdc-dialog__content) {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 14px;
  }

  :global(.confirm-sms-deactivation-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.confirm-sms-deactivation-modal .mdc-dialog__title) {
    font-size: 16px;
  }

  :global(.confirm-sms-deactivation-modal.mdc-dialog .mdc-dialog__surface) {
    max-width: 650px;
    max-height: calc(100% - 70px);
  }

  :global(.confirm-sms-deactivation-modal  .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.confirm-sms-deactivation-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

</style>