<script>
  import Card, { Actions, Content } from "@smui/card";
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import { Body, Cell, Head, Row, Label } from "@smui/data-table/styled";
  import DataTable from "tttl-core-ui/src/components/DataTable.svelte";
  import Switch from "@smui/switch";
  import { onMount } from "svelte";
  import {
    getIntegrations,
    addOrUpdateToastIntegration,
  } from "src/services/integrationsEngineApi/integrations";
  import Textfield from "@smui/textfield";
  import HelperText from '@smui/textfield/helper-text';
  import CircularProgress from "@smui/circular-progress";
  import toastIntegrationRow from "./toastIntegrationRow.svelte";
  import Snackbar from "@smui/snackbar";
  import LayoutGrid, { Cell as LayoutCell } from "@smui/layout-grid";

  export let merchantId;

  let loading = true;
  let snackBar;
  let snackBarClass;
  let snackBarMessage;

  function showSnackbarSuccess() {
    snackBarClass = "toastSuccessSurface";
    snackBarMessage = "Management Group successfully updated!";
    snackBar.open();
  }

  function showSnackbarError() {
    snackBarClass = "toastErrorSurface";
    snackBarMessage = "Error encountered while adding Management Group";
    snackBar.open();
  }

  onMount(() => {
    loadAllData();

    // Setting sortDirection descending doesn't update the internal store, so we cheat and set it on mount
    dateColumnElement?.getElement()?.click();
  });

  function loadAllData() {
    let tattlePromise = Promise.resolve();

    return tattlePromise
      .then(() => Promise.allSettled([retrieveIntegrations()]))
      .then(() => {
        //Error treatment?
        loading = false;
      });
  }

  function handleIntegrationsUpdated(response) {
    var isSuccess = response?.detail?.isSuccess;

    if (!isSuccess) {
      showSnackbarError();
      return;
    }
    showSnackbarSuccess();

    loading = true;

    let tattlePromise = Promise.resolve();

    return tattlePromise.then(() => Promise.allSettled([loadAllData()]));
  }

  let rows = [];
  let filteredRows;
  $: searchText,
    (filteredRows = rows.filter((row) => {
      return (
        row.managementGroupGuid
          ?.toLowerCase()
          ?.indexOf(searchText.toLowerCase()) > -1
      );
    }));

  let dateColumnElement;

  let showAddIntegrationRow = false;
  let inputMgg = "";
  let searchText = "";

  function retrieveIntegrations() {
    return getIntegrations(merchantId).then((results) => {
      rows = results.toastIntegrations;
    });
  }

  function addNewManagementGroupGUID() {
    return addOrUpdateToastIntegration(merchantId, inputMgg, true, true).then((results) => {
      if (results?.status > 300) {
        showSnackbarError();
      } else {
        showSnackbarSuccess();
        retrieveIntegrations();
      }

      resetForm();
    });
  }

  let validGUID = true;

  function checkIfValidGUID() {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    validGUID = regexExp.test(inputMgg.trim());
  }

  function resetForm() {
    showAddIntegrationRow = false;
    inputMgg = "";
    validGUID = true;
  }
</script>

<Card class="integrationsListCard">
  <Head class="cardHeader">
    <LayoutGrid class="headerGrid">
      <LayoutCell span={9}>
        <h1 data-cy="integrations-heading">Toast</h1>
      </LayoutCell>
      <LayoutCell span={3} class="searchCell text-right">
        <Textfield
          data-cy="integrations-search-field"
          input$data-cy="integrations-search-input"
          variant="outlined"
          bind:value={searchText}
          label="Search"
          class="searchBox"
        />
      </LayoutCell>
    </LayoutGrid>

    <hr />
  </Head>
  {#if loading}
    <CircularProgress
      style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px; left: 50%;"
      indeterminate
    />
  {:else}
    <div>
      {#if showAddIntegrationRow || (filteredRows != null && filteredRows.length > 0)}
        <Content class="integrationsContent">
          <DataTable class="management-group-table">
            <Head slot="head">
              <Row>
                <Cell
                  columnId="status"
                  class="unsortableHeaderCell align-left"
                />
                <Cell
                  columnId="status"
                  class="unsortableHeaderCell align-left"
                >
                  <Label
                    data-cy="integrations-group-header-status-label"
                    class="header-label align-left">Status</Label
                  >
                </Cell>
                <Cell columnId="managementGroupGuid" class="align-left">
                  <Label
                    data-cy="integrations-group-header-management-group-id-label"
                    class="unsortableHeaderCell header-label align-left"
                  >
                    Management Group ID
                  </Label>
                </Cell>
                <Cell
                  columnId="lastUpdated"
                  class="unsortableHeaderCell align-left"
                  bind:this={dateColumnElement}
                >
                  <Label
                    data-cy="integrations-group-header-last-updated-label"
                    class="header-label align-left">Last Updated</Label
                  >
                </Cell>
              </Row>
            </Head>
            <Body slot="body">
              {#each filteredRows as row, index}
                <svelte:component
                  this={toastIntegrationRow}
                  {row}
                  {index}
                  {merchantId}
                  on:integrationsUpdated={handleIntegrationsUpdated}
                />
              {/each}
              {#if showAddIntegrationRow}
              <Row>
                <Cell colspan="6">
                  <div class="toastAddIntegration">
                    <DataTable>
                      <Body slot="body">
                        <Row>
                          <Cell style="font-weight: 500;" class="align-left integrationSwitchCol">
                            <Switch
                              input$data-cy="integrations-add-new-management-group-id-status-switch"
                              checked="true"
                              disabled="true"
                            />
                          </Cell>
                          <Cell class="align-left" colspan="2">
                            <Textfield
                              data-cy="integrations-add-new-management-group-id-text-field"
                              input$data-cy="integrations-add-new-management-group-id-text-input"
                              variant="outlined"
                              bind:value={inputMgg}
                              label="Management Group GUID"
                              class="inputMggTextField"
                              invalid={!validGUID}
                              on:focusout="{() => checkIfValidGUID()}"
                            >
                            <HelperText persistent slot="helper" class="helper-error">
                                {#if !validGUID}
                                  Invalid Management Group GUID
                                {/if}
                              </HelperText>
                            </Textfield>
                          </Cell>
                          <Cell />
                          <Cell />
                          <Cell class="integrationActionsCol">
                            <div class="addMggButtonsCell">
                              <Button
                                autoId="integrations-add-new-management-group-id-save-button"
                                type="raised"
                                color="primary"
                                on:click={() => addNewManagementGroupGUID()}
                                disabled={inputMgg?.length < 1 || !validGUID}
                              >
                                <Label>
                                  <div class="label-contents">
                                    <div>Save</div>
                                  </div>
                                </Label>
                              </Button>
                              <Button
                                autoId="integrations-add-new-management-group-id-cancel-button"
                                type="raised"
                                color="secondary"
                                on:click={() => resetForm()}
                              >
                                <Label>
                                  <div class="label-contents">
                                    <div>Cancel</div>
                                  </div>
                                </Label>
                              </Button>
                            </div>
                          </Cell>
                        </Row>
                      </Body>
                    </DataTable>
                  </div>
                </Cell>
              </Row>
              {/if}
            </Body>
          </DataTable>
        </Content>
      {/if}
      <Actions>
        <Actions>
          {#if !showAddIntegrationRow}
            <Button
              autoId="integrations-add-new-management-group-id-add-button"
              on:click={() => {
                showAddIntegrationRow = true;
              }}
            >
              <Label class="actionLabel"
                >+ Add New Management Group ID</Label
              >
            </Button>
          {/if}
        </Actions>
      </Actions>
    </div>
  {/if}
</Card>
<Snackbar
  bind:this={snackBar}
  surface$data-cy="integrations-snackbar"
  surface$class={snackBarClass}
>
  <Label>{snackBarMessage}</Label>
</Snackbar>

<style lang="scss">
  :global(.integrationsListCard .integrationsContent) {
    padding-top: 0px;
  }
  :global(.integrationsListCard .cardHeader) {
    display: flex;
    padding: 8px;
  }
  :global(.integrationsListCard .cardHeader > h1) {
    flex: 1;
    margin-left: 8px;
  }
  :global(.integrationsListCard .searchBox) {
    margin-left: auto;
    margin-right: 8px;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  :global(.integrationsListCard .mdc-floating-label) {
    font-size: 12pt !important;
    height: 24px;
    top: 60%;
  }
  :global(.integrationsListCard .unsortableHeaderCell) {
    cursor: default;
  }
  :global(.integrationsListCard .addMggButtonsCell) {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;

    @media (max-width: 1320px) {
      float: right;
    }
  }

  :global(.integrationsListCard .integrationRow > td) {
    border-width: 1px !important;
  }
  :global(.integrationsListCard .align-left) {
    text-align: left !important;
    padding-left: 8px !important;
  }
  :global(.integrationsListCard .mdc-data-table__sort-icon-button) {
    opacity: 1;
  }
  :global(.integrationsListCard .actionLabel) {
    font-size: 12pt;
  }
  :global(.toastSuccessSurface) {
    background-color: var(--tattle-success-color);
  }
  :global(.mdc-snackbar__label) {
    font-size: 12pt;
  }
  :global(.toastErrorSurface) {
    background-color: var(--tattle-fail-color);
  }
  :global(.inputMggTextField) {
    width: 350px;
  }
  :global(.mdc-text-field__input) {
    font-size: 12pt;
  }

  .toastAddIntegration {
    margin: 8px 20px 8px 20px;
  }

  :global(.integrationsListCard .integrationSwitchCol) {
    width: 100px;
  }

  :global(.integrationsListCard .integrationActionsCol) {
    width: 200px;
  }

  :global(.integrationsListCard .headerGrid) {
    margin: 0; 
    padding: 0;
    width: 100%;
    padding-left: 8px;
  }
  
  :global(.helper-error) {
    color: var(--mdc-theme-error) !important;
  }

  :global(.integrationsListCard .mdc-floating-label) {
    line-height: 1.3rem;
  }
</style>
