const colorRanges = [
  '#b70034',
  '#b70034',
  '#b70034',
  '#f0495d',
  '#f0495d',
  '#f88245',
  '#ffbc2b',
  '#ffbc2b',
  '#5EB768',
  '#009564',
  '#007244'
];

/***
 * Returns a hex code color for the given rating percentage
 * @param rating {Number} - A rating between 0 and 100
 * @return {String} - A string representing the ratings color in hex code
 */
export function getColorForRatingPercentage(rating) {
  if (rating > 100) {
    rating = 100;
  } else if (rating < 0){
    rating = 0;
  }

  return colorRanges[Math.floor(rating/10)];
}
