import {headers, baseURL} from 'src/services/auth';

/**
 * Retrieves the rewards for a given customer
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<any>} A promise that resolves to the rewards customer object
 */
export function getCustomerRewards(params) {
  const url = new URL(`${baseURL}/v2/api/rewards-customers`);
  let tail = '';

  // Have to do this because url search params will escape any commas in the expand
  if (params.expand) {
    if (Object.keys(params).length > 1){
      tail = `&expand=${params.expand}`;
    } else {
      tail = `?expand=${params.expand}`;
    }
    delete(params.expand);
  }

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString() + tail, {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.rewards_customers);
}


/**
 *
 * @param merchantsId {Number} - The merchant's id
 * @param customersId {Number} - The customer the reward is being added to
 * @param customerQuestionnaireId {Number} - The questionnaire to be associated with the reward
 * @param rewardsId {Number} - The reward id
 * @param locationsId {Number} - The location the reward is associated with
 * @param sourceLocationsId {Number} - The location the reward is associated with? TODO: Double check this
 * @param waitTime {Number} - Amount of time to wait until reward is active
 * @param lifeSpan {Number} - How long the reward is valid, in days
 * @return {Promise<any>}
 */
export function addCustomerReward(merchantsId, customersId, customerQuestionnaireId, rewardsId, locationsId, sourceLocationsId, waitTime, lifeSpan) {
  const url = new URL(`${baseURL}/v2/api/rewards-customers`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      merchants_id: merchantsId,
      customers_id: customersId,
      customer_questionnaire_id: customerQuestionnaireId,
      rewards_id: rewardsId,
      locations_id: locationsId,
      source_locations_id: sourceLocationsId,
      wait_time: waitTime,
      life_span: lifeSpan
    })
  }).then((result) => {
    return result.json();
  });
}
