<script>
  import { onMount } from "svelte";
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import { Label } from '@smui/button';
  import CircularProgress from "@smui/circular-progress";
  import Dialog, { Title, Content, Actions, InitialFocus } from '@smui/dialog';
  import {
    getIntegrations,
    getSquareIntegration,
    getSquareOAuthAuthorizeUrl,
    revokeSquareOAuthToken
  } from "src/services/integrationsEngineApi/integrations";
  import { getLocations } from "src/services/api/locations";

  import LocationMappings from "../locationMappings.svelte";

  export let merchantId;
  let isLoading = true;
  let isActive = false;
  let oAuthInfo = {
    accessTokenExpiresAt: null,
    accessTokenRevokedAt: null,
    needsAuthorization: false,
    accessTokenStatus: ""
  }
  let isOAuthAuthorizeDialogOpen = false;
  let isOAuthRevokeDialogOpen = false;
  let squareLocations;
  let locations;
  let isRevoking = false;

  onMount(() => {
    loadAllData();
  });

  async function loadAllData() {
    isLoading = true
    const result = await getLocations({
      active: 1,
      expand: "address",
      merchants_id: merchantId,
      page: 1,
      size: -1,
    });
    locations = result

    await retrieveSquareIntegration();
    await retrieveIntegrations();
    isLoading = false;
  }

  function retrieveSquareIntegration() {
    return getSquareIntegration(merchantId).then(result => {
      let squareIntegration = result
      isActive = squareIntegration.isActive;
      
      squareLocations = [];
      squareIntegration.locations.forEach(item => {
        let tattleLocation = locations.find(x => x.id == item.locationId)
        squareLocations.push({
          integrationLocationLabel: item.squareLocationName,
          integrationLocationId: item.squareLocationId,
          isActive: item.isActive ? true : false,
          tattleLocationId: tattleLocation?.id,
          tattleLocationLabel: tattleLocation?.label,
          lastSyncEndDateTime: item?.lastSyncTimeUtc
        })
      });
    }).catch(() => {
      isActive = false;
    })
  }

  function retrieveIntegrations() {
    getIntegrations(merchantId).then(result => {
      isLoading = false;
      let squareIntegration = result?.squareIntegration || {};
      oAuthInfo.accessTokenExpiresAt = squareIntegration.accessTokenExpiresAt ? new Date(squareIntegration.accessTokenExpiresAt) : null;
      oAuthInfo.accessTokenRevokedAt = squareIntegration.accessTokenRevokedAt ? new Date(squareIntegration.accessTokenRevokedAt) : null;
      oAuthInfo.needsAuthorization = !squareIntegration.accessTokenEncrypted || !!oAuthInfo.accessTokenRevokedAt;
    })
  }


  function startSquareOAuthProcess() {
    getSquareOAuthAuthorizeUrl(merchantId).then(result => {
      if (!result?.squareOAuthAuthorizeUrl) return;
      window.open(result.squareOAuthAuthorizeUrl, "_self");
    });
  }

  async function revokeAccess() {
    try {
      isRevoking = true;
      await revokeSquareOAuthToken(merchantId);
      let retries = 10;
      while (retries > 0) {
        await new Promise(r => setTimeout(r, 3000)); // Wait for 3 seconds
        const result = await getSquareIntegration(merchantId);
        if (!result.isActive) {
          // If the status has updated to 'inactive', refresh the page data
          await loadAllData();
          break;
        }
        retries--;
      }
    } catch (error) {
      console.error(error);
    } finally {
      isRevoking = false;
    }
  }
</script>

<h1 data-cy="integrations-square-heading">Square</h1>
<hr/>

{#if isLoading}
  <CircularProgress
    style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px; left: 50%;"
    indeterminate
  />
{:else}
  <div class="m-t-lg">
    <p data-cy="integrations-square-activation-status-label">
      This integration is
      {#if isActive}
        <strong data-cy="integrations-square-activation-status-value" class="color-success">active</strong>.
      {:else}
        <strong data-cy="integrations-square-activation-status-value" class="color-danger">inactive</strong>.
        {#if oAuthInfo.needsAuthorization}
          <p data-cy="integrations-square-activate-text" class="m-t-lg" style="display: flex; align-items: baseline;">
            To activate your Square integration,
            <Button autoId="integrations-square-activate-link" on:click={() => isOAuthAuthorizeDialogOpen = true }>
              <Label style="font-size: 14px; min-width: 0">Click here</Label>
            </Button>
          </p>
        {/if}
      {/if}
    </p>

    <p data-cy="integrations-square-token-status-label" class="m-t-lg">
      {#if oAuthInfo.accessTokenRevokedAt}
        Access token status: <span data-cy="integrations-square-token-status-value" class="badge badge-danger">Revoked {oAuthInfo.accessTokenRevokedAt.toLocaleString()}</span>
      {:else if oAuthInfo.accessTokenExpiresAt}
        {#if oAuthInfo.accessTokenExpiresAt > Date.now()}
          Access token status: <span data-cy="integrations-square-token-status-value" class="badge badge-success">Valid</span>
          <p data-cy="integrations-square-complete-your-setup-text" class="alert alert-info m-t-lg">
            Please contact your Customer Success Manager to complete setting up your integration.
          </p>
        {:else}
          Access token status: <span data-cy="integrations-square-token-status-value" class="badge badge-danger">Expired {oAuthInfo.accessTokenExpiresAt.toLocaleString()}</span>
        {/if}
        <p class="m-t-lg">
          <Button autoId="integrations-square-revoke-access-button" on:click={() => isOAuthRevokeDialogOpen = true} disabled={isRevoking}>
            <Label>{isRevoking ? 'Revoking...' : 'Revoke Access'}</Label>
          </Button>
        </p>
      {/if}
    </p>
  </div>

  {#if isActive}
    <LocationMappings locations={squareLocations} />
  {/if}
  
{/if}

<Dialog
  bind:open={isOAuthAuthorizeDialogOpen}
  aria-labelledby="square-oauth-dialog"
  aria-describedby="square-oauth-dialog-content"
>
  <Title data-cy="square-oauth-dialog-title" id="square-oauth-dialog-title">Confirm</Title>
  <Content data-cy="square-oauth-dialog-content" id="square-oauth-dialog-content">
    To connect your Tattle and Square accounts, you will be redirected to Square to authorize Tattle's access. Do you want to continue?
  </Content>
  <Actions>
    <Button autoId="square-grant-access-dialog-continue-button" on:click={startSquareOAuthProcess}>
      <Label>Continue</Label>
    </Button>
    <Button autoId="square-grant-access-dialog-cancel-button" use={[InitialFocus]}>
      <Label>Cancel</Label>
    </Button>
  </Actions>
</Dialog>

<Dialog
  bind:open={isOAuthRevokeDialogOpen}
  aria-labelledby="square-oauth-revoke-access-dialog"
  aria-describedby="square-oauth-revoke-access-dialog-content"
>
  <Title data-cy="square-oauth-revoke-dialog-title" id="square-oauth-revoke-dialog-title">Confirm</Title>
  <Content data-cy="square-oauth-revoke-dialog-content" id="square-oauth-revoke-dialog-content">
    This action will revoke Tattle's access to your Square account. All data syncing will be halted. Do you want to continue?
  </Content>
  <Actions>
    <Button autoId="square-revoke-access-dialog-continue-button" on:click={revokeAccess}>
      <Label>Continue</Label>
    </Button>
    <Button autoId="square-revoke-access-dialog-cancel-button" use={[InitialFocus]}>
      <Label>Cancel</Label>
    </Button>
  </Actions>
</Dialog>
