<script>
  import Card, { Content as CardContent, PrimaryAction, Media } from "@smui/card";
  import Dialog, { Title, Content, Actions } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import {getIntegrationUI} from 'src/services/integrationsEngineApi/integrationsUI';

  export let integration;
  export let ngStateService;

  const integrationUI = getIntegrationUI(integration.ref)
  let isDialogOpen = false;

  function getIntegrationLogoUrl(imgPath) {
    return `https://${window.BRANDS_BUCKET}.s3.amazonaws.com/${imgPath}`;
  }

  function onTileClick() {
    if (integrationUI) {
      ngStateService.go("admin.global.integration-detail", { partnerRef: integration.ref });
    } 
    else {
      isDialogOpen = true;
    }
  }
</script>


<Card data-cy="integrations-{integration.ref.toLowerCase()}-tile" class="integration-tile">
  <PrimaryAction on:click={onTileClick}>
    <h4 data-cy="integrations-{integration.ref.toLowerCase()}-tile-header" class="integration-tile-header">
      {integration.label}
      {#if integration.active}
        <span data-cy="integrations-{integration.ref.toLowerCase()}-tile-badge-active" class="badge badge-success">Active</span>
      {:else if integration.comingSoon}
        <span data-cy="integrations-{integration.ref.toLowerCase()}-tile-badge-future" class="badge">Coming soon</span>
      {/if}
    </h4>
    <Media
      data-cy="integrations-{integration.ref.toLowerCase()}-tile-logo"
      class="integration-tile-img"
      style="--imgsrc: url('{getIntegrationLogoUrl(integration.logoUrl)}');"
      aspectRatio="16x9"
    />
    {#if integration.description}
      <CardContent style="padding: 0; padding-top: 8px;">
        <p>{integration.description}</p>
      </CardContent>
    {/if}
  </PrimaryAction>
</Card>

<Dialog
  bind:open={isDialogOpen}
  aria-labelledby="{integration.ref}-dialog"
  aria-describedby="{integration.ref}-dialog-content"
>
  <Title data-cy="integrations-{integration.ref.toLowerCase()}-dialog-title" id="{integration.ref}-dialog">{integration.label}</Title>
  <Content data-cy="integrations-{integration.ref.toLowerCase()}-dialog-content" id="{integration.ref}-dialog-content">Self-service page coming soon. In the meantime, please talk to your Customer Success Manager to get your integration set up!</Content>
  <Actions>
    <Button data-cy="integrations-{integration.ref.toLowerCase()}-dialog-button">
      <Label>OK</Label>
    </Button>
  </Actions>
</Dialog>

<style global lang="scss">
  .integration-tile > .mdc-card__primary-action {
    padding: 6px 24px 12px;
  }

  .integration-tile-header {
    display: flex;
    align-items: center;

    > .badge {
      padding: 0.6rem 1.5rem;
      border-radius: 2rem;
      position: absolute;
      right: 10px;
    }
  }

  .integration-tile-img {
    background-image: var(--imgsrc);
    background-color: var(--backgroundColor, white);
    background-size: contain !important; // TODO: fix specificity and remove !important
    @media (min-width: 1000px) {
      padding: 0 3rem;
    }
    background-origin: content-box;
    border-radius: 4px;
  }

  .mdc-dialog__title {
    font-size: 1.6rem !important; // TODO: fix specificity and remove !important
  }

  .mdc-dialog__content {
    font-size: 1.25rem !important; // TODO: fix specificity and remove !important
  }
</style>
