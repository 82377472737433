<script>
  import {createEventDispatcher} from 'svelte';
  import Dialog, {Actions, Content} from '@smui/dialog';
  import {Label} from '@smui/button';
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import { addTagsToSurvey } from 'src/services/integrationsEngineApi/v3';
  import MultiSelect from 'src/building-blocks/MultiSelect.svelte';


  /***** add-tag-modal Component *****
   * Displays a modal that allows a user to reply to a customer's questionnaire response
   *
   */

  export let merchantTags;
  export {className as class};

  let className = '';

  export let show = false;

  export let survey;

  const dispatch = createEventDispatcher();

  export let selectedTags = [];


  let nonPersistedSelectedTags = [];

  $: if(selectedTags) setSelectTags(selectedTags);
  $: if(!show) setSelectTags(selectedTags);

  function setSelectTags(tagsToPersist){
    nonPersistedSelectedTags.length = 0;
    nonPersistedSelectedTags.push(...tagsToPersist);
    nonPersistedSelectedTags = nonPersistedSelectedTags;
  }

  function saveTags() {
    // It might make sense to move these three lines into the THEN statement below and have params use nonPersistedSelectTags
    selectedTags.length = 0;
    selectedTags.push(...nonPersistedSelectedTags);
    selectedTags = selectedTags;

    let params = {
      tagIds: selectedTags
    }
    addTagsToSurvey(survey.merchants_id, survey.id, params)
      .then(() => {
        dispatch('tagsAdded');
        closeHandler();
      });
  }

  function closeHandler() {
    setSelectTags(selectedTags);
    show = false;
  }

</script>

<Dialog class="add-tag-modal {className}" data-cy="survey-add-tag-modal" bind:open={show}
        fullscreen on:MDCDialog:closed={closeHandler}>

  <Content tabindex="0">
    <div style="text-align: center;">
      <svg class="icon__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true">
        <path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z"></path>
      </svg>
      <div class="action-header">Survey Tags</div>
    </div>
    {#if show}
    <div style="text-align: left;">
      <p class="main-question">Select Tags</p>
      <MultiSelect id='lang' bind:value={nonPersistedSelectedTags}>
        {#each merchantTags as tag }
          <option value="{tag.id}">{tag.label}</option>
        {/each}
      </MultiSelect>
    </div>
    {/if}
  </Content>

  <Actions>
    <Button on:click={closeHandler}><Label>Cancel</Label></Button>
    <Button type="raised" color="primary" on:click={saveTags}><Label>Save</Label></Button>
  </Actions>
</Dialog>



<style lang="scss">
  :global(.add-tag-modal) {
    font-family: "Inter", sans-serif;
  }
  
  :global(.add-tag-modal .mdc-dialog__header) {
    margin-bottom: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecef;
  }

  :global(.add-tag-modal .expiration-input) {
    width: 85%;
    text-align: right;
    height: 40px;
  }

  :global(.add-tag-modal .reply-input.reply-input-number) {
    padding-left: 0;
  }

  :global(.add-tag-modal .reply-label.include-reward) {
    min-width: 120px;
    margin-right: 0;
  }

  :global(.add-tag-modal .reply-label) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    color: black;
    width: 75px;
    margin-right: 30px;
  }

  :global(.add-tag-modal .smui-select--standard .mdc-menu-surface--is-open-below) {
    max-height: 70vh;
  }

  :global(.add-tag-modal .mdc-dialog__content) {
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: visible;
  }

  :global(.add-tag-modal .send-reward-checkbox-wrapper label) {
    line-height: 35px;
    font-size: 14px;
    font-weight: normal;
  }

  :global(.add-tag-modal .send-reward-checkbox-wrapper .mdc-checkbox) {
    margin-bottom: 6px;
  }

  :global(.add-tag-modal .reply-input) {
    flex-grow: 1;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 5px;
    border-width: 1px;
    border-color: #e0e0e0;
    border-style: solid;
  }

  :global(.add-tag-modal .reply-input-select) {
    flex-grow: 1;
  }

  :global(.add-tag-modal .reply-input-select .mdc-select__anchor) {
    border-color: grey;
    border-width: 1px;
    border-style: solid;
    height: 40px;
    width: 100%;
    padding-left: 10px;
    border-radius: 5px;
    border-bottom: none;
  }

  :global(.add-tag-modal .reply-input-select .mdc-select__anchor .mdc-select__selected-text) {
    font-size: 16px;

  }

  :global(.add-tag-modal .reply-row-wrapper) {
    display: flex;
    height: 40px;
    margin-bottom: 25px;
  }

  :global(.add-tag-modal .message-contents) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  :global(.add-tag-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.add-tag-modal .mdc-dialog__title) {
    font-size: 20px;
  }

  :global(.add-tag-modal.mdc-dialog .mdc-dialog__surface) {
    width: 605px;
    max-height: calc(100% - 70px);
    overflow: visible;
  }

  :global(.add-tag-modal  .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.add-tag-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

  :global(.add-tag-modal .checkbox-details) {
    color: black;
    font-size: 10px;
  }

  :global(.add-tag-modal .checkbox-row) {
    margin-bottom: 35px;
  }

  :global(.add-tag-modal .icon__svg) {
    height: 55px;
    width: 55px;
    fill: rgb(241, 142, 71);
  }

  :global(.add-tag-modal .action-header) {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: #353535 !important;
    margin-bottom: 16px;
  }

  :global(.add-tag-modal .main-question) {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    color: #353535 !important;
    letter-spacing: 0;
  }

  :global(.add-tag-modal .mdc-dialog__actions) {
    justify-content: space-between !important;
    border-top: none;
    padding: 8px 15px 28px;
    margin-top: 14px;
  }

  :global(.add-tag-modal .mdc-dialog__actions button) {
    border-radius: 4px !important;
    border: none !important;
    box-shadow: none !important;
  }

  :global(.tag__cancel-button) {
    color: #0071E8 !important;
  }

  :global(.tag__cancel-button:hover) {
    color: #0071E8 !important;
    background-color: #C1DFFF !important;
  }

  :global(.tag__save-button) {
    background-color: #0071E8 !important;
  }

  :global(.tag__save-button:hover) {
    background-color: #1F3A74 !important;
  }

</style>
