import {headers, baseURL} from 'src/services/auth';

/**
 * Retrieves the message logs
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<Response>} An array of message logs
 */
export function getMessageLogs(params) {
  const url = new URL(`${baseURL}/v2/api/message-logs`);
  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.message_logs);
}

/**
 * Adds a new message to the log
 * @param senderId {Number} - The sender's id, generally the merchant's id
 * @param senderTypeId {Number} - The type of sender it is
 * @param receiverId {Number} - The id of the person receiving the message
 * @param receiverTypeId {Number} - The type of receiver it is
 * @param messageTypeId {Number} - The type of message being sent
 * @param surveyId {Number} - The number representing the survey this is in reference to
 * @param message {String} - The entirety of the message to add to the log
 * @return {Promise<any>} - The response from the endpoint
 */
export function addMessageLog(senderId, senderTypeId, receiverId, receiverTypeId, messageTypeId, surveyId, message){
  const url = new URL(`${baseURL}/v2/api/message-logs`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      sender_id: senderId,
      sender_type_id: senderTypeId,
      receiver_id: receiverId,
      receiver_type_id: receiverTypeId,
      message_type_id: messageTypeId,
      survey_id: surveyId,
      message: message
    })
  }).then((result) => {
    return result.json();
  });
}


export function updateMessageLog(messageLogId, updatedBody) {
  const url = new URL(`${baseURL}/v2/api/message-logs/${messageLogId}`);

  return fetch(url.toString(), {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(updatedBody)
  }).then((result) => {
    return result.json();
  });
}
