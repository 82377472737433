const partnerRefsWithIntegrationUis = [ 
    {
        ref: "square",
    },
    {
        ref: "toast",
    },
    {
        ref: "cova",
    },
    {
        ref: "thanx",
    },
    {
        ref: "punchh",
    },
    {
        ref: "lunchbox",
    },
    {
        ref: "chowly",
        label: "Chowly",
        integrationLocationName: "chowlyRestaurantId",
        integrationLocationId: "chowlyRestaurantId",
    },
    {
        ref: "bite",
        label: "Bite kiosk",
        integrationLocationName: "biteLocationId",
        integrationLocationId: "biteLocationId",
        extraFields: {
            orgId: "Organization Id"
        }
    },
    {
        ref: "flipdish",
        label: "Flipdish",
        integrationLocationName: "storeId",
        integrationLocationId: "storeId",
        extraFields: {
            appId: "Application Id"
        }
    },
    {
        ref: "incentivio",
        label: "Incentivio",
        integrationLocationName: "restaurantId",
        integrationLocationId: "restaurantId",
    },
    {
        ref: "olo",
        label: "Olo",
        integrationLocationName: "oloStoreNumber",
        integrationLocationId: "oloStoreNumber",
    },    
    {
        ref: "paytronix",
        label: "Paytronix",
        integrationLocationName: "restaurantId",
        integrationLocationId: "restaurantId",
        extraFields: {
            appId: "Application Id"
        }
    },
    {
        ref: "chatmeter",
        label: "Reputation Management",
        integrationLocationName: "chatmeterLocationId",
        integrationLocationId: "chatmeterLocationId",
        hideFieldsAfterSetup: true,
        extraFields:{
            accountId: "Account Id",
            username: "Admin Username",
            website: "Website",
            phone: "Phone Number"
        },
        externalStep:{
            required: true,
            title: "Add Third-Party Credentials"
        },
        phone:{
            show: true,
            tooltip: "Providing a phone number enhances the accuracy of matching your location to an online review platform like Google or Yelp."
        }
    }];

export function getIntegrationUI(ref) {
    return partnerRefsWithIntegrationUis.find(element => element.ref == ref);
}