import {headers, baseURL} from 'src/services/auth';

/**
 * Adds a new comment to a customer's questionnaire
 * @param userId {String} - The user who is adding the comment
 * @param questionnaireId {String} - The unique id for the customer's questionnaire
 * @param commentText {String} - The contents of the comment
 * @returns {Promise<any>}
 */
export function addComment(userId, questionnaireId, commentText){
  return fetch(`${baseURL}/v2/api/customer-questionnaire-comment`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      users_id: userId,
      customer_questionnaire_id: questionnaireId,
      comment: commentText
    })
  }).then((result) => {
    return result.json();
  });
}

/**
 * Modifies an existing comment
 * @param commentId {String} - The unique id for a given comment
 * @param commentText {String} - The content of the note to update the comment to
 * @returns {Promise<any>} - Returns an object with the newly modified comment
 */
export function editComment(commentId, commentText){
  return fetch(`${baseURL}/v2/api/customer-questionnaire-comment/${commentId}`, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify({
      comment: commentText
    })
  }).then((result) => {
    return result.json();
  });
}


/**
 * Retrieves a list of comments for a given questionnaire id
 * @param customerQuestionnaireId {String} - The unique id for the customer's questionnaire id
 * @returns {Promise<any>} - Returns a list of comments with the user object expanded
 */
export function getCustomerQuestionnaireComments(customerQuestionnaireId) {
  return fetch(`${baseURL}/v2/api/customer-questionnaire-comment?customer_questionnaire_id=${customerQuestionnaireId}&expand=user`, {
    method: 'GET',
    headers: headers,
  }).then((result) => {
    return result.json();
  }).then((result) => {
    return result._embedded.customer_questionnaire_comment;
  });
}
