<div class="multiselect" class:readonly>
  <div class="tokens" class:showOptions on:click={handleTokenClick} on:keydown={handleKeydown}>
    {#each Object.values(selected) as s}
      <div class="token" data-id="{s.value}">
        <span>{s.name}</span>
        {#if !readonly}
          <div class="token-remove" title="Remove {s.name}">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3438 9.40625L7.9375 7L10.3438 4.59375L9.40625 3.65625L7 6.0625L4.59375 3.65625L3.65625 4.59375L6.0625 7L3.65625 9.40625L4.59375 10.3438L7 7.9375L9.40625 10.3438L10.3438 9.40625ZM2.28125 2.3125C3.59375 1 5.16667 0.34375 7 0.34375C8.83333 0.34375 10.3958 1 11.6875 2.3125C13 3.60417 13.6562 5.16667 13.6562 7C13.6562 8.83333 13 10.4062 11.6875 11.7188C10.3958 13.0104 8.83333 13.6562 7 13.6562C5.16667 13.6562 3.59375 13.0104 2.28125 11.7188C0.989583 10.4062 0.34375 8.83333 0.34375 7C0.34375 5.16667 0.989583 3.60417 2.28125 2.3125Z" fill="white"/>
            </svg>
          </div>
        {/if}
      </div>
    {/each}
    <div class="actions">
      {#if !readonly}
        <input id={id} autocomplete="off" bind:value={inputValue} bind:this={input} on:keyup={handleKeyup} on:blur={handleBlur} placeholder={placeholder} class="multiselect-input-field"/>
        <div class="v-field__append-inner">
          <i class="v-icon notranslate v-theme--light v-icon--size-default v-icon--clickable v-autocomplete__menu-icon" role="button" aria-hidden="false" tabindex="-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="{showOptions ? 'v-icon__svg select-arrow-up' : 'v-icon__svg'}">
              <path d="M7,10L12,15L17,10H7Z"></path>
            </svg>
          </i>
        </div>
      {/if}
    </div>
  </div>

  <select bind:this={slot} type="multiple" class="hidden"><slot></slot></select>
  
  {#if showOptions}
    <ul class="options" use:setupOptions style="max-height: {$optionsHeight}; overflow-y: auto;" 
        transition:fly="{{duration: 200, y: 5}}" on:mousedown|preventDefault={handleOptionMousedown}>
      {#each filtered as option}
        <li class:selected={selected[option.value]} class:active={activeOption === option} data-value="{option.value}">
            <input type="checkbox" checked="{selected[option.value]}" class="select__checkbox" />
            {option.name}
        </li>
      {/each}
    </ul>
  {/if}
</div>

<script>
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { fly } from 'svelte/transition';
  export let id = '';
  export let value;
  export let readonly = false;
  export let placeholder = '';

  let input, 
    inputValue, 
    options = [],
    activeOption, 
    showOptions = false,
    selected = {},
    first = true,
    slot

  const optionsHeight = writable('auto');

  let optionsElement;

  onMount(() => {
    init();
    adjustHeight();
  });

  $: if (!first) value = Object.values(selected).map(o => o.value);
  $: filtered = options.filter(o => inputValue ? o.name.toLowerCase().includes(inputValue.toLowerCase()) : o);
  $: if (activeOption && !filtered.includes(activeOption) || !activeOption && inputValue) activeOption = filtered[0];
  $: if (showOptions) {
    setTimeout(adjustHeight, 0);
  }

  export const init = () => {
    slot.querySelectorAll('option').forEach(o => {
      options = [...options, {value: o.value, name: o.textContent}]
    });
    value && (selected = options.reduce((obj, op) => value.includes(op.value) ? {...obj, [op.value]: op} : obj, {}));
    first = false;
  }

  function adjustHeight() {
    if (optionsElement && showOptions) {
      let rect = optionsElement.getBoundingClientRect();
      let elementTop = rect.top;
      let elementHeight = optionsElement.scrollHeight;
      let windowHeight = window.innerHeight;

      if (elementTop + elementHeight > windowHeight) {
        let maxHeight = windowHeight - elementTop - 10; // 10px de margem
        optionsHeight.set(`${maxHeight}px`);
      } else {
        optionsHeight.set('auto');
      }
    }
  }

  function handleResize() {
    adjustHeight();
  }

  function setupOptions(node) {
    optionsElement = node;
    adjustHeight();

    window.addEventListener('resize', handleResize);

    return {
      destroy() {
        window.removeEventListener('resize', handleResize);
      }
    };
  }

  onMount(() => {
    adjustHeight();
  });

  function add(token) {
    if (!readonly) selected[token.value] = token;
  }

  function remove(value) {
    if (!readonly) {
      const {[value]: val, ...rest} = selected;
      selected = rest;
    }
  }

  function optionsVisibility(show) {
    if (readonly) return;
    if (typeof show === 'boolean') {
      showOptions = show;
      show && input.focus();
    } else {
      showOptions = !showOptions;
    }
    if (!showOptions) {
      activeOption = undefined;
    }
  }

  function handleKeyup(e) {
    if (e.keyCode === 13) {
      Object.keys(selected).includes(activeOption.value) ? remove(activeOption.value) : add(activeOption);
      inputValue = '';
    }
    if ([38,40].includes(e.keyCode)) { // up and down arrows
      const increment = e.keyCode === 38 ? -1 : 1;
      const calcIndex = filtered.indexOf(activeOption) + increment;
      activeOption = calcIndex < 0 ? filtered[filtered.length - 1]
        : calcIndex === filtered.length ? filtered[0]
        : filtered[calcIndex];
    }
  }

  function handleBlur(e) {
    optionsVisibility(false);
  }

  function handleTokenClick(e) {
    if (e.target.closest('.token-remove')) {
      e.stopPropagation();
      remove(e.target.closest('.token').dataset.id);
    } else if (e.target.closest('.remove-all')) {
      selected = [];
      inputValue = '';
    } else {
      optionsVisibility(true);
    }
  }

  function handleOptionMousedown(e) {
    const value = e.target.dataset.value;
    if (selected[value]) {
      remove(value);
    } else {
      add(options.filter(o => o.value === value)[0]);
      input.focus();
    }
  }

  function handleKeydown(e) {
    if(e.code === "Escape") {
        showOptions = false;
    }
    if(e.code == 'Space') {
        showOptions = true;
    }
    e.preventDefault();
  }
</script>

<style>
  :global(.multiselect) {
    border-radius: 4px;
		border: 1px solid #BEBEBE;
		background: #FFF;
		min-height: 40px;
		padding: 0 10px;
    position: relative;
  }

  :global(.multiselect .tokens) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  :global(.multiselect .tokens.showOptions::after) { 
    width: 100%; 
    left: 0; 
  }
  :global(.multiselect .tokens .token) {
    align-items: center;
    background-color: #0071E8;
		color: white;
    border-radius: 50px;
    display: flex;
    margin: 4px;
    height: 28px;
    padding: 0px 16px;
    transition: background-color .3s;
    white-space: nowrap;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
    letter-spacing: 0;
  }
  :global(.multiselect .tokens .token .token-remove) {
    align-items: center;
    display: flex;
    margin-left: 8px;
  }
  :global(.multiselect .tokens .token .token-remove:hover) {
    cursor: pointer;
  }

  :global(.multiselect .tokens .actions) {
    align-items: center;
    display: flex;
    flex: 1;
  }

  :global(.multiselect .tokens .actions input) {
    border: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #353535;
    margin: 0;
    outline: none;
    padding: 10px;
    width: 100%;
  }

  :global(.multiselect .options) {
    border-radius: 4px;
    border: 1px solid #BEBEBE;
    background: #FFF;
    left: 0;
    margin-top: 4px;
    list-style: none;
    max-height: 350px;
    overflow: auto;
    padding-inline-start: 0;
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;
    z-index: 9999;
  }
  :global(.multiselect .options li) {
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;
    padding: 0 16px;
		height: 40px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		color: #353535;
    letter-spacing: 0;
  }
  :global(.multiselect .options li.selected) {
    color: #0071E8;
		background: white;
		font-weight: 600;
  }
  :global(.multiselect .options li.selected.active), 
	:global(.multiselect .options li.selected:hover),
	:global(.multiselect .options li:hover) {
    background-color: #E8F3FF;
		color: #0071E8;
		font-weight: 600;
  }
	:global(.multiselect .options li .select__checkbox) {
		width: auto;		
    margin: 0;
		margin-right: 12px;
	}

  :global(.multiselect .hidden) {
    display: none;
  }

  .multiselect-input-field {
    caret-color: transparent;
  }

  .select-arrow-up {
    transform: rotate(180deg);
  }
</style>