<script>
    import Menu from '@smui/menu/styled';
    import List from '@smui/list/styled';
    import '@smui/menu/bare.css';
    import '@smui/list/bare.css';
    import './../../theme/colors.css';

    /***** ContextMenu.svelte Component *****
     * For the Svelte ContextMenu, we use SMUI's Menu as a base

     ******Parameters ******************************************************************************
     * @param autoId (string) - Menu autoId for Cypress tests
     * @param menu (Menu Component) - This points to the menu component instance.  This should not be set outside this component.
     * @param overrideCoordinates (Array of Numbers) - An array of x,y coordinates, if provided, overrides the location of the context menu
     * @param class (String) - Any classnames needed
     * @param on:mouseenter (function) - fires when the mouse enters the element
     * @param on:mouseleave (function) - fires when the mouse leaves the element
     * @slot slot (List) -  The contents of the context menu, should be a List
     */
    export let autoId;
    export let menu;
    export let overrideCoordinates;
    export let anchor;
    export let anchorCorner;
    export {className as class};

    let className;

    let style = '';

    $: {
        if (overrideCoordinates && overrideCoordinates.length === 2) {
            style = 'position: absolute; left: ' + overrideCoordinates[0] + 'px; top: ' + overrideCoordinates[1] + 'px;';
        }
    }
</script>

<div
    on:mouseenter
    on:mouseleave
    style={style}
    class={className}
>

    <Menu
        {anchor}
        {anchorCorner}
        data-cy={autoId}
        bind:this={menu}
    >
        <slot/>
    </Menu>
</div>

<style lang="scss">

</style>