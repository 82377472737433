<script>
  import OperationalCategoryReason
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/operational-category/operational-category-reason.svelte';
  import OperationalCategoryRating
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/operational-category/operational-category-rating.svelte';
  import OperationalCategoryClipboardContent from "src/pages/sidebars/enhanced-profile-sidebar/sections/operational-category/operational-category-clipboard-content.svelte";

  /***** operational-category-snapshot Component *****
   * Displays an individual operational category snapshot along with the factors, rating, and comments
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param questionnaireSnapshot {Object} - The snapshot to show
   * @param snapshotReasons {Array} - An array of snapshot reasons for the questionnaire
   */

  export let autoId = "operational-category-snapshot";
  export { className as class };
  export let questionnaireSnapshot;
  export let snapshotReasons;
  
  let className = '';

  async function copyToClip() {
    let element = document.createElement("div");
    let component = new OperationalCategoryClipboardContent({
      target: element,
      props: {
        questionnaireSnapshot: questionnaireSnapshot,
        snapshotReasons: snapshotReasons,
      },
    });
    try {
      const textBlob = new Blob([element.innerHTML], {
        type: "text/html",
      });
      await navigator.clipboard.write([
        new ClipboardItem({
          [textBlob.type]: textBlob,
        }),
      ]);
    } catch (err) {
      console.error(err.name, err.message);
    }
  }
</script>

<div class="operational-category-snapshot {className}" data-cy={autoId}>
  <div class="snapshot-label">
    {questionnaireSnapshot.snapshot.label}
  </div>

  <div class="rating-wrapper">
    <OperationalCategoryRating
      rating={questionnaireSnapshot.rating}
      class="operational-category-rating"
    />
    <div class="snapshot-wrapper">
      {#each snapshotReasons as snapshotReason}
        <OperationalCategoryReason
          class="operational-category-reason"
          label={snapshotReason.reason.label}
          impact={snapshotReason.impact}
        />
      {/each}
    </div>
  </div>

  <div class="copy-btn-wrapper" on:click={copyToClip}>
    <span
      class="material-icons content_copy bg-color-unflagged copy-icon"
      tabindex="0"
      >content_copy</span
    >
  </div>

  <div class="snapshot-comment">
    <div class="snapshot-comment-title">Comment Added</div>
    <div
      class="snapshot-user-comment"
      class:no-comment={!questionnaireSnapshot.comment}
    >
      {#if !questionnaireSnapshot.comment}
        No Comment Provided
      {/if}
      {questionnaireSnapshot.comment}
    </div>
  </div>
</div>

<style lang="scss">
  .snapshot-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-top: -10px;
  }

  .snapshot-comment-title {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: var(--mdc-typography-caption-font-size);
  }

  .rating-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .snapshot-user-comment {
    font-size: var(--mdc-typography-button-font-size);
  }

  * :global(.operational-category-rating) {
    margin-right: 30px;
    margin-top: 3px;
  }

  .operational-category-snapshot {
    position: relative;
    border-style: solid;
    border-width: 1px;
    border-radius: 9px;
    border-color: #bec3cb;
    padding: 20px;
  }

  .copy-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .snapshot-user-comment.no-comment {
    color: #bbbbbb;
  }

  .snapshot-label {
    font-size: var(--tattle-subtitle-font-size);
    margin-bottom: 10px;
    font-weight: 500;
  }

  * :global(.operational-category-reason) {
    margin-left: 10px;
    margin-right: 10px;
    flex-grow: 0;
    justify-content: space-between;
    margin-top: 10px;
  }
</style>
