import {accessToken, merchantId, userId, user} from './services/auth.js';
import 'svelte-material-ui/bare.css';
import EnhancedProfileSidebar from 'src/pages/sidebars/enhanced-profile-sidebar/enhanced-profile-sidebar.svelte';
import RatingTrendsTab from 'src/pages/landing/ratingTrends/ratingTrendsTab.svelte';
import IntegrationsList from 'src/pages/integrations/integrationsList.svelte';
import IntegrationDetail from 'src/pages/integrations/integrationDetail.svelte';
import SquareOAuthRedirect from 'src/pages/integrations/square/squareOauthRedirect.svelte';
import SMSConfig from 'src/pages/sms/smsConfig.svelte';
import PublicReviewsConfig from 'src/pages/public-reviews/publicReviewsConfig.svelte';
import SurveyConfiguration from 'src/pages/survey-configuration/surveyConfiguration.svelte'
import {sidebarOpenStore} from 'src/stores/sidebarOpenStore';
import {globalFilterBarStore} from 'src/stores/globalFilterBarStore';
import 'tttl-core-ui/src/theme/colors.css';


export default {
  Pages: {
    IntegrationsList,
    IntegrationDetail,
    SquareOAuthRedirect,
    Landing: {
      RatingTrendsTab
    },
    SMSConfig,
    PublicReviewsConfig,
    SurveyConfiguration
  },
  Sidebars: {
    EnhancedProfileSidebar
  },
  Stores: {
    accessToken,
    merchantId,
    userId,
    user,
    sidebarOpenStore,
    globalFilterBarStore
  }
};
