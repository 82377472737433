<script>
  import { onMount } from "svelte";
  import {fade} from "svelte/transition";

  export let partnerRef;
  export let merchantId;

  let integrationComponent;

  const getComponentFromPartnerRef = async (ref) => {
    switch (ref) {
      case "square":
        integrationComponent = (await import("./square/squareIntegration.svelte")).default;
        break;
      case "toast":
        integrationComponent = (await import("./toast/toastIntegrations.svelte")).default;
        break;
      case "cova":
        integrationComponent = (await import("./cova/covaIntegrations.svelte")).default;
        break;
      case "thanx":
        integrationComponent = (await import("./thanx/thanxIntegration.svelte")).default;
        break;
      case "punchh":
        integrationComponent = (await import("./punchh/punchhIntegration.svelte")).default;
        break;
      case "lunchbox":
        integrationComponent = (await import("./lunchbox/lunchboxIntegration.svelte")).default;
        break;
      case "chowly":
        integrationComponent = (await import("./chowly/chowlyIntegration.svelte")).default;
      default:
        integrationComponent = (await import("./genericIntegration.svelte")).default;
        break;
    }
  };

  onMount(async () => getComponentFromPartnerRef(partnerRef));

</script>

<div style="margin-bottom: 20px;"><a data-cy="integrations-back-to-integrations-link" href="#/admin/global/integrations"><i class="fa fa-arrow-left"></i> Back to Integrations</a></div>

{#if integrationComponent}
  <div in:fade>
    <svelte:component this={integrationComponent} {merchantId} {partnerRef} />
  </div>
{/if}
