<script>
  import {DateTime} from "luxon";
  import {editComment} from 'src/services/api/customer-questionnaire-comments';
  import {parseServerDate} from "src/services/utility/dateParser";


  /***** comment Component *****
   * Displays a single comment that can be edited
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param comment {Object} - A single object from the customer questionnaire comment endpoint with the user expanded
   */

  export let autoId = 'comment';
  export {className as class};

  let isEditing = false;
  let className = '';

  export let comment;

  function save() {
    if (comment.comment === '') {
      return;
    }

    editComment(comment.id, comment.comment).then(() =>{
      isEditing = false;
    });
  }

  $: formattedDate = parseServerDate(comment.date_time_created).toLocaleString(DateTime.DATE_FULL);

</script>

<div class="comment {className}" data-cy={autoId}>
  <div class="comment-header">
    <div class="comment-author">
      <div>
        {comment.user.first} {comment.user.last}
      </div>

      <div>
        {#if !isEditing}
        <span class="material-icons" on:click={()=> isEditing = true}>
          edit
        </span>
        {/if}
        {#if isEditing}
          <span class="material-icons" on:click={() => save()}>save</span>
        {/if}
      </div>
    </div>
    <div class="comment-date">
      {formattedDate}
    </div>
  </div>

  <div class="comment-content">
    {#if !isEditing}
      {comment.comment}
    {/if}
    {#if isEditing}
      <textarea data-cy="${autoId}-comment-input" bind:value={comment.comment}></textarea>
    {/if}
  </div>
</div>


<style lang="scss">
  .comment {
    margin-top: 15px;

    .comment-header {
      background-color: #f5f5f5;
      padding: 10px;

      .comment-author {
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
      }

      .comment-date {
        font-size: 10px;
      }
    }

    .comment-content {
      margin-left: 15px;
      margin-top: 10px;
      font-size: 12px;
    }
  }
  .material-icons {
    cursor: pointer;
    background-color: #ccc;
    padding: 5px;
    border-radius: 50%;
  }

  textarea {
    width: 100%;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border-color: rgba(127, 135, 152, 0.5);
    resize: none;
    padding: 10px;
  }


</style>

