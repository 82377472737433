<script>
  import Dialog, {Actions, Title, Content, Header} from '@smui/dialog';
  import IconButton from '@smui/icon-button';
  import {Label} from '@smui/button';
  import Button from "tttl-core-ui/src/components/Button.svelte";

  export let autoId = 'confirmation-modal';
  export {className as class};
  export let show = false;

  let className = '';

  export let message;
  export let resultHandler;
  export let result = false;

  function closeHandler() {
    resultHandler(result);
  }

</script>

<Dialog class="confirmation-modal {className}" data-cy="{autoId}-modal" bind:open={show}
        fullscreen
        on:MDCDialog:closed={closeHandler}>
  <Header>
    <Title data-cy="{autoId}-title" id="fullscreen-title">Confirmation</Title>
    <IconButton data-cy="{autoId}-close-button" action="close" class="material-icons">close</IconButton>
  </Header>

  <Content style="text-align: left;">
    <div data-cy="{autoId}-question" class="pre-editor-message">
      {@html message}
    </div>

  </Content>

  <Actions>
    <Button autoId="{autoId}-confirm-button" type="raised" color="primary" on:click={() => result = true}><Label>Confirm</Label></Button>
    <Button autoId="{autoId}-cancel-button" type="outlined" color="secondary" on:click={() => result = false}><Label>Cancel</Label></Button>
  </Actions>

</Dialog>


<style lang="scss">
  :global(.confirmation-modal .mdc-dialog__title) {
    font-size: 20px;
  }

  :global(.confirmation-modal .pre-editor-message) {
    font-size: 10px;
    white-space: break-spaces;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  :global(.confirmation-modal .post-editor-message) {
    font-size: 10px;
  }

  :global(.confirmation-modal .notice) {
    font-weight: 500;
    color: black;
  }

  :global(.confirmation-modal .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.confirmation-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

  :global(.confirmation-modal.mdc-dialog .mdc-dialog__surface) {
    max-height: calc(100% - 70px);
  }

  :global(.confirmation-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.confirmation-modal .message-contents) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>

