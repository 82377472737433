<script>
  import SurveyAnswerDetail from 'src/pages/sidebars/enhanced-profile-sidebar/sections/survey-answer-section/survey-answer-detail.svelte';
  import { partition } from "lodash"

  /***** enhanced-profile-survey-section Component *****
   * Displays all the details for a given list of survey answers
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   */

  export let autoId = 'enhanced-profile-survey-section';
  export {className as class};
  export let answers;

  let className = '';

  //let [checkboxAnswers, nonCheckboxAnswers] = partition(answers, { field_type: 'checkbox' });
  //  if(!checkboxAnswers.lenth){
  //    answers = [...nonCheckboxAnswers, [...checkboxAnswers]];
  //  }

</script>

<div class="enhanced-profile-survey-section {className}" data-cy={autoId}>
  {#each answers as answer}
    <SurveyAnswerDetail {answer}/>
  {/each}
</div>


<style lang="scss">
  .enhanced-profile-survey-section {
    margin: 40px 20px 20px;
  }

</style>

