import {headers} from 'src/services/auth';

export function getMenuItems(merchant_id, order_id) {
  // const baseURL = 'https://localhost:5000/'
  const baseURL = window.INTEGRATIONS_ENGINE_URL;

  const url = new URL(`${baseURL}surveys/api/order-lookup/${merchant_id}/order/${order_id}`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

