<script>
  import { Cell, Row, Label } from "@smui/data-table/styled";
  import Switch from "@smui/switch";
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import { editToastLocation } from "src/services/integrationsEngineApi/integrations";
  import { createEventDispatcher } from 'svelte';
  import Snackbar from "@smui/snackbar";
  import ConfirmationModal from 'src/building-blocks/confirmation-modal.svelte';

  const dispatch = createEventDispatcher();

  export let row;
  export let locations;
  export let takenLocations;
  export let merchantId;

  export let restaurantPlusLocationName;
  export let tattleLocationLabel;
  export let lastSyncEndDateTime;
  export let editingRowId;

  export let isEditMode;
  let newSelectedLoc;
  let originalStatus = row?.isActive;

  $: filteredLocations = locations.filter(location => !takenLocations.includes(location.id));

  function editLocation(isActionConfirmed) {
    if(!isActionConfirmed)
      return;

    if(!newSelectedLoc && row.isActive){
        showCannotEnableError();
        return false;
      }

      return editToastLocation(
        row.isActive,
        row.toastLocation?.restaurantGuid,
        newSelectedLoc ?? row.tattleLocation?.locationId,
        merchantId
      ).then((results) => {
        if (results?.status > 300) {
          dispatch('locationsUpdated', {isSuccess: false});
        }
        else {
          dispatch('locationsUpdated', {isSuccess: true});
        }
        
        isEditMode = false;
        dispatch('editRow', {restaurantGuid: null, rowActive: originalStatus});
        
      });
  }

  let snackBar;
  let snackBarClass;
  let snackBarMessage;
  function showCannotEnableError() {
    snackBarClass = "toastErrorSurface";
    snackBarMessage = "A Toast location integration cannot be enabled if it is not mapped to a Tattle location";
    snackBar.open();
  }

  function handleSave() {
    row.isActive ?  editLocation(true) : showDeactivateConfirmationModal();
  }

  let confirmationModalText;
  let confirmationModalIsVisible;
  function showDeactivateConfirmationModal(){
    confirmationModalText = "Are you sure you want to deactivate this location?";
    confirmationModalIsVisible = true;
  }

  let confirmationMultipleEditionModalText;
  let confirmationMultipleEditionIsVisible;

  export function toggleEditMode(){
    if(!isEditMode){
      originalStatus = row.isActive;
      if(editingRowId) {
        confirmationMultipleEditionModalText = "You are editing another location. Your changes will be lost. Are you sure to continue?";
        confirmationMultipleEditionIsVisible = true;
        return;
      } else {
        dispatch('editRow', {restaurantGuid: row.restaurantGuid, rowActive: originalStatus});
      }

      // turning on EditMode
      if(!row.isActive)
        row.isActive = true; // Default to activating location
    }
    else{
      // Cancelling (turning off edit mode) so reset status
      row.isActive = originalStatus;
      dispatch('editRow', {restaurantGuid: null, rowActive: originalStatus});
    }
    isEditMode = !isEditMode
  }

  function handleMultiEdit(response) {
    if(response) {
      dispatch('editRow', {restaurantGuid: row.restaurantGuid, rowActive: originalStatus})
      if(!row.isActive)
        row.isActive = true;
    }
  }

</script>

<Row>
  <Cell style="font-weight: 500;" class="align-left">
    <Switch input$data-cy="integrations-location-row-{row.toastLocation?.restaurantGuid}-status-switch" bind:checked={row.isActive} disabled={!isEditMode}
      />
  </Cell>
  <Cell data-cy="integrations-location-row-{row.toastLocation?.restaurantGuid}-toast-label" class="align-left locationRowCell"
    >{restaurantPlusLocationName}
  </Cell>
  <Cell data-cy="integrations-location-row-{row.toastLocation?.restaurantGuid}-tattle-label" class="align-left locationRowCell">
    {#if isEditMode}
      <input
        data-cy="integrations-location-row-{row.toastLocation?.restaurantGuid}-tattle-input"
        class="form-control"
        list="datalistOptions"
        placeholder="locations..."
        bind:value={newSelectedLoc}
      />
      <datalist id="datalistOptions">
        {#each filteredLocations as location}
          <option data-value="{location.id}" value={location.id}>{location.label}</option>
        {/each}
      </datalist>
    {:else}
      {tattleLocationLabel ?? ""}
    {/if}
  </Cell>
  <Cell data-cy="integrations-location-row-{row.toastLocation?.restaurantGuid}-last-synced" class="align-left locationRowCell"
    >{lastSyncEndDateTime ?? ""}</Cell
  >
  <Cell class="locationRowCell">
    {#if isEditMode}
      <div class="buttonContainer">
        <Button
          autoId="integrations-location-row-{row.toastLocation?.restaurantGuid}-save-button"
          type="raised"
          color="primary"
          on:click={() => {handleSave()}}
        >
          <Label>
            <div class="label-contents">Save</div>
          </Label>
        </Button>
        <Button
          autoId="integrations-location-row-{row.toastLocation?.restaurantGuid}-cancel-button"
          type="raised"
          color="secondary"
          on:click={() => toggleEditMode()}
        >
          <Label>
            <div class="label-contents">
              <div>Cancel</div>
            </div>
          </Label>
        </Button>
      </div>
    {:else}
      <Button
        autoId="integrations-location-row-{row.toastLocation?.restaurantGuid}-edit-button"
        type="raised"
        color="primary"
        on:click={() => toggleEditMode()}
      >
        <Label>
          <div class="label-contents">
            <div>Edit</div>
          </div>
        </Label>
      </Button>
    {/if}
  </Cell>
</Row>
<Snackbar bind:this={snackBar} surface$data-cy="integrations-snackbar" surface$class={snackBarClass}>
  <Label>{snackBarMessage}</Label>
</Snackbar>
<ConfirmationModal autoId="integrations-confirmation-{row.toastLocation?.restaurantGuid}" bind:show={confirmationModalIsVisible} message={confirmationModalText} resultHandler={editLocation}/>

<ConfirmationModal autoId="integrations-multiple-edition-{row.toastLocation?.restaurantGuid}" bind:show={confirmationMultipleEditionIsVisible} message={confirmationMultipleEditionModalText} resultHandler={handleMultiEdit}/>

<style lang="scss">
  :global(.locationRowCell) {
    word-wrap: break-word;
    max-width: 180px;
    min-width: 150px;
  }

  * :global(.locs-dropdown .autocomplete) {
    cursor: pointer;
    font-size: 14px;
  }

  * :global(.buttonContainer) {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
  }
  .buttonContainer {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;

    @media (max-width: 1320px) {
      float: right;
    }
  }
  * :global(.align-left) {
    text-align: left !important;
    padding-left: 8px !important;
    max-width: 180px;
  }
  :global(.toastSuccessSurface) {
    background-color: var(--tattle-success-color);
  }
  :global(.mdc-snackbar__label) {
    font-size: 12pt;
  }
  :global(.toastErrorSurface) {
    background-color: var(--tattle-fail-color);
  }
</style>
