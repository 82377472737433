import {headers, baseURL} from 'src/services/auth';

/**
 * Returns the snapshot reasons for a given customer
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<any>} A promise returning the snapshot reasons for the customer
 */
export function getCustomerSnapshotReasons(params) {
  const url = new URL(`${baseURL}/v2/api/customer-snapshot-reasons`);
  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.customer_snapshot_reasons);
}
