<script>
    import Chip from '@smui/chips';

    /***** Chip.svelte Component *****
     * For the Chip, we import Material's chip as a base
     *
     * Style Guide for Material with Svelte: https://sveltematerialui.com/demo/chips/
     *
     ******Parameters ******************************************************************************
     * @param chip (any) - The chip provided from a set
     * @param disabled (boolean) -  if true, disables the chip
     * @param selected (boolean) - if true, the chip will show as selected
     * @param class {string} - Any classes to add to the component
     *
     * @slot - The contents of the chip
     */

    // The chip provided from a SET
    export let chip;
    // Boolean denoting if the chip is disabled
    export let disabled;
    // Boolean denoting if the chip is selected
    export let selected = false;
    export {className as class};

    let className;
</script>

<div class={className}>
    <Chip on:click {disabled} {chip} class={selected ? 'selected' : ''}>
        <slot/>
    </Chip>
</div>

<style lang="scss">
  * :global(.mdc-chip) {
    user-select: none;
  }
  * :global(.mdc-chip.selected) {
    color: var(--mdc-theme-primary) !important;
  }

  * :global(.mdc-chip.selected .mdc-chip__ripple::before) {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary));
    opacity: var(--mdc-ripple-selected-opacity);
  }

  * :global(.mdc-chip.selected .mdc-chip__ripple::after) {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary));
  }

</style>