import { writable } from 'svelte/store';

var currentAPI = window.location.origin;

if(window.USE_DECOUPLED_UI) {
    currentAPI = window.TATTLE_API_URL_EMPTY;
}
export const baseURL = currentAPI;
/**
 * A store keeping the access token needed to make network calls after being authenticated
 * @type {Writable<String>}
 */
export const accessToken = writable(undefined);

/**
 * A store keeping the current merchant's id
 * @type {Writable<String>}
 */
export const merchantId = writable(undefined);


export const userId = writable(undefined);

export const user = writable(undefined);


accessToken.subscribe((_identity) => {
    if(_identity !== undefined) {
        setAuthHeaders(_identity);
    }
});

/**
 * An object containing the needed headers to make network calls
 * @type {Object}
 */
export let headers = {};

/**
 * Sets the headers object with the authorization and content type headers
 *
 * @param {String} accessToken the generated access token from an authentication call
 */
export function setAuthHeaders(accessToken) {
    headers = {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json'
    };
}
