<script>
  /***** operational-category-rating Component *****
   * Displays a star and percentage rating along with coloring
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param rating {Number} - A rating number between 1 and 5 inclusive
   */

  export let autoId = 'operational-category-rating';
  export {className as class};
  export let rating;


  let className = '';

  let stars = [1,2,3,4,5];

  //1 star is considered 0, 3 - 50%, 5 - 100%
  $: percentage = (rating - 1) * 25;

</script>

<div class="{className} operational-category-rating-{rating}" data-cy={autoId} style="display: flex; flex-direction: row;">
  <div class="operational-category-stars" style="display: flex; flex-direction: row;">

    {#each stars as star}
      <span class="material-icons-outlined stars" class:filled={star <= rating}>star</span>
    {/each}

    <div class="operational-category-percentage">
      {#if percentage > -1}
        {percentage}%
      {/if}
    </div>
  </div>

</div>


<style lang="scss">
  .operational-category-stars .stars {
    color: #ced1d9;
  }

  .operational-category-percentage {
    line-height: 25px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  //TODO: Move this out to a service
  .operational-category-rating-1 {
    .operational-category-stars .stars.filled,  .operational-category-percentage {
      color: #b70034;
    }
  }

  .operational-category-rating-2 {
    .operational-category-stars .stars.filled,  .operational-category-percentage {
      color: #f0495d;
    }
  }

  .operational-category-rating-3 {
    .operational-category-stars .stars.filled,  .operational-category-percentage{
      color: #f88245;
    }
  }

  .operational-category-rating-4 {
    .operational-category-stars .stars.filled,  .operational-category-percentage {
      color: #ffbc2b;
    }
  }

  .operational-category-rating-5 {
    .operational-category-stars .stars.filled,  .operational-category-percentage {
      color: #007244;
    }
  }

</style>

