<script>
  import { Cell, Row } from "@smui/data-table/styled";
  import Switch from "@smui/switch";
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import toastLocations from "./toastLocations.svelte";
  import ConfirmationModal from 'src/building-blocks/confirmation-modal.svelte';
  import { addOrUpdateToastIntegration } from "src/services/integrationsEngineApi/integrations";
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let row;
  export let merchantId;

  let showLocations = false;

  function handleIntegrationStatusChange(e, isActive) {
    isActive ?  showDeactivateConfirmationModal() :
    showActivateConfirmationModal();
  }

  function handleStatusChange(isActionConfirmed){
    if(isActionConfirmed){
      // Update integration
      return addOrUpdateToastIntegration(merchantId, row.managementGroupGuid, row.isActive, false).then((results) => {
        if (results?.status > 300) {
          dispatch('integrationsUpdated', {isSuccess: false});
        } else {
          dispatch('integrationsUpdated', {isSuccess: true});
        }
      });
    } else{
      // revert the decision
      row.isActive = !row.isActive;
    }

  }

  let confirmationModalText;
  let confirmationModalIsVisible;
  function showDeactivateConfirmationModal(){
    confirmationModalText = "All locations under the Management Group ID will be deactivated. Are you sure you want to deactivate this Management Group?";
    confirmationModalIsVisible = true;
  }
  function showActivateConfirmationModal(){
    confirmationModalText = "Are you sure you want to activate this Toast Integration?";
    confirmationModalIsVisible = true;
  }

</script>

<Row class="integrationRow">
  <Cell style="font-weight: 500;" class="align-left">
    <Button autoId="integrations-group-row-{row.managementGroupGuid}-arrow-button" on:click={() => showLocations = !showLocations} disabled={!row.isActive}>
      {#if showLocations}
        <i data-cy="integrations-group-row-{row.managementGroupGuid}-arrow-icon" class="material-icons">arrow_drop_down</i>
      {:else}
        <i data-cy="integrations-group-row-{row.managementGroupGuid}-arrow-icon" class="material-icons">arrow_right</i>
      {/if}
      
    </Button>
  </Cell>
  <Cell style="font-weight: 500;" class="align-left">
    <Switch input$data-cy="integrations-group-row-{row.managementGroupGuid}-status-switch" bind:checked={row.isActive} on:click={(e) => handleIntegrationStatusChange(e, row.isActive)} />
  </Cell>
  <Cell data-cy="integrations-group-row-{row.managementGroupGuid}-management-group-id" class="align-left">
    {row.managementGroupGuid}
  </Cell>
  <Cell data-cy="integrations-group-row-{row.managementGroupGuid}-last-updated" class="align-left">{row.lastUpdated}</Cell>
</Row>
{#if showLocations}
<Row>
  <Cell colspan="6">
    <svelte:component
      this={toastLocations}
      {merchantId}
      managementGroupGuid={row.managementGroupGuid}
    />
  </Cell>
</Row>
{/if}
<ConfirmationModal autoId="integrations-confirmation-{row.managementGroupGuid}" bind:show={confirmationModalIsVisible} message={confirmationModalText} resultHandler={handleStatusChange}/>

<style lang="scss">
  * :global(.buttonContainer) {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
  }
  .buttonContainer {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
  }
  * :global(.align-left) {
    text-align: left !important;
  }
</style>
