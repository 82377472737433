'use strict';

import {parseServerDate, getDateEnding} from 'src/services/utility/dateParser';


export function replaceTags(text, survey, customer, reward, links) {
  text = rpl_survey_title(text, survey);
  text = rpl_date_complete(text, survey);
  text = rpl_business_name(text, survey);
  text = rpl_brand_name(text, survey);
  text = rpl_location_name(text, survey);
  text = rpl_customer_name(text, customer);
  text = rpl_manager_name(text, survey);
  text = rpl_manager_email(text, survey);
  text = rpl_merchant_links(text, links);
  if (survey.reward != null){
    text = rpl_date_redeemed(text, survey.reward);
  }
  if (reward) {
    text = rpl_reward_title(text, reward);
  }
  return text;
}

function rpl(text, query, replacement) {
    return text.replace(new RegExp('(?:' + escapeRegExp(query) + ')', 'g'), replacement);
}

function rpl_survey_title(text, survey) {
  return rpl(text, '[[survey_title]]', survey.title);
}

function rpl_business_name(text, survey) {
  return rpl(text, '[[business_name]]', survey.merchant.business_name);
}

function rpl_brand_name(text, survey) {
  var brand_name;
  brand_name = survey.merchant.business_name;
  if (survey.brand != null) {
    if (survey.brand.prefix != null) {
      brand_name = survey.brand.prefix;
    }
  }
  return rpl(text, "[[brand_name]]", brand_name);
}

function rpl_location_name(text, survey) {
  return rpl(text, '[[location_name]]', survey.location.label);
}

function rpl_date_complete(text, survey) {
  return rpl(text, '[[date_complete]]', formatDate(survey.date_time_completed));
}

function rpl_date_redeemed(text, reward) {
  return rpl(text, '[[date_redeemed]]', formatDate(reward.date_time_redeemed));
}

function rpl_reward_title(text, reward) {
  return rpl(text, '[[reward_title]]', reward.label);
}

function formatDate(dateString){
  const dateObject = parseServerDate(dateString);
  return dateObject.toFormat('cccc, LLLL d') + getDateEnding(dateObject.day);

}

function rpl_customer_name(text, customer) {
  var name;
  name = customer.first_name;
  if (name == null) {
    name = customer.email;
  }
  return rpl(text, '[[customer_name]]', name);
}

function rpl_manager_name(text, survey) {
  var replacement = survey.location.label + ' Manager';
  if ((typeof survey.manager !== 'undefined' && survey.manager !== null) && survey.manager.id !== null) {
    replacement = survey.manager.first + ' ' + survey.manager.last;
  }
  return rpl(text, '[[manager_name]]', replacement);
}

function rpl_manager_email(text, survey) {
    var replacement = '';
    if(survey.manager.id !== null) {
        replacement = survey.manager.email;
    }
    return rpl(text, "[[manager_email]]", replacement);
}

function rpl_merchant_links(text, links) {
  links.forEach((link) => {
    var html, url;
    if (link.hasOwnProperty("override") && link.override.url !== null) {
      url = link.override.url;
    } else {
      url = link.url;
    }
    html = '<a href="' + url + '" target="_blank">' + link.label + '</a>';
    //TODO: Double check this in the unit tests
    return text = rpl(text, '[[link:' + link.slug + ']]', html);
  });

  return text;
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

function setCharAt(str,replace) {
  if (1 > str.length-1) return str;
  return str.substring(0,0) + replace + str.substring(0+1);
}

function rep(str) {
  str.replace('$','$$$$');
  return str
}
