<script>
  import {afterUpdate} from 'svelte';
  import { orderBy } from "lodash"
  import Button, {Label} from '@smui/button';
  import MenuLevelFeedbackModal from 'src/pages/sidebars/enhanced-profile-sidebar/modals/menu-level-feedback-modal.svelte';

  /***** menu-item-level-feedback Component *****
   * Displays menu items and their ratings
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param menuItemLevelFeedback {Object} -  The menu item level feedback from this response's Item Level Feedback survey
   */

  export {className as class};

  export let menuItemLevelFeedback;

  let className = '';
  let menuItemLevelFeedbackElement;
  let showMoreOption = false;
  let showMenuLevelFeedbackModal = false;
  let maxHeight = 270;


  afterUpdate(() => {
    getHeight(menuItemLevelFeedbackElement);
  });

  function getHeight(element) {
    if (element != null) {
      showMoreOption = element.getBoundingClientRect().height >= maxHeight;
    }
  }

</script>


<div data-cy="menu-items-section" class="mlf-wrapper {className}">

  <div data-cy="menu-items-title" class="mlf-header-title">
    Menu Items
  </div>

  <div data-cy="menu-items-list" class="mlf-section" style="max-height: {maxHeight}px;" bind:this={menuItemLevelFeedbackElement}>
    {#each orderBy(menuItemLevelFeedback.data, (item) => (item.itemRating), 'desc') as item}
      <div data-cy="menu-items-item-{item.itemId}-row" class="mlf-container">

        <div class="mlf-item">
          <div data-cy="menu-items-item-{item.itemId}-label" class="mlf-item-label">{item.itemLabel}</div>
          {#if item.itemNote}
            <div data-cy="menu-items-item-{item.itemId}-note" class="mlf-item-note">{item.itemNote}</div>
          {/if}
        </div>

        <div data-cy="menu-items-item-{item.itemId}-rating" class="mlf-item mlf-star-rating checked-{item.itemRating}">
          <span data-cy="menu-items-item-{item.itemId}-star-1" class="fa fa-star {item.itemRating < 1 ? 'unchecked': ''}"></span>
          <span data-cy="menu-items-item-{item.itemId}-star-2" class="fa fa-star {item.itemRating < 2 ? 'unchecked': ''}"></span>
          <span data-cy="menu-items-item-{item.itemId}-star-3" class="fa fa-star {item.itemRating < 3 ? 'unchecked': ''}"></span>
          <span data-cy="menu-items-item-{item.itemId}-star-4" class="fa fa-star {item.itemRating < 4 ? 'unchecked': ''}"></span>
          <span data-cy="menu-items-item-{item.itemId}-star-5" class="fa fa-star {item.itemRating < 5 ? 'unchecked': ''}"></span>
        </div>

      </div>
    {/each}
  </div>

  {#if showMoreOption}
    <div class="show-button">
      <Button autoId="menu-items-more-button" on:click={() => { showMenuLevelFeedbackModal = true;}}>
        <Label>More...</Label>
      </Button>
    </div>
  {/if}

</div>

<MenuLevelFeedbackModal bind:show={showMenuLevelFeedbackModal}
                        menuItemLevelFeedback={menuItemLevelFeedback}/>


<style lang="scss">

  .mlf-header-title {
    font-weight: 500;
    user-select: none;
    height: 52px;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 52px;
    margin-bottom: 20px;
  }

  .mlf-section {
    font-size: var(--mdc-typography-caption-font-size);
    user-select: none;
    margin-left: 30px;
    margin-right: 15px;
    text-align: left;
    overflow-y: hidden;
    line-height: 14px;
    min-width: 200px;
  }

  .mlf-container {
    display: flex;
    padding-bottom: 15px;
  }

  .mlf-item {
    flex: 1;
    margin: auto;
    text-align: left;
  }

  .mlf-star-rating {
    text-align: center;
  }

  .mlf-item-label {
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding-bottom: 5px;
  }

  .mlf-item-note {
    color: #828899;
  }

  .unchecked {
    color: #70757a;
  }
  .checked-1 {
    color: #A50126;
  }
  .checked-2 {
    color: #F56D43;
  }
  .checked-3 {
    color: #F7CB48;
  }
  .checked-4 {
    color: #A7D96A;
  }
  .checked-5 {
    color: #006837;
  }

  .mlf-wrapper {
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    max-width: 300px;
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .show-button {
    display: flex;
    justify-content: center;
    border-top: 1px solid #ccc;
  }

</style>
