import {headers} from 'src/services/auth';

const baseURL = () => window.INTEGRATIONS_ENGINE_URL + 'communications/api/';

export function getSMSConfig(merchantId) {
  const url = new URL(`${baseURL()}MerchantSMS/${merchantId}/merchant-sms`);

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}

export function activate(merchantId, messageTemplate) {
  const url = new URL(`${baseURL()}MerchantSMS/${merchantId}/activate-merchant-sms`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      "messageTemplate": messageTemplate
    })
  }).then(result => result);
}

export function deactivate(merchantId) {
  const url = new URL(`${baseURL()}MerchantSMS/${merchantId}/deactivate-merchant-sms`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers
  }).then(result => result);
}