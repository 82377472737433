<script>
  import UserProfilePicture from 'src/building-blocks/user-profile-picture.svelte';
  import Tooltip, {Wrapper} from '@smui/tooltip';
  import { onMount } from 'svelte';

  /***** user-profile-component.svelte Component *****
   * This component shows user profile information such as their name, email address, and profile picture
   * along with the ability to open the user's profile page in a new tab
   *
   ******Parameters ******************************************************************************
   * @param autoId (string) - The auto id for Cypress E2E testing
   * @param class {string} - Any classes to add to the component
   * @param customerMerchant {Object} - The object returned from the customers api
   * @param isNewUser {Boolean} - If true the user is a new user (defined by having only one feedback for the current merchant.)
   * @param anonymous {Boolean} - indicates the survey was completed anonymously
   */

  export let autoId = 'user-profile-component';
  export {className as class};
  export let customerMerchant;
  export let isNewUser;
  export let anonymous;

  let className = '';
  let emailDisplay, shortenEmail;

  function openTab() {
    const url = window.location.origin + window.location.pathname + '#/customers/' + customerMerchant?.id;
    window.open(url, '_blank');
  }

  function copyEmailToClipboard() {
    navigator.clipboard.writeText(customerMerchant?.email);
  }

  $: hasName = customerMerchant?.first_name || customerMerchant?.last_name;

	onMount(() => {
    chooseEmailDisplay();
		window.addEventListener('resize', chooseEmailDisplay);
		return () => window.removeEventListener('resize', chooseEmailDisplay);
	}); 

  function chooseEmailDisplay(){
    shortenEmail = customerMerchant?.email.split("@")[0]+"@...";
    if(shortenEmail.length > 16){
      shortenEmail = customerMerchant?.email.substr(0, 16)+"@...";
    }
    emailDisplay = window.innerWidth < 1367 ? shortenEmail : customerMerchant?.email;
  }

  function revealFullEmail(){
    if(window.innerWidth < 1367){      
      emailDisplay = customerMerchant?.email;
    }
  }

  function hideFullEmail(){
    if(window.innerWidth < 1367){
      emailDisplay = shortenEmail;
    }
  }
</script>

<div class="user-profile-component {className}" data-cy="{autoId}">
  <div class="image-container" data-cy="{autoId}-image-container">
      <UserProfilePicture
        autoId="{autoId}-user-profile-picture"
        size="60"
        photoUrl={customerMerchant?.picture_url}
        anonymous={anonymous}
        {isNewUser}
      />
  </div>

  <div class="profile-wrapper">
    {#if hasName}
      <Wrapper>
        <div class="title-container customer-email-display" data-cy="{autoId}-customer-name" on:click={openTab}>
          {customerMerchant.first_name} {customerMerchant.last_name}
        </div>
        <Tooltip style="z-index: 9999;">
          <slot name="tooltip">
            View the customer profile in a new window
          </slot>
        </Tooltip>
      </Wrapper>
      <div class="email-container" data-cy="{autoId}-customer-email">
        <div class="customer-email-display">
          {customerMerchant.email}          
        </div>
        <span class="material-icons copy-icon" on:click={copyEmailToClipboard}>content_copy</span>
      </div>
    {/if}
    {#if !hasName}
    <div class="wrapper-container">
      {#if !anonymous}
        <Wrapper>
          <div class="title-container" data-cy="{autoId}-customer-email" on:click={openTab}>
              <div on:mouseenter={revealFullEmail} on:mouseleave={hideFullEmail} class="customer-email-display">{emailDisplay}</div>
          </div>
          <Tooltip style="z-index: 9999;">
            <slot name="tooltip">
              View the customer profile in a new window
            </slot>
          </Tooltip>
        </Wrapper>
        <span class="material-icons copy-icon" on:click={copyEmailToClipboard}>content_copy</span>
      {/if}
      {#if anonymous}
      <Wrapper>
        <div class="title-container" data-cy="{autoId}-customer-email">
            <div class="anonymous-text">—</div>
        </div>
      </Wrapper>
      {/if}
    </div>
    {/if}
  </div>
</div>


<style lang="scss">
  .wrapper-container{
    display: flex;
    flex-wrap: wrap;
  }

  .title-container {
    font-size: 20px;
    font-weight: 500;
    color: var(--mdc-theme-primary);
    cursor: pointer;
  }

  .email-container{
    display:flex;
  }

  .user-profile-component {
    display: flex;
  }

  .image-container {
    padding-right: 10px;
  }

  .material-icons {
    font-size: 15px;
    vertical-align: top;
  }

  .copy-icon{
    position: relative;
    left: 5px;
    color: var(--mdc-theme-primary);
    font-size: 15px;
    cursor: pointer;
    align-content: center;
  }

  .customer-email-display {
    width: 395px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  .profile-wrapper {
    align-content: center;
  }
</style>

