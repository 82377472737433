import {headers, baseURL} from 'src/services/auth';

/**
 * Returns all permissions associated with an userId
 * @param userId {String} the userId to loop up
 * @returns {Promise<any>} A promise returning the permmissions information
 */
export function getPermissions(userId){
    const url = new URL(`${baseURL}/v2/api/users/${userId}/permissions`);

    return fetch(url.toString(), {
        method: 'GET',
        headers: headers
    }).then(result => result.json());
}