<script>
  import DefaultProfilePicture from 'src/svg/default-profile-picture.svelte';
  import AnonymousProfilePicture from '../svg/anonymous-profile-picture.svelte';
  import Tooltip, { Wrapper } from '@smui/tooltip';

  /***** user-profile-picture.svelte Component *****
   * Displays a user's profile picture, or an icon representing their hash along with a new badge
   *
   ******Parameters ******************************************************************************
   * @param autoId (string) - The auto id for Cypress E2E testing
   * @param class {string} - Any classes to add to the component
   * @param photoUrl {String} - A link to where the user's photo is stored
   * @param size {Number} - The size of the photo/icon in pixels
   * @param isNewUser {Boolean} - If true, displays a NEW USER badge underneath the photo/icon
   * @param anonymous {Boolean} - indicates the survey was completed anonymously
  */

  export let autoId = 'user-profile-picture';
  export {className as class};

  let className = '';

  export let photoUrl;
  export let size;
  export let isNewUser;
  export let anonymous;

  let canvas;
  let additionalMargin;


  $: {
    if (isNewUser) {
      additionalMargin = 7;
    } else {
      additionalMargin = 5;
    }
  }

</script>

<div class="user-profile-picture {className}" style="--base-size: {size}px; --additional-margin: {additionalMargin}px;" data-cy="{autoId}">
  {#if photoUrl}
    <img src="{photoUrl}" alt="customer" class="customer-profile-picture" width={size} height={size} data-cy="{autoId}-photo"/>
  {/if}
  {#if !photoUrl && !anonymous}
    <DefaultProfilePicture {size} data-cy="{autoId}-default-picture"/>
  {/if}
  {#if !photoUrl && anonymous}
    <AnonymousProfilePicture {size} data-cy="{autoId}-default-picture"/>
  {/if}
  {#if isNewUser}
    <Wrapper>
      <div class="new-badge" data-cy="{autoId}-new-badge">NEW</div>
      <Tooltip style="z-index: 9999;">This is their first feedback submission.</Tooltip>
    </Wrapper>
  {/if}
</div>


<style lang="scss">
  .user-profile-picture {
    margin-bottom: var(--additional-margin);
  }

  .customer-profile-picture {
    border-radius: 50%;
    margin: 0;
    padding: 0;
  }

  .new-badge {
    background-color: var(--tattle-new-user-badge-background-color);
    color: var(--tattle-new-user-badge-font-color);
    font-size: 10px;
    padding: 3px 7px;
    margin-top: -10px;
    margin-left: 11px;
    user-select: none;
    width: 37px;
    height: 17px;
    border-radius: 12px;
    position: relative;
  }


</style>

