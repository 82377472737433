<script>
  import Dialog, {Title, Header, Content} from '@smui/dialog';
  import IconButton from '@smui/icon-button';
  import { orderBy } from "lodash"

  /***** menu-level-feedback-modal Component *****
   * Displays a modal containing menu items and their ratings
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param show {Boolean} - If true, displays the modal
   * @param menuItemLevelFeedback {Object} -  The menu item level feedback from this response's Item Level Feedback survey
   */

  export let autoId = 'menu-level-feedback-modal';
  export {className as class};

  export let show = false;
  export let menuItemLevelFeedback;

  let className = '';

</script>

<Dialog class="menu-level-feedback-modal {className}" sheet data-cy={autoId} bind:open={show} fullscreen>

  <Header>
    <Title tabindex="0" id="fullscreen-title">Menu Items</Title>
    <IconButton action="close" class="material-icons">close</IconButton>
  </Header>

  <Content>
    {#each orderBy(menuItemLevelFeedback.data, (item) => (item.itemRating), 'desc') as item}
      <div class="mlf-modal-container">

        <div class="mlf-modal-item">
          <div class="mlf-modal-item-label">{item.itemLabel}</div>
          {#if item.itemNote}
            <div class="mlf-modal-item-note">{item.itemNote}</div>
          {/if}
        </div>

        <div class="mlf-modal-item mlf-modal-item-rating checked-{item.itemRating}">
          <span class="fa fa-star {item.itemRating < 1 ? 'unchecked': ''}"></span>
          <span class="fa fa-star {item.itemRating < 2 ? 'unchecked': ''}"></span>
          <span class="fa fa-star {item.itemRating < 3 ? 'unchecked': ''}"></span>
          <span class="fa fa-star {item.itemRating < 4 ? 'unchecked': ''}"></span>
          <span class="fa fa-star {item.itemRating < 5 ? 'unchecked': ''}"></span>
        </div>
      </div>
    {/each}
  </Content>

</Dialog>


<style lang="scss">
  :global(.menu-level-feedback-modal .mdc-dialog__header) {
    margin-bottom: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecef;
  }

  :global(.menu-level-feedback-modal .mdc-dialog__content) {
    text-align: left;
    padding: 15px;
    font-size: var(--mdc-typography-caption-font-size);
    font-family: Roboto;
    color: #222f48;
  }

  :global(.menu-level-feedback-modal .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.menu-level-feedback-modal .mdc-dialog__title) {
    font-size: 16px;
  }

  :global(.menu-level-feedback-modal.mdc-dialog .mdc-dialog__surface) {
    max-height: 70%;
  }

  :global(.menu-level-feedback-modal  .mdc-dialog__actions) {
    border-top-color: #e9ecef;
  }

  :global(.menu-level-feedback-modal .mdc-dialog__actions button) {
    margin-right: 10px;
    border-radius: 18px;
  }

  .mlf-modal-container {
    display: flex;
    padding-bottom: 15px;
    margin-left: 15px;
  }

  .mlf-modal-item {
    flex: 1;
    margin: auto;
  }

  .mlf-modal-item-rating {
    text-align: center;
  }

  .mlf-modal-item-label {
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding-bottom: 5px;
  }

  .mlf-modal-item-note {
    color: #828899;
  }

  .unchecked {
    color: #70757a;
  }
  .checked-1 {
    color: #A50126;
  }
  .checked-2 {
    color: #F56D43;
  }
  .checked-3 {
    color: #F7CB48;
  }
  .checked-4 {
    color: #A7D96A;
  }
  .checked-5 {
    color: #006837;
  }

</style>
