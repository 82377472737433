import {headers} from 'src/services/auth';

/**
 * Retrieves the list of snapshots for a given questionnaire id
 * @param {String} questionnaireId The id for the questionnaire
 * @returns {Promise<Object>} An object containing an array of snapshots
 */
export function getSnapshots(questionnaireId) {
  return fetch(window.TATTLE_API_URL_EMPTY + `/v2/api/customer-questionnaire-snapshots?customer_questionnaire_id=${questionnaireId}&size=-1&expand=snapshot,customer_questionnaire,questionnaire,questionnaire_snapshots&order=sort_ord+ASC,+date_time_created+ASC`, {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.customer_questionnaire_snapshots);
}
