/**
 * Registers a listener on the angular root scope
 * @param eventName {String} - The event name to listen for
 * @param handler {Function} - The function called when the event is fired
 * @returns {*}
 */
export function registerListener(eventName, handler){
  return window.tattleRootScope.$on(eventName, handler);
}

/**
 * Broadcasts an event on the angular root scope
 * @param eventName {String} - The name of the event to broadcast
 * @param source {String} - The source of the event being fired (generally the class name that is calling this)
 * @param data {Object} - Optional - Any data that needs to be passed to the listener
 * @returns {{name: *, defaultPrevented, targetScope: this, preventDefault: function(): void}}
 */
export function broadcastEvent(eventName, source, data=null){
  if (!data) {
    data = {source: source};
  } else {
    data.source = source;
  }

  return window.tattleRootScope.$broadcast(eventName, data);
}
