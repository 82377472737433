<script>
  /***** order-details Component *****
   * Contains the order details if provided from an external source
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param transaction {Object} - Returned from the transaction endpoint, it's possible for this to be undefined if a merchant doesn't use a transaction processor
   * @param survey {Object} - The survey the order details are in reference to
   */

  export let autoId = 'order-details';
  export {className as class};
  export let transaction;
  export let survey;

  let className = '';

  $: deliveryText = transaction?.is_delivery === 0 ? 'No' : 'Yes';

  $: showOrderNumber = Boolean(transaction?.external_id);
  $: showOrderAmount = Boolean(transaction?.cost);
  $: showOloLink = transaction?.partners_id === 5;
  $: showReferralSource = Boolean(transaction?.partners.label);
  $: showDelivery = Boolean(transaction?.is_delivery);
  $: showComoLink = transaction?.partners_id === 2 && survey?.merchants_id == 2179;


</script>

<div data-cy="order-details-section" class="order-details-outer-wrapper {className}">
  <div data-cy="order-details-title" class="order-details-header">
    Order Details
  </div>

  {#if showOrderNumber}
    <div class="order-details-section">
      <div data-cy="order-details-order-number-label" class="order-details-subheader">
        Order Number
      </div>
      {#if showOloLink}
        <div data-cy="order-details-order-number-text" class="order-details-value">
          <a data-cy="order-details-order-link" class="underline text-primary" href="https://my.olo.com/Orders/Order/Details/{transaction?.external_id}" target="_blank">{transaction?.external_id}</a>
        </div>
      {:else if showComoLink}
        <div data-cy="order-details-order-number-text" class="order-details-value">
          <a data-cy="order-details-order-link" class="underline text-primary" href="https://ordering.como.com/en/manage/nojs/order-details/{transaction?.external_id}" target="_blank">{transaction?.external_id}</a>
        </div>
      {:else}
        <div data-cy="order-details-order-number-text" class="order-details-value">
          {transaction?.external_id}
        </div>
      {/if}
    </div>
  {/if}

  {#if showOrderAmount}
    <div class="order-details-section">
      <div data-cy="order-details-order-amount-label" class="order-details-subheader">
        Order Amount
      </div>
      <div data-cy="order-details-order-amount-text" class="order-details-value">
        ${transaction?.cost}
      </div>
    </div>
  {/if}

  {#if showReferralSource}
    <div class="order-details-section">
      <div data-cy="order-details-referral-source-label" class="order-details-subheader">
        Referral Source
      </div>
      <div data-cy="order-details-referral-source-text" class="order-details-value">
        {transaction.partners.label}
      </div>
    </div>
  {/if}

  {#if showDelivery}
    <div class="order-details-section">
      <div data-cy="order-details-delivery-label" class="order-details-subheader">
        Delivery
      </div>
      <div data-cy="order-details-delivery-text" class="order-details-value">
        {deliveryText}
      </div>
    </div>
  {/if}
</div>


<style lang="scss">
  .order-details-header {
    font-weight: 500;
    padding-bottom: 10px;
    user-select: none;
    height: 52px;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 52px;
    margin-bottom: 20px;
  }

  .order-details-value {
    font-weight: bold;
  }

  .order-details-subheader {
    padding-bottom: 5px;
    font-weight: normal;
  }

  .order-details-section {
    padding-bottom: 15px;
    font-size: var(--mdc-typography-subtitle2-font-size);

    margin-left: 30px;
    margin-right: 30px;
  }

  .order-details-outer-wrapper {
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    max-width: 240px;
    border-top-width: 0;
    border-bottom-width: 0;
  }
</style>

