<script>
  export let size = 60;

  $: ratio = size / 56;
</script>

<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" style="--ratio: {ratio}">
  <defs>
    <style>
      .cls-1 {
        fill: #cbcbcb;
      }

      .cls-2 {
        fill: #fff;
      }
    </style>
  </defs>
  <g id="Group_4777" data-name="Group 4777" transform="translate(-108 -74)">
    <g id="User_Menu" data-name="User Menu" transform="translate(-1446.992 58)">
      <rect id="Avatar" class="cls-1" width="56" height="56" rx="28" transform="translate(1554.992 16)"/>
    </g>
    <path id="ic_account_circle_24px" class="cls-2"
          d="M30,2A28,28,0,1,0,58,30,28.01,28.01,0,0,0,30,2Zm0,8.4a8.4,8.4,0,1,1-8.4,8.4A8.389,8.389,0,0,1,30,10.4Zm0,39.76a20.161,20.161,0,0,1-16.8-9.016c.084-5.572,11.2-8.624,16.8-8.624,5.572,0,16.716,3.052,16.8,8.624A20.161,20.161,0,0,1,30,50.16Z"
          transform="translate(106 72)"/>
  </g>
</svg>

<style lang="scss">
  svg {
    transform: scale(var(--ratio, 1));
  }

</style>

