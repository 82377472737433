import {headers, baseURL} from 'src/services/auth';

// POST
// 	https://gettattle.com/v2/api/users-merchants-logs
/**
 *
 * @param action
 * @param apiEndpoint
 * @param endpointRecordId {String} - The unique id returned from the endpoint (example, if sending a thank you, the id would be the customer_response id)
 * @param merchantsId
 * @param operation
 * @param usersId
 * @return {Promise<any>}
 */
export function sendUsersMerchantsLog(action, apiEndpoint, endpointRecordId, merchantsId, operation, usersId) {
  const url = new URL(`${baseURL}/v2/api/users-merchants-logs`);

  return fetch(url.toString(), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      action: action,
      api_endpoint: apiEndpoint,
      endpoint_record_id: endpointRecordId,
      merchants_id: merchantsId,
      operation: operation,
      users_id: usersId
    })
  }).then((result) => {
    return result.json();
  });
}
