<script>
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import {Label} from '@smui/button';

  /***** enhanced-profile-footer.svelte Component *****
   * Description goes here
   *
   ******Parameters ******************************************************************************
   * @param class {string} - Any classes to add to the component
   */

  const autoId = 'enhanced-profile-footer';
  export {className as class};
  export let surveyId;

  let className = '';
  const surveyUrl = `#/survey/${surveyId}`;

</script>

<div class="enhanced-profile-footer {className}" data-cy={autoId}>

  <a href={surveyUrl}>
    <Button
      class="response-button"
      type="raised"
      color="primary"
    >
      <Label>
        <div class="label-contents">View Full Customer Response</div>
      </Label>
    </Button>
  </a>

</div>


<style lang="scss">

  * :global(button) {
    border-radius: 30px;
    height: 30px;
    padding: 20px 10px;
    font-size: 16px;
  }

  .enhanced-profile-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    margin: 20px 20px 30px 20px;
  }

  .response-button {
    margin-bottom: 20px !important;
  }

</style>
