<script>
 import Button, { Label } from '@smui/button';
 import '@smui/button/bare.css';
 import './../theme/colors.css';

	/***** Button.svelte Component *****
	* For the Svelte Button, we import Material's button as a base

	* Style Guide for Material with Svelte: https://sveltematerialui.com/demo/button/

	******Parameters ******************************************************************************
	* @param type (string) - Button style type based on material button variants.  Options include:
	*     "contained" - style the button as a contained button
	*     "outlined" - style the button as an outlined button
	*	  "text" - style the button to be a text button
	* @param color (string) - How the buttons should be colored.  Options include:
	*	   "primary" - style the button as a primary color
	*      "secondary" - style the button as a secondary color
	* @param disabled (boolean) - Button is enabled or disabled
	* @param icon (string) - Icon to display inside the button.  Options include:
	*	  "plus-light" - provides a plus sign in the button that is light
	*	  "plus-dark" - provides a plus sign in the button that is dark
    * @param on:click - fired whenever the button is clicked
	* @param autoId (string) - The auto id for Cypress E2E testing for the button
	* @param postIcon {string} - Optional string, if provided, places a font awesome icon after the label text
	* @param href {string} - Optional string, if provided, button is treated like a link
	* @param target {string} - Optional string, if provided, button is treated like a link
	* @slot slot -  The contents of the button, usually a Label
	 */
	export let type = "contained";
	export let color = "primary";
	export let disabled = false;
	export let autoId;
	export let href;
	export let target;

</script>
<div>
	<Button
		variant={type}
		color={color}
		disabled={disabled}
		on:click
		data-cy={autoId}
		{href}
		{target}
	>
		<slot/>
	</Button>
</div>

<style lang="scss">
	* :global(.smui-button--color-secondary:not(:disabled)) {
		color: var(--mdc-theme-secondary) !important;
	}

	* :global(.smui-button--color-secondary.mdc-button--raised:not(:disabled)) {
		background-color: var(--mdc-theme-secondary) !important;
		color: var(--mdc-theme-on-secondary) !important;
	}

	* :global(.smui-button--color-secondary.mdc-button--outlined:not(:disabled)) {
		border-color: var(--mdc-theme-secondary) !important;
	}

</style>