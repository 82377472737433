// toast-constants.js

  // for <SvelteToast>
  export const toastOptions = {
    duration: 3000,       // duration of progress bar tween to the `next` value
    initial: 1,           // initial progress bar value
    next: 0,              // next progress value
    pausable: false,      // pause progress bar tween on mouse hover
    dismissable: false,   // allow dismiss with close button
    intro: { x: 256 }     // toast intro fly animation settings
  }

  export const toastSuccessTheme = {
    '--toastWidth': '470px',
    '--toastBarBackground': '#00aa00',
    '--toastBarHeight': '0px',
    '--toastMargin': '0px',
    '--toastPadding': '0px',
    '--toastMsgPadding': '0px',
  }

  export const toastErrorTheme = {
    '--toastWidth': '470px',
    '--toastBarBackground': '#aa0000',
    '--toastBarHeight': '0px',
    '--toastMargin': '0px',
    '--toastPadding': '0px',
    '--toastMsgPadding': '0px',
  }
