<script>

  import FeedbackTooltip from 'src/building-blocks/feedback-tooltip.svelte';
  import {feedbackTooltipStore} from "src/stores/feedbackTooltipStore";
  import {replyTooltipStore} from "src/stores/replyTooltipStore";
  // the Toast is controlled on this page - not the FeedbackTooltip
  import { toast } from '@zerodevx/svelte-toast'
  import ToastSuccess from 'src/building-blocks/toast-success.svelte';
  import ToastError from 'src/building-blocks/toast-error.svelte';
  import { toastOptions, toastSuccessTheme, toastErrorTheme } from 'src/building-blocks/toast-constants.js';
  // userId is now a store
  import {userId} from 'src/services/auth.js';
  // V2 API call
  import {flagSurveyFeedback} from 'src/services/api/survey';

  /***** questionable-feedback Component *****
   * Displays a questionable feedback header and button
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   */

  export let autoId = 'questionable-feedback';
  export {className as class};
  export let survey;

  let className = '';
  // returned from Tooltip
  let newReason = '';

  // Toasts
  const showToastSuccess = () => {
    // passing in a Svelte component - use the props to pass to the comp
    toast.push({
      ...toastOptions,
      theme: toastSuccessTheme,
      component: {
        src: ToastSuccess,
        props: {
          message: "Feedback Flagged",
          onClose: closeToast
        }
      }
    });
  }

  const showToastError = () => {
    toast.push({
      ...toastOptions,
      theme: toastErrorTheme,
      component: {
        src: ToastError,
        props: {
          message: "Feedback not Flagged",
          onClose: closeToast
        }
      }
    });
  }

  const closeToast = () => {
    toast.pop();
  }


  // called by the Tooltip when Flag button clicked
  const flagFeedback = (comment) => {
    newReason = comment;

    // this will update the local survey until refreshed
    flagSurveyFeedback(survey.id, $userId, comment)
      .then(result => {
        showToastSuccess();
        survey.flagged = 1;                     // int - not a Boolean in response
        survey.flagged_reason = newReason;
      })
      .catch(e => {
        showToastError();
      })
  }


  // Feedback Tooltip - poaitioning now in component

  const formatTooltipData = (survey) => {
    return {
      isFlagged: survey.flagged > 0,
      reason: survey.flagged_reason || newReason,
      flaggedBy: survey.flagged_by,
      callback: flagFeedback
    };
  }

  const showFeedbackTooltip = (e) => {
    const flagIcon = document.getElementById('flag-icon');
    e.preventDefault();
    // do not interpret this as a drawer click which will hide it
    e.stopPropagation();
    // hide the ReplyTooltip
    replyTooltipStore.set({
      anchorElem: null,
      isVisible: false,
      data: null
    })
    // show the FeedbackTooltip
    feedbackTooltipStore.set({
      anchorElem: flagIcon,
      isVisible: true,
      data: formatTooltipData(survey),
    })
  }

</script>


<div class="questionable-feedback-wrapper d-flex {className}" data-cy={autoId}>
  <!-- both the text and the flag icon are clickable -->
  {#if survey.flagged === 0}
    <div class="questionable-feedback-content color-unflagged" >
      <div class="questionable-feedback-tooltip" on:click={showFeedbackTooltip}>
        <span class="questionable-tooltip-label">
          Flag for review
        </span>
        <span class="material-icons flag-icon bg-color-unflagged" tabindex="0" id="flag-icon">flag</span>
      </div>
    </div>
  {:else}
    <div class="questionable-feedback-content color-flagged" >
      <div class="questionable-feedback-tooltip" on:click={showFeedbackTooltip} >
        <span class="questionable-label">
          Review flagged
        </span>
        <span class="material-icons flag-icon bg-color-flagged" tabindex="0" id="flag-icon">flag</span>
      </div>
    </div>
  {/if}

  <FeedbackTooltip />

</div>



<style lang="scss">

  /* for <SvelteToast> */
  :root {
    --toastContainerTop: auto;
    --toastContainerLeft: auto;
    --toastContainerRight: 40px;
    --toastContainerBottom: 20px;
  }

  /* only works for Toast fonts */
  .toast-wrapper {
    font-family: "Roboto";
    font-size: 16px;
  }

  .questionable-feedback-header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    user-select: none;
    height: 52px;
    background-color: #f0f0f0;
  }

  .questionable-feedback-content {
    color: var(--tattle-flag-feedback-color);
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: left;
    cursor: pointer;
  }

  .questionable-feedback-tooltip {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .flag-icon {
    display: inline;
    color: white;
    border-radius: 50%;
    font-size: 14px;
    border-width: 2px;
    border-style: solid;
    margin-left: 5px;
    cursor: pointer;
  }

  .color-unflagged {
    color: var(--tattle-flag-feedback-color);
  }

  .color-flagged {
    color: #616c7f;
  }

  .bg-color-unflagged {
    background-color: var(--tattle-flag-feedback-color);
    border-color: var(--tattle-flag-feedback-color);
  }

  .bg-color-flagged {
    background-color: #616c7f;
    border-color: #616c7f;
  }

  .is-disabled {
    color: #616c7f;
  }

  .questionable-feedback-wrapper {
    margin: auto;
    padding-left: 8px;
    width: 120px;
    margin-right: 15px;
  }

  .questionable-tooltip-label {
    text-wrap: nowrap;
  }

</style>
