import {headers, baseURL} from 'src/services/auth';

/**
 * Returns a customer record
 * @param customerId {String} the id representing the customer
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<any>} A promise returning the customer record
 */
export function getCustomer(customerId, params) {
  const url = new URL(`${baseURL}/v2/api/customers/${customerId}`);
  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  }).then(result => result.json());
}
