<script>
  import UserProfileHeader from 'src/building-blocks/user-profile-component.svelte';
  import TattleScoreDisplay from 'src/building-blocks/tattle-score-display.svelte';
  import ReplyToCustomerButton from 'src/building-blocks/reply-to-customer-button.svelte';
  import QuestionableFeedback from 'src/pages/sidebars/enhanced-profile-sidebar/sections/feedback-details-sections/questionable-feedback.svelte';
  import IconButton from 'tttl-core-ui/src/components/IconButton.svelte';
  import ReplyToCustomerModal from 'src/pages/sidebars/enhanced-profile-sidebar/modals/reply-to-customer-modal.svelte';
  import HandledExternallyModal
    from 'src/pages/sidebars/enhanced-profile-sidebar/modals/handled-externally-modal.svelte';
  import {checkPermission} from "src/services/utility/permissionsHelper";
  import TagMenu from 'src/building-blocks/tag-menu.svelte';
  import EditTagModal from 'src/pages/sidebars/enhanced-profile-sidebar/modals/edit-tag-modal.svelte';
  import {createEventDispatcher} from 'svelte';
  import Tooltip, {Wrapper} from '@smui/tooltip';

  /***** enhanced-profile-header.svelte Component *****
   * Displays the header of the enhanced profile
   *
   ******Parameters ******************************************************************************
   * @param class {string} - Any classes to add to the component
   * @param autoId (string) - The auto id for Cypress E2E testing
   * @param customerMerchant {Object} - The object returned from the customers api
   * @param filteredMerchantSurveys {Array} - An array containing all the customer's surveys for this merchant minus flagged feedback
   * @param survey {Object} - The object returned from the survey api
   * @param onClose {Function} - Fired when a user clicks the close button
   * @param messageTemplates {Array} - An array of all the message templates available
   * @param merchantRewards {Array} - An array of all rewards available to a merchant
   * @param permissions {Object} permission object returned from an api request to users/{userId}/permissions
   * @param anonymous {Boolean} - indicates the survey was completed anonymously
  */

  export let autoId = 'enhanced-profile-header';
  export {className as class};
  export let customerMerchant;
  export let filteredMerchantSurveys;
  export let tattle;
  export let survey;
  export let onClose;
  export let messageTemplates;
  export let groupLocations;
  export let merchantRewards;
  export let links;
  export let permissions;
  export let anonymous;
  export let surveyTags;
  export let merchantTags;

  let className = '';

  const dispatch = createEventDispatcher();

  $: isNewUser = filteredMerchantSurveys?.length === 1 && !anonymous;
  $: filteredSurveyTags = surveyTags.map(tag => String(tag.id));

  // iterate surveys and average all CERs for this customer
  // this allows us to only pass a number to the component
  const getAverageCER = () => {
    let total = 0;
    let surveyCount = 0;
    if (!filteredMerchantSurveys) return 0;
    surveyCount = filteredMerchantSurveys.length;
    if (surveyCount === 0) return 0;
    for (const survey of filteredMerchantSurveys) {
      total += survey.rating;
    }
    return (total / surveyCount).toFixed(2);
  }

  let showReplyToCustomerButton = !anonymous && checkPermission(permissions, 'can_respond_to_tattle_feedback');
  let replyWithAI = checkPermission(permissions, 'can_respond_to_reviews_with_ai')
  let showQuestionableFeedback = checkPermission(permissions, 'customer_questionnaire.can_flag')
  let showReplyToCustomerModal = false;
  let showHandledExternallyModal = false;
  let replyToCustomerTemplates;
  let replyAction;
  let replyToCustomerInProgress;
  let handledExternallyInProgress;
  let showTagModal = false;

  function replySelected(item) {
    // TODO: Move to service
    const feedbackLookup = {
      'requestSocialFeedback': 5,
      'sendThankYou': 3,
      'sendApology': 2,
      'handledExternally': 8
    }

    const replyActionLookup = {
      'requestSocialFeedback': 'yelp',
      'sendThankYou': 'thankyou',
      'sendApology': 'apology',
      'handledExternally': 'external'
    };

    replyAction = replyActionLookup[item.detail.action];

    if (replyAction !== 'external') {
      showReplyToCustomerModal = true;
      replyToCustomerTemplates = messageTemplates.filter(template =>
        template.message_type_id === feedbackLookup[item.detail.action]
        && template.active  
        && (groupLocations.some(group => group.groups_id === template.groups_id) || template.groups_id == null));
    } else {
      showHandledExternallyModal = true;
    }
  }

  const openAddTagModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showTagModal = true;
  }

  function updateTags() {
    dispatch('tagsAdded');
  }

</script>

<div class="enhanced-profile-header {className}" data-cy={autoId}>
  <div class="user-profile-reply-container">
    <UserProfileHeader {customerMerchant} {isNewUser} {anonymous}/>
    <div class="user-profile-actions-container m-b-lg">
      {#if showReplyToCustomerButton}
        <ReplyToCustomerButton dropdownItems={survey.recommendations.allLinks} 
                               on:select={replySelected} 
                               inProgress={replyToCustomerInProgress || handledExternallyInProgress}/>
      {/if}
      {#if showQuestionableFeedback}
        <QuestionableFeedback class="section-wrapper questionable-feedback-component" {survey} />
      {/if}
      <TagMenu on:click={openAddTagModal} />
    </div>
    <ReplyToCustomerModal bind:show={showReplyToCustomerModal}
                          bind:inProgress={replyToCustomerInProgress}
                          messageTemplates={replyToCustomerTemplates}
                          survey={survey}
                          actionType={replyAction}
                          customer={customerMerchant}
                          {merchantRewards}
                          {links}
                          canUseAiReply={replyWithAI}/>
    <HandledExternallyModal {survey} bind:show={showHandledExternallyModal} inProgress={handledExternallyInProgress}/>

    <EditTagModal {survey} {merchantTags} selectedTags={filteredSurveyTags} bind:show={showTagModal} on:tagsAdded={updateTags} />
  </div>

  <div class="top-margin">
    <TattleScoreDisplay label="CER" starRating={tattle.rating}>
      <div slot="tooltip">
        <div><strong>What is CER?</strong></div>
        <br/>
        <div> Customer Experience Rating (CER) is the <strong>overall 5-star experience
          rating</strong> as indicated by the guest. This is the benchmark of their experience.
        </div>
      </div>
    </TattleScoreDisplay>

    <TattleScoreDisplay label="Survey Score" percentage={tattle.score}>
      <div slot="tooltip">
        <div><strong>What is Survey Score?</strong></div>
        <br/>
        <div> The Survey Score is <strong>the average of all Operational Category star ratings and
          any additional follow up questions that are marked as scoreable.</strong> The Survey Score often
          correlates strongly to the CER, but it can differ.
        </div>
      </div>
    </TattleScoreDisplay>

    <TattleScoreDisplay label="Avg. CER" starRating={getAverageCER()}>
      <div slot="tooltip">
        <div><strong>What is Avg. CER?</strong></div>
        <br/>
        <div>Average Customer Experience Rating (Avg. CER) is the <strong>average 5-star experience
          ratings</strong> for all experiences as indicated by the guest.
        </div>
      </div>
    </TattleScoreDisplay>
  </div>

  <IconButton class="material-icons close-button" autoId="enhanced-profile-close-button" on:click={onClose}>close</IconButton>
  {#if surveyTags && surveyTags.length > 0}
    <div class="break-line"></div>
    <div class="tags-wrapper">
      {#each surveyTags as tag }
        {#if tag.active}
          <Wrapper>
            <div class="survey-tag-pill" data-cy="survey-tag">
              {tag.label}
            </div>
            <Tooltip class="tag-tooltip" style="z-index: 9999;">
                {#if tag.description}
                {tag.description}
                {:else}
                No description.
                {/if}
              </Tooltip>
          </Wrapper>
        {/if}
      {/each}
    </div>
  {/if} 
</div>


<style lang="scss">
  .enhanced-profile-header {
    display: flex;
    padding: 30px 0 20px;
    flex-flow: row wrap;
  }

  .break-line {
    flex-basis: 100%;
    width: 0px;
    height: 0px;
    overflow: hidden;
  }

  .tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .survey-tag-pill {
    height: 25px;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    background: #E5E5E5;
    border-radius: 100px;
    align-content: center;
    padding: 0 16px;
    margin: 5px;
  }

  * :global(.close-button) {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .top-margin {
    display: flex;
    justify-content: space-between;
    width: 372px;
    max-width: 418px;
    min-width: 110px;
    margin-top: auto;
    margin-right: 30px;
    margin-bottom: auto;
  }

  .user-profile-actions-container {
    display: flex;
  }

  .score-container {
    display:inline-block; 
    width:100%;
  }

  :global(.tag-tooltip .mdc-tooltip__surface-animation) {
    display: flex;
    width: 161px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 2px 7px 0px rgba(73, 73, 73, 0.25);
    color: #353535;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
  }

  .user-profile-reply-container {
    margin-right: clamp(10px, 3.7vw, 70px);
  }
</style>

