<script>
  import Tooltip, {Wrapper} from '@smui/tooltip';

  /***** tattle-score-display.svelte Component *****
   *
   * This generic component shows 5-star user CER Ratings, Survey scores, etc.
   * This will display decimal points if they are present in value
   * pass in .label to display top area with .tooltipText
   * pass in .starRating (1-5) to display star icon
   * pass in .percentage (0-100) to display % sign
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param customerMerchant {Object} - The object returned from the customers api
   * @param label {String} - The label to place above the rating
   * @param tooltipText {String} - The text to show up when a user hovers over the question mark, this is used for plain text
   * @param tooltip {Slottable} - The text to show up when a user hovers over the question mark, supports HTML/rich formatting
   * @param starRating {Number} - The star rating between 1 and 5 inclusive
   * @param percentage {Number} - The percentage rating between 0 and 100 inclusive
   *
   */

  export let autoId = 'tattle-score-display';

  export {className as class};
  let className = '';

  export let label;
  export let tooltipText;
  export let starRating = null;    // 1 - 5
  export let percentage = null;    // 0 - 100

  let colorClass;

  $:{
    if (starRating !== null && starRating !== undefined) {
      colorClass = 'star-color-' + Math.floor(Number(starRating));
    } else if (percentage !== null && percentage !== undefined) {
      colorClass = 'range-color-' + Math.floor(Number(percentage) / 10);
    } else {
      colorClass = '';
    }
  }

  $: {
    // range checks
    if (starRating !== null && starRating !== undefined) {
      if (Number(starRating) < 1) {
        starRating = 1;
      } else if (Number(starRating) > 5) {
        starRating = 5;
      }
    }
    if (percentage !== null && percentage !== undefined) {
      if (Number(percentage) < 0) {
        percentage = 0;
      } else if (Number(percentage) > 100) {
        percentage = 100;
      }
    }
  }
</script>

<div class="tattle-score-display {className}" data-cy="{autoId}">
  {#if label}
    <div class="score-box upper">
      <div class="tattle-score-label">{label}</div>
      <Wrapper>
        <div class="question-mark material-icons-outlined">help</div>
        <Tooltip style="z-index: 9999;">
          <slot name="tooltip">
            {tooltipText}
          </slot>
        </Tooltip>
      </Wrapper>
    </div>
  {/if}

  <div class="score-box lower {colorClass}">
    {#if starRating !== null && starRating !== undefined}
      <div>{starRating}</div>
      <div class="star-icon material-icons-outlined">star</div>
    {/if}
    {#if percentage !== null && starRating !== undefined}
      <div>{percentage}%</div>
    {/if}
  </div>
</div>

<style lang="scss">

  .tattle-score-display {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    min-width: 110px;
    background-color: #ffffff;
    height: 82px;
    border-color: #dedede;
    border-width: 1px;
    border-style: solid;
  }

  .tattle-score-label {
    font-weight: 500;
  }

  .score-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
  }

  .score-box.upper {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border-bottom-color: #ededed;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 5px 10px;
  }

  .score-box.lower {
    font-size: 15px;
    color: #ffffff;
    border-radius: 3px;
    font-weight: 500;

    height: 28px;
    width: 67px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
  }

  .question-mark {
    font-size: 14px;
    margin-left: 2px;
    margin-bottom: 3px;
    cursor: pointer;
  }

  .star-icon {
    font-size: 16px;
    margin-left: 2px;
  }

  /* map value to 5 colors - use common color file here? */
  .star-color-1 {
    background-color: #b70034;
  }

  .star-color-2 {
    background-color: #f0495d;
  }

  .star-color-3 {
    background-color: #f88245;
  }

  .star-color-4 {
    background-color: #ffbc2b;
  }

  .star-color-5 {
    background-color: #007244;
  }

  /* map value to 11 colors - need additional one for 100 */
  .range-color-0 {
    background-color: #b70034;
  }

  .range-color-1 {
    background-color: #b70034;
  }

  .range-color-2 {
    background-color: #b70034;
  }

  .range-color-3 {
    background-color: #f0495d;
  }

  .range-color-4 {
    background-color: #f0495d;
  }

  .range-color-5 {
    background-color: #f88245;
  }

  .range-color-6 {
    background-color: #ffbc2b;
  }

  .range-color-7 {
    background-color: #ffbc2b;
  }

  .range-color-8 {
    background-color: #5EB768;
  }

  .range-color-9 {
    background-color: #009564;
  }

  .range-color-10 {
    background-color: #007244;
  }

</style>
