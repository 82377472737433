<script>

  import {feedbackTooltipStore} from 'src/stores/feedbackTooltipStore';
  import { afterUpdate } from 'svelte';
  import { onDestroy } from 'svelte';


  /***** feedback-tooltip.svelte Component *****
   *
   * Tooltip popover for the Flag Feedback section of Feedback Details
   *
   * This tooltip positions itself based on the anchorElem (flag icon) in the store
   *
   ******Parameters ******************************************************************************
   * @param autoId (string) - The auto id for Cypress E2E testing
   *

    feedbackTooltipStore:

    anchorElem: target,
    isVisible: true,
    data: {
      isFlagged: survey.flagged > 0,
      reason: survey.flagged_reason,
      flaggedBy: survey.flagged_by,
      callback: flagFeedback
    }

  */

  export let autoId = 'feedback-tooltip';

  let className = '';
  let comment = '';     // the new reason feedback is being flagged

  // for self-positioning
  const tooltipPos = {top: 0, left: 0};
  const offsetX = 0;
  const offsetY = 16;

  // TODO: This is really brittle and will most likely break if we have more than one drawer
  // switch to using parents of the current element
  function calculatePosition(anchorElem) {
    // this element - might not be in DOM yet
    let tooltip = document.getElementById('feedback-tooltip');
    if (!tooltip) return;
    let tooltipRect = tooltip.getBoundingClientRect();

    let tooltipWidth = tooltipRect.width;

    // the flag icon in this case
    let anchorElemRect = anchorElem.getBoundingClientRect();
    let anchorCenterX = Math.round(anchorElemRect.x) + anchorElemRect.width / 2;
    let anchorCenterY = Math.round(anchorElemRect.y) + anchorElemRect.height / 2;

    let drawer = document.getElementsByClassName('mdc-drawer')[0];    // strange!
    if (!drawer) return;
    let drawerRect = drawer.getBoundingClientRect();
    let drawerX = Math.round(drawerRect.x);
    let drawerY = Math.round(drawerRect.y);

    let drawerContent = document.getElementsByClassName('mdc-drawer__content')[0];
    if (!drawerContent) return;

    let availSpaceToLeft = anchorCenterX - drawerX - offsetX;
    let newTop = anchorCenterY + offsetY + drawerContent.scrollTop;       // place below always
    let newLeft = 0;

    if (availSpaceToLeft <= tooltipWidth) {
      newLeft = anchorCenterX + offsetX;                                  // place to the right
    } else {
      newLeft = anchorCenterX - tooltipWidth - offsetX;                   // place to the left
    }

    // adjust placement to be relative to drawer not screen
    newTop -= drawerY;
    newLeft -= drawerX;

    tooltipPos.top = newTop;
    tooltipPos.left = newLeft;
  }

	afterUpdate(() => {
    let anchorElem = $feedbackTooltipStore.anchorElem;
    let isVisible = $feedbackTooltipStore.isVisible;
    let data = $feedbackTooltipStore.data;

    if (!anchorElem) return;        // not launched from UI yet
    if (!isVisible) return;
    if (!data) return;

    calculatePosition(anchorElem);

    // move to new pos while retaining visibility
    data.top = tooltipPos.top;
    data.left = tooltipPos.left;
    feedbackTooltipStore.set({
      anchorElem: anchorElem,
      isVisible: isVisible,
      data: data,
    })

	});


  const hideTooltip = () => {
    comment = '';
    let data = $feedbackTooltipStore.data;
    feedbackTooltipStore.set({
      anchorElem: null,
      isVisible: false,
      data: data,
    })
  }

  // call the host form's flagFeedback function if provided
  const flagFeedback = () => {
    if (comment === '') return;
    if (!$feedbackTooltipStore.data.callback) return;
    $feedbackTooltipStore.data.callback(comment);
    hideTooltip();
  }

  // do not interpret this as a drawer click which will hide it
  const cancelClickEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  onDestroy(() => {
    hideTooltip();
  })

</script>

{#if $feedbackTooltipStore.data}

  <div class="feedback-tooltip" data-cy={autoId}
    class:is-showing={$feedbackTooltipStore.isVisible}
    id="feedback-tooltip"
    style={`top: ${tooltipPos.top}px; left: ${tooltipPos.left}px`}
  >

    {#if !$feedbackTooltipStore.data.isFlagged}
      <div class="tooltip-title" data-cy={autoId + '-title'}>
        Flag this feedback
      </div>
      <div class="tooltip-content" data-cy={autoId + '-content'}>
        If you believe there is an issue with the integrity or content of this feedback or it is inappropriate, let us know.
        Our team will perform an audit of the feedback and alert you of the resolution.
      </div>
      <div class="tooltip-text-section" data-cy={autoId + '-text-section'}>
        Please let us know why you are flagging this feedback
        <textarea class="tooltip-textarea"
          bind:value={comment}
          data-cy={autoId + '-textarea'}
          on:click={cancelClickEvent}
        ></textarea>
      </div>
      <div class="tooltip-buttons" data-cy={autoId + '-buttons'}>
        <div class="tooltip-button" on:click={(e) => hideTooltip()}>Cancel</div>
        <div class="tooltip-button" on:click={(e) => flagFeedback()} default class:is-disabled={comment === ""}>Flag</div>
      </div>
    {:else}
      <div class="tooltip-title title-flagged" data-cy={autoId + '-title'}>
        This message has been flagged
      </div>
      <div class="tooltip-content" data-cy={autoId + '-content'}>
        If you believe there is an issue with the integrity or content of this feedback or it is inappropriate, let us know.
        Our team will perform an audit of the feedback and alert you of the resolution.
      </div>
      <div class="tooltip-text-section" data-cy={autoId + '-text-section'}>
        Please let us know why you are flagging this feedback
        <textarea class="tooltip-textarea textarea-flagged"
          bind:value={$feedbackTooltipStore.data.reason}
          data-cy={autoId + '-textarea'} readonly
          on:click={cancelClickEvent}
        ></textarea>
      </div>
      <div class="tooltip-buttons" data-cy={autoId + '-buttons'}>
        <div class="tooltip-button" on:click={(e) => hideTooltip()}>Cancel</div>
        <div class="tooltip-button is-disabled" on:click={cancelClickEvent}>Flag</div>
      </div>
    {/if}
  </div>
{/if}


<style lang="scss">

.feedback-tooltip {
    position: absolute;
    width: 430px;
    // height: 290px;   /* no height - keep stretchable */
    padding: 0;
    z-index: 9999;
    color: #212529;
    background-color: #ffffff;
    font-family: "Helvetica Neue", sans-serif;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    /* defaults to hidden */
    visibility: hidden;
    opacity: 0;
    /* scaling messes up getBoundingClientRect */
    transform: scale(1);
    transition: opacity .5s, transform .2s;
  }

  .tooltip-title {
    padding: 8px 15px;
    text-align: left;
    font-size: 16px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 500;
    line-height: 19px;
    color: #212529;
    background-color: var(--tattle-slideout-secondary-header-color);
    border-bottom: 1px solid #ebebeb;
  }

  .title-flagged {
    color: var(--tattle-flag-feedback-color);
  }

  .tooltip-content {
    padding: 15px;
    text-align: left;
    font-size: 14px;
    font-family: "Helvetica Neue", sans-serif;
    line-height: 21px;
    color: #212529;
  }

  .tooltip-text-section {
    width: 100%;
    padding: 15px 15px 0px 15px;
    text-align: left;
    font-size: 14px;
    line-height: 21px;
    color: #212529;
  }

  .tooltip-textarea {
    width: 100%;
    height: 80px;
    padding: 5px;
    margin-top: 5px;
    color: #000000;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    resize: vertical;
  }

  .textarea-flagged {
    background-color: #d9d9d9;
  }

  .tooltip-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    font-size: 14px;
    padding: 10px 15px;
    // border: 1px solid green;
  }

  .tooltip-button {
    color: #0577d1;
    margin-left: 16px;
    font-size: 14px;
    font-family: "Roboto";
    cursor: pointer;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: -.1px;
    // border: 1px solid red;
  }

  .is-disabled {
    color: #616c7f;
  }

  .is-showing {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }


</style>
