<script>
  import ContextMenu from 'tttl-core-ui/src/components/context-menu/ContextMenu.svelte';
  import ContextListItem from 'tttl-core-ui/src/components/context-menu/ContextListItem.svelte';
  import List from '@smui/list/styled';
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import {Label} from '@smui/button';
  import {createEventDispatcher} from 'svelte';
  import CircularProgress from '@smui/circular-progress';


  /***** reply-to-customer-button.svelte Component *****
   * Displays a dropdown button that allows a user to select how to reply to a questionnaire
   *
   ******Parameters ******************************************************************************
   * @param autoId (string) - The auto id for Cypress E2E testing
   * @param class {string} - Any classes to add to the component
   * @param dropdownItems {Array} - An array of objects that have the text property, used to generate the dropdown
   * @param select {Event} - Fired when an item is selected
   * @param inProgress {Boolean} - If true, disable clicking on the button and show a spinner
   */

  export let autoId = 'reply-to-customer-button';
  export {className as class};
  export let dropdownItems;
  export let inProgress;

  const dispatch = createEventDispatcher();

  let className = '';
  let replyToCustomerMenu;

</script>

<div class="button-wrapper">


  <Button
    autoId={autoId}
    type="raised"
    class={className}
    color="primary"
    disabled={inProgress}
    on:click={() => {
        if (!inProgress){
          replyToCustomerMenu.setOpen(true);
        }
      }}
  >

    <Label>
      <div class="label-contents">
        {#if !inProgress}
          <div data-cy="{autoId}-enabled">
            Reply to Customer
          </div>
          <div class="material-icons">expand_more</div>
        {/if}
        {#if inProgress}
          <CircularProgress style="height: 16px; width: 16px; margin-right: 5px;" indeterminate/>
          <div data-cy="{autoId}-in-progress">
            Sending message...
          </div>
        {/if}
      </div>
    </Label>
  </Button>

  <ContextMenu bind:menu={replyToCustomerMenu}>
    <List>
      {#each dropdownItems as item}
        <ContextListItem on:click={() => { dispatch('select', item)}}>
          {item.text}
        </ContextListItem>
      {/each}
    </List>
  </ContextMenu>
</div>


<style lang="scss">
  * :global(button) {
    border-radius: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: normal;
  }

  .label-contents {
    display: flex;
    align-items: center;
  }

  .button-wrapper{
    width:180px;
  }
</style>

