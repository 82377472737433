<script>
  import {getColorForRatingPercentage} from 'src/services/utility/colors';

  /***** survey-answer-detail Component *****
   * Description goes here
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   */

  export let autoId = 'survey-answer-detail';
  export {className as class};
  export let answer;

  let className = '';
  let iconName;
  let ratingColor;


  $: iconName = answer?.weight > 50 ? 'thumb_up' : 'thumb_down';
  $: ratingColor = getColorForRatingPercentage(answer?.weight || 0)

</script>

<div class="survey-answer-detail {className}" data-cy={autoId} style="--survey-answer-rating-color: {ratingColor};">
  <div class="question-wrapper">
    <div class="flex-left-wrapper">
      <div class="question-answer">
        <!--{#if !answer.length}-->
          <span class="question">
            {answer.question}
          </span>
        <!--{/if}
        {#if answer.length > 0}
          <span class="question">
            {answer[0].question}
          </span>
        {/if}-->
      </div>
    </div>

    <div class="flex-right-wrapper">
      <div class="rating">
        {#if answer.weightless === 0 && answer.field_type !== 'textarea'}
          <div class="material-icons round-icon ${iconName} thumb-icon">{iconName}</div>
          <div class="answer-percentage">
            {answer.weight}%
          </div>
        {/if}
      </div>
    </div>
  </div>
  <!--{#if !answer.length}-->
    <div class="answer-wrapper">
      <span class="answer">
        {answer.answer}
      </span>
    </div>
  <!--{{/if}-->

  <!--{#if answer.length > 0}
    {#each answer as answer}
      <div class="answer-wrapper">
        <span class="answer">
          {answer.answer}
        </span>
      </div>
    {/each}
  {/if}-->

</div>


<style lang="scss">

.question-wrapper {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.answer-wrapper {
 padding-left: 25px;
 padding-bottom: 24px;
}

.survey-answer-detail {
 min-height: 60px;
 border-color: rgba(127, 135, 152, 0.5);
 border-radius: 9px;
 border-width: 1px;
 border-style: solid;
 margin-bottom: 15px;
}

.rating > div {
 display: inline-block;
}

.flex-left-wrapper {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 padding-left: 25px;
 padding-top: 25px;
 padding-bottom: 15px;
}

.flex-right-wrapper {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 padding-right: 25px;
 padding-top: 15px;
 min-width: 120px;
}

.question {
 font-size: 16px;
 font-weight: 500;
 color: black;
}

.answer {
 color: #616c7f;
 font-weight: 500;
 font-size: 14px;
}

.answer-percentage {
 width: 35px;
 text-align: right;
 font-size: 16px;
 color: var(--survey-answer-rating-color, black);
 height: 30px;
 vertical-align: middle;
 font-weight: bold;
}

.thumb-icon {
 font-size: 20px;
 background-color: #cccccc;
 border-radius: 50%;
 padding: 5px;
 color: var(--survey-answer-rating-color, black);
}
</style>

