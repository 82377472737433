<script>

  import Card, { Content as CardContent, Media } from "@smui/card";

  import Button from "tttl-core-ui/src/components/Button.svelte";
  import { Label } from "@smui/data-table/styled";
  import ToastSuccess from 'src/building-blocks/toast-success.svelte';
  import ToastError from 'src/building-blocks/toast-error.svelte';
  import { toastOptions, toastSuccessTheme, toastErrorTheme } from 'src/building-blocks/toast-constants.js';
  import {SvelteToast, toast} from "@zerodevx/svelte-toast";
  import ConfirmModal from './confirm-modal.svelte';
  import {broadcastEvent} from 'src/services/utility/angularScopeUtility';
  import { getReviewTrackersStatus, activate, deactivate } from "../../services/integrationsEngineApi/merchantPreferences";

  let showConfirmModal = false
  let inProgress = false


  function getReviewTrackersLogo() {
    return `https://${window.BRANDS_BUCKET}.s3.amazonaws.com/integrations/logos/reviewtrackers.png`;
  }


  export let isActive;
  export let merchantId;

  async function callActivate() {
    inProgress = true
    const result = await activate(merchantId)
    if(result.ok) {
      showToastSuccess('ReviewTrackers activated successfully')
      broadcastEvent("reviewTrackerPreferenceChanged", "publicReviewsConfig");
      isActive = true
      inProgress = false
    } else {
      const errorResponse = await result.json()
      inProgress = false
      showToastError(errorResponse.message)
    }
  }

  async function callDeactivate() {
    inProgress = true
    const result = await deactivate(merchantId);
    if(result.ok) {
      showToastSuccess('ReviewTrackers deactivated successfully')
      broadcastEvent("reviewTrackerPreferenceChanged", "publicReviewsConfig");
      isActive = false
      inProgress = false
    } else {
      const errorResponse = await result.json()
      inProgress = false
      showToastError()
    }
  }
  const showToastSuccess = (toastMessage) => {
    toast.push({
      ...toastOptions,
      theme: toastSuccessTheme,
      component: {
        src: ToastSuccess,
        props: {
          message: toastMessage,
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }

  const showToastError = () => {
    toast.push({
      ...toastOptions,
      theme: toastErrorTheme,
      component: {
        src: ToastError,
        props: {
          message: "",
          onClose: () => {
            toast.pop();
          }
        }
      }
    });
  }
</script>

<div class="card-display">
  <div class="card-container">
    <Card data-cy="review-trackers-card" class="review-trackers-card">
      <div class="card-header">
        <h4><b>ReviewTrackers</b></h4>
        {#if isActive}
        <div class="statusLabel">
          Active
        </div>
        {/if}
      </div>
      <Media
        data-cy="review-trackers-logo"
        class="review-trackers-img"
        style="--imgsrc: url('{getReviewTrackersLogo()}');"
        aspectRatio="16x9"
      />
      <CardContent>
        {#if isActive}
          <Button
          autoId="review-trackers-deactivate-button"
          color="secondary"
          type="outlined"
          disabled={inProgress}
          on:click={() => showConfirmModal = true}
          >
            <Label> Deactivate </Label>
          </Button>
        {:else}
          <Button
            autoId="review-trackers-activate-button"
            color="primary"
            type="raised"
            disabled={inProgress}
            on:click={() => showConfirmModal = true}
          >
            <Label> Activate </Label>
          </Button>
        {/if}
      </CardContent>
    </Card>
  </div>

  <div class="toast-wrapper"><SvelteToast /></div>
</div>

<ConfirmModal bind:show={showConfirmModal} merchantId={merchantId} isActive={isActive} on:actionActivate={() => callActivate()} on:actionDeactivate={() => callDeactivate()}/>


<style global lang="scss">
.review-trackers-card {
  max-width: 300px;
  --mdc-theme-primary: #7B89AC !important;
  --mdc-theme-secondary: #7B89AC !important;
  --mdc-theme-on-secondary: #7B89AC !important;


  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
  }
  .smui-card__content {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  .statusLabel {
    font-size: 9px;
    text-transform: capitalize;
    font-weight: 800;
    line-height: 11px;
    background-color: #88B861;
    color: #ffffff;
    border-radius: 24px;
    padding: 0.5rem 1.5rem;
  }

  .mdc-button {
    padding: 8px 32px;
    border-radius: 6px;
    font-size: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--mdc-theme-primary);
    height: 30px;
    font-size: 14px;
    font-weight: normal;

  }

}

.review-trackers-img {
  background-image: var(--imgsrc);
  background-color: var(--backgroundColor, white);
  background-size: contain !important;
  @media (min-width: 1000px) {
    padding: 0rem 3rem;
  }
  background-origin: content-box;
  border-radius: 4px;
}

</style>
