import {headers, baseURL} from 'src/services/auth';

/**
 * @typedef ScoresAndTotalsOptionalParameters
 * @property locationIds {Number[]} -  A list of inclusive location id's to filter on
 * @property questionnaireIds {Number[]} -  A list of inclusive questionnaire id's to filter on
 * @property groupIds {Number[]} -  A list of inclusive group id's to filter on
 * @property shiftStart {String} -  The starting shift to filter on, given in 24 hour time (ex: "15:00")
 * @property shiftEnd {String} -  The ending shift to filter on, given in 24 hour time (ex: "15:00")
 * @property dateField {String} - Overrides the date field to search on, by default the backend uses date_time_experienced
 */

/**
 *
 * @param merchantsId {Number} - The id representing the merchant
 * @param timePeriod {("year", "quarter", "month", "week", null)} - The type of time to bucket the results in
 * @param startDate {DateTime} - A luxon date object representing the start of the date range
 * @param endDate {DateTime} - A luxon date object representing the end of the date range
 * @param optionalParameters {ScoresAndTotalsOptionalParameters} - Contains additional optional filtering
 * @returns {Promise<any>}
 */
export function getScoresAndTotalsByTimePeriod(merchantsId, timePeriod, startDate, endDate, optionalParameters) {
  'use strict';


  if (!optionalParameters){
    optionalParameters = {};
  }

  const dateFormat = 'yyyy-LL-dd';
  const formattedStartDate = startDate.toFormat(dateFormat);
  const formattedEndDate = endDate.toFormat(dateFormat);
  let urlString = `${baseURL}/v2/api/data/tattles/scores-and-totals?publish_cycle=IN:0,1&merchants_id=${merchantsId}&start=${formattedStartDate}&end=${formattedEndDate}`;

  if (timePeriod) {
    urlString += `&group_by_date=${timePeriod}`;
  }
  if (optionalParameters.locationIds && optionalParameters.locationIds.length > 0) {
    urlString += `&location_ids=${optionalParameters.locationIds.join(',')}`;
    urlString += `&locations_id=IN:${optionalParameters.locationIds.join(',')}`;
  }
  if (optionalParameters.questionnaireIds && optionalParameters.questionnaireIds.length > 0) {
    urlString += `&questionnaire_id=IN:${optionalParameters.questionnaireIds.join(',')}`;
  }
  if (optionalParameters.groupIds && optionalParameters.groupIds.length > 0) {
    urlString += `&group_ids=${optionalParameters.groupIds.join(',')}`;
    urlString += `&groups_id=IN:${optionalParameters.groupIds.join(',')}`;
  }
  if (optionalParameters.shiftStart && optionalParameters.shiftEnd){
    urlString += `&shift_start=${optionalParameters.shiftStart}&shift_end=${optionalParameters.shiftEnd}`;
  }
  if (optionalParameters.dateField) {
    urlString += `&date_field=${optionalParameters.dateField}`;
  }

  //TODO: This should be genericized and pulled out to a common place
  if ((merchantsId === 2148 || merchantsId === 2361 || merchantsId === 2371)){
    const proxyHeaders = Object.assign({}, headers);
    proxyHeaders.UnverifiedMerchantId = merchantsId;
    proxyHeaders['Content-Type'] = 'application/json';

    return fetch(`${baseURL}/v2/api/request-proxy`, {
      method: 'POST',
      headers: proxyHeaders,
      body: JSON.stringify({
        url: urlString
      })
    }).then(result => result.json())
      .then(result => result.data);
  }

  return fetch(urlString, {
    method: 'GET',
    headers: headers,
  }).then(result => result.json())
    .then(result => result.data);
}
