<script>
  import Dialog, {Actions, Title, Header, Content} from '@smui/dialog';
  import {Label} from '@smui/button';
  import Button from 'tttl-core-ui/src/components/Button.svelte';
  import { createEventDispatcher } from 'svelte';

  export let show = false;
  export let isActive;
  export {className as class};

  let className = '';

  const dispatch = createEventDispatcher();

</script>

<Dialog class="confirm-modal-public-reviews {className}" data-cy='confirm-modal-public-reviews' bind:open={show}
        fullscreen>

  <Header>
    {#if isActive}
      <Title tabindex="0" id="fullscreen-title">Deactivate ReviewTrackers</Title>
    {:else}
      <Title tabindex="0" id="fullscreen-title">Activate ReviewTrackers</Title>
    {/if}
  </Header>

  {#if isActive}
    <Content style="text-align: left;">Are you sure you want to deactivate Review Trackers for this customer?</Content>
  {:else}
    <Content style="text-align: left;">Please be sure the customer has signed up for Review Trackers before activating. Are you sure you want to activate Review Trackers for this customer?</Content>
  {/if}

  <Actions>
    <Button type="outlined" color="secondary" action="close"><Label>Cancel</Label></Button>
    {#if isActive}
      <Button type="raised" color="primary" on:click={() => dispatch('actionDeactivate')}><Label>Confirm</Label></Button>
    {:else}
      <Button type="raised" color="primary" on:click={() => dispatch('actionActivate')}><Label>Confirm</Label></Button>
    {/if}
  </Actions>

</Dialog>

<style lang="scss">
  :global(.confirm-modal-public-reviews .mdc-dialog__header) {
    margin-bottom: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecef;
  }

  :global(.confirm-modal-public-reviews .mdc-dialog__content) {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 14px;
  }

  :global(.confirm-modal-public-reviews .mdc-dialog__container) {
    margin-top: 60px;
  }

  :global(.confirm-modal-public-reviews .mdc-dialog__title) {
    font-size: 16px;
  }

  :global(.confirm-modal-public-reviews.mdc-dialog .mdc-dialog__surface) {
    max-width: 650px;
    max-height: calc(100% - 70px);
  }

  :global(.confirm-modal-public-reviews  .mdc-dialog__actions) {
    border-top-color: #e9ecef;
    display: flex;
    gap: 0.5rem; 

    --mdc-theme-primary: #7B89AC !important;
    --mdc-theme-secondary: #7B89AC !important;
    --mdc-theme-on-secondary: #7B89AC !important;

    .mdc-button {
      padding: 8px 32px;
      border-radius: 6px;
      font-size: 16px;
      border-style: solid;
      border-width: 1px;
    }
  }
</style>