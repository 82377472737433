import { writable } from 'svelte/store';

/*
    To show or hide, use the feedbackTooltipStore with a payload of:

    anchorElem: target,
    isVisible: true,
    data: {
      messageType: row.message_type,
      htmlContent: row.message,
      dateSent: row.month_date_sent
    }

*/
export const replyTooltipStore = writable({anchorElem: null, isVisible: false, data: null});
