<script>
  import Card, { Actions, Content } from "@smui/card";
  import Button from "tttl-core-ui/src/components/Button.svelte";
  import { Head, Label } from "@smui/data-table/styled";
  import { onMount } from "svelte";
  import {
    getIntegrations,
    addCovaIntegration,
    deactivateCovaIntegration,
  } from "src/services/integrationsEngineApi/integrations";
  import Textfield from "@smui/textfield";
  import IconButton, { Icon } from '@smui/icon-button';
  import HelperText from "@smui/textfield/helper-text";
  import CircularProgress from "@smui/circular-progress";
  import covaLocations from "./covaLocations.svelte";
  import Snackbar from "@smui/snackbar";
  import LayoutGrid, { Cell as LayoutCell } from "@smui/layout-grid";
  import ConfirmationModal from 'src/building-blocks/confirmation-modal.svelte';

  export let merchantId;

  let loading = true;
  let snackBar;
  let snackBarClass;
  let snackBarMessage;
  let isEditingIntegration = false;
  let integrationDetails = {};

  let formData = {
    username: "",
    password: "",
    viewPassword: false,
    clientId: "",
    clientSecret: "",
    viewClientSecret: false,
    companyId: "",
  };

  function showSnackbarSuccess(create = false) {
    snackBarClass = "integrationSuccessSurface";
    snackBarMessage = "Integration successfully deactivated!";
    if(create) {
      snackBarMessage = "Integration successfully created!";
    }
    snackBar.open();
  }

  function showSnackbarError() {
    snackBarClass = "integrationErrorSurface";
    snackBarMessage = "Error encountered while adding Integration";
    snackBar.open();
  }

  onMount(() => {
    initData();
  });

  function initData() {
    getIntegrations(merchantId).then((results) => {
      integrationDetails = results.covaIntegration;
      loading = false;
      if (!integrationDetails?.isActive) {
        isEditingIntegration = true;
      }
    });
  }

  function handleIntegrationDeactivate(isActionConfirmed) {
    if(!isActionConfirmed)
      return;
    loading = true;
    return deactivateCovaIntegration(merchantId).then(
      (results) => {
        if (results?.status > 300) {
          showSnackbarError();
        } else {
          loading = false;
          showSnackbarSuccess();
          resetForm();
          initData();
        }
      }
    );
  }

  function handleIntegrationSave() {
    return addCovaIntegration(merchantId, formData).then(
      (results) => {
        if (results?.status > 300) {
          showSnackbarError();
        } else {
          showSnackbarSuccess(true);
          resetForm();
          initData();
        }
      }
    );
  }

  function resetForm() {
    isEditingIntegration = false;
    formData = {
      username: "",
      password: "",
      viewPassword: false,
      clientId: "",
      clientSecret: "",
      viewClientSecret: false,
      companyId: "",
    };
  }

  let confirmationModalText;
  let confirmationModalIsVisible;
  function showDeactivateConfirmationModal(){
    confirmationModalText = "Are you sure you want to deactivate this integration?";
    confirmationModalIsVisible = true;
  }
</script>

<Card class="integrationsListCard">
  <Head class="cardHeader">
    <LayoutGrid class="headerGrid">
      <LayoutCell span={9}>
        <h1 data-cy="integrations-heading">Cova</h1>
      </LayoutCell>
      <LayoutCell span={3} class="text-right">
        {#if !isEditingIntegration}
          <div class="m-t-lg m-r-lg">
            <Button
              autoId="integrations-deactivate-integration-button"
              type="outlined"
              on:click={() => showDeactivateConfirmationModal()}
            >
              <Label>
                <div class="label-contents">
                  <div>Deactivate</div>
                </div>
              </Label>
            </Button>
          </div>
        {/if}
      </LayoutCell>
    </LayoutGrid>
    <hr />
  </Head>
  {#if loading}
    <CircularProgress
      style="height: 64px; width: 64px; margin-top: 150px; margin-bottom: 150px; left: 50%;"
      indeterminate
    />
  {:else}
    <LayoutGrid class="m-none w-100" align="left">
      {#if isEditingIntegration}
        <LayoutCell span={6}>
          <Textfield
            data-cy="integrations-username-text-field"
            input$data-cy="integrations-username-text-input"
            variant="outlined"
            bind:value={formData.username}
            label="Username"
            class="inputTextField"
            input$autocomplete="off"
          />
        </LayoutCell>
        <LayoutCell span={6}>
          <Textfield
            data-cy="integrations-password-text-field"
            input$data-cy="integrations-password-text-input"
            variant="outlined"
            type={formData.viewPassword ? 'text' : 'password'}
            bind:value={formData.password}
            label="Password"
            class="inputTextField"
            input$autocomplete="off"
          >
          <IconButton slot="trailingIcon" on:click={() => formData.viewPassword = !formData.viewPassword} pressed={formData.viewPassword} disabled={!formData.password}>
            {#if formData.password}
              <Icon class="material-icons-outlined" on>visibility</Icon>
              <Icon class="material-icons-outlined">visibility_off</Icon>
            {/if}
          </IconButton>
          </Textfield>
        </LayoutCell>
        <LayoutCell span={6}>
          <Textfield
            data-cy="integrations-client-id-text-field"
            input$data-cy="integrations-client-id-text-input"
            variant="outlined"
            bind:value={formData.clientId}
            label="Client ID"
            class="inputTextField"
            input$autocomplete="off"
          />
        </LayoutCell>
        <LayoutCell span={6}>
          <Textfield
            data-cy="integrations-client-secret-text-field"
            input$data-cy="integrations-client-secret-text-input"
            variant="outlined"
            type={formData.viewClientSecret ? 'text' : 'password'}
            bind:value={formData.clientSecret}
            label="Client Secret"
            class="inputTextField"
            input$autocomplete="off"
          >
          <IconButton slot="trailingIcon" on:click={() => formData.viewClientSecret = !formData.viewClientSecret} pressed={formData.viewClientSecret} disabled={!formData.clientSecret}>
            {#if formData.clientSecret}
              <Icon class="material-icons-outlined" on>visibility</Icon>
              <Icon class="material-icons-outlined">visibility_off</Icon>
            {/if}
          </IconButton>
          </Textfield>
        </LayoutCell>
        <LayoutCell span={6}>
          <Textfield
            data-cy="integrations-company-id-text-field"
            input$data-cy="integrations-company-id-text-input"
            variant="outlined"
            bind:value={formData.companyId}
            label="Company ID"
            class="inputTextField"
            input$autocomplete="off"
          />
        </LayoutCell>
        <LayoutCell class="action-buttons" span={12}>
          <div>
            <Button
              autoId="integrations-form-save-button"
              type="raised"
              color="primary"
              on:click={() => handleIntegrationSave()}
            >
              <Label>Save</Label>
            </Button>
          </div>
        </LayoutCell>
      {:else}
        {#if integrationDetails}
          <LayoutCell span={12}>
            <svelte:component
              this={covaLocations}
              {merchantId}
              integrationLocationsList={integrationDetails.locations}
              companyId={integrationDetails.companyId}
            />
          </LayoutCell>
        {:else}
        <div class="no-messages m-t-lg" data-cy="no-manager-replies">
          <div class="no-messages-wrapper">
            <div class="no-messages-title">
              <span>This merchant has no integrations</span>
            </div>
          </div>
        </div>
        {/if}
      {/if}
    </LayoutGrid>
  {/if}
</Card>
<Snackbar
  bind:this={snackBar}
  surface$data-cy="integrations-snackbar"
  surface$class={snackBarClass}
>
  <Label>{snackBarMessage}</Label>
</Snackbar>

<ConfirmationModal autoId="integrations-confirmation-deactivate" bind:show={confirmationModalIsVisible} message={confirmationModalText} resultHandler={handleIntegrationDeactivate}/>

<style lang="scss">
  :global(.integrationsListCard .integrationsContent) {
    padding-top: 0px;
  }
  :global(.integrationsListCard .cardHeader) {
    display: flex;
    padding: 8px;
  }
  :global(.integrationsListCard .cardHeader > h1) {
    flex: 1;
    margin-left: 8px;
  }
  :global(.integrationsListCard .searchBox) {
    margin-left: auto;
    margin-right: 8px;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  :global(.integrationsListCard .mdc-floating-label) {
    font-size: 12pt !important;
    height: 24px;
    top: 60%;
  }
  :global(.integrationsListCard .unsortableHeaderCell) {
    cursor: default;
  }
  :global(.integrationsListCard .addMggButtonsCell) {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;

    @media (max-width: 1320px) {
      float: right;
    }
  }

  :global(.integrationsListCard .integrationRow > td) {
    border-width: 1px !important;
  }
  :global(.integrationsListCard .align-left) {
    text-align: left !important;
    padding-left: 8px !important;
  }
  :global(.integrationsListCard .mdc-data-table__sort-icon-button) {
    opacity: 1;
  }
  :global(.integrationsListCard .actionLabel) {
    font-size: 12pt;
  }
  :global(.integrationSuccessSurface) {
    background-color: var(--tattle-success-color);
  }
  :global(.mdc-snackbar__label) {
    font-size: 12pt;
  }
  :global(.integrationErrorSurface) {
    background-color: var(--tattle-fail-color);
  }
  :global(.inputTextField) {
    width: 100%;
  }
  :global(.integrationsListCard .mdc-text-field__input) {
    font-size: 12pt;
  }

  :global(.integrationsListCard .integrationSwitchCol) {
    width: 100px;
  }

  :global(.integrationsListCard .integrationActionsCol) {
    width: 200px;
  }

  :global(.integrationsListCard .headerGrid) {
    margin: 0;
    padding: 0;
    width: 100%;
    padding-left: 8px;
  }

  :global(.helper-error) {
    color: var(--mdc-theme-error) !important;
  }

  :global(.integrationsListCard .mdc-floating-label) {
    line-height: 1.3rem;
  }

  :global(.integrationsListCard .action-buttons) {
    display: flex;
    justify-content: end;
  }

  :global(.w-100) {
    width: 100%;
  }
</style>
