<script>
    import {Pagination} from '@smui/data-table/styled';
    import {Set} from '@smui/chips';
    import Chip from './Chip.svelte';

    /***** Pagination.svelte Component *****
     * A pagination component for data tables
     *
     ******Parameters ******************************************************************************
     * @param lastPage (number) -  A zero based index of the last page (if there's 30 pages, this number would be 29)
     * @param currentPage (number) - A zero based index of the current page being viewed, this needs to be bound in order for parent components to listen to the changes
     * @param totalRecordCount (number) - The total number of records in the table
     * @param class (string) - Any classes that need to be assigned to the component
     */

    // A zero based index of the last page (if there's 30 pages, this number would be 29)
    export let lastPage = 0;
    // A zero based index of the current page being viewed, this needs to be bound in order for parent components to listen to the changes
    export let currentPage = 0;
    // The total number of records in the table
    export let totalRecordCount = 0;
    // Any classes that need to be assigned to the component
    export {className as class};

    let className;

    // Contains a simple list of all the page numbers (starts with 1, not 0)
    let pageArray = [];
    // 1 based index of which chip is currently selected
    let selectedChip = 1;

    $: {
        const tempArray = [];
        for (let i = 0; i <= lastPage; ++i) {
            tempArray.push(i + 1);
        }
        if (tempArray.length === 0) {
            tempArray.push(1);
        }
        pageArray = tempArray;
    }
    $: {
        selectedChip = currentPage + 1;
    }
</script>

<div class={className}>
    <Pagination slot="paginate" class="paginator">
        <Set chips={['<']} let:chip>
            {#if chip !== null && chip !== undefined}
                <Chip {chip}  on:click={() => { if(currentPage !== 0) currentPage -= 1}} disabled={currentPage === 0}>
                    {chip}
                </Chip>
            {/if}
        </Set>

        {#if lastPage >= 0 && pageArray.length > 0}
            <Set chips={pageArray} let:chip bind:selected={selectedChip} choice>
                {#if chip !== null && chip !== undefined}
                    <Chip chip={chip} on:click={() => {currentPage = chip - 1}}  selected={chip - 1 === currentPage}>
                        {chip}
                    </Chip>
                {/if}
            </Set>
        {/if}

        <Set chips={['>']} let:chip>
            {#if chip !== null && chip !== undefined}
                <Chip {chip} on:click={() => {if(currentPage !== lastPage) currentPage += 1}}  disabled={currentPage === lastPage}>
                    {chip}
                </Chip>
            {/if}
        </Set>

        <div class="page-summary">
            {currentPage + 1} of {lastPage + 1} of {totalRecordCount} Results
        </div>

    </Pagination>
</div>

<style lang="scss">
  // Pagination styles
  * :global(.paginator .mdc-chip) {
    user-select: none;
    background-color: transparent;
  }

  * :global(.paginator .page-summary) {
    padding-right: 20px;
  }
</style>