<script>
    import Drawer, {
        Content,
        Header,
        Scrim,
    } from '@smui/drawer';

    /***** QuickPeek.svelte Component *****
     * Displays a slide out drawer on the right side of the screen
     *
     ******Parameters ******************************************************************************
     * @param open (boolean) - When set to true, opens drawer, when false, drawer is closed
     * @param autoId (string) - The auto id for Cypress E2E testing
     * @param class {string} - Any classes to add to the component
     */
    export let open = false;
    export let autoId;
    export {className as class};

    let className;
</script>

<!--Set it to the right side-->
<!--Reset the text align to left since rtl forces it to the right-->
<div class="drawer-container {className}" data-cy={autoId}>
    <Drawer variant="modal" bind:open fullScreen={true}>
        <Header>
            <slot name="title"/>
        </Header>
        <!-- Tab index is needed to not break the drawer on focus call-->
        <Content tabindex="0">
            <slot name="content"/>
        </Content>
    </Drawer>
    <Scrim/>
</div>


<style lang="scss">
  .drawer-container :global(aside.mdc-drawer.mdc-drawer--modal.mdc-drawer--open) {
    top: 50px;
    width: 60%;
    padding-bottom: 50px;
	z-index: 1002;
  }
  .drawer-container :global(.mdc-drawer-scrim) {
	  z-index: 1001;
	  }

  //Overrides so it goes on the right side
  * :global(.mdc-drawer--modal) {
    left: initial;
    right: 0;
  }

  * :global(.mdc-drawer) {
    border-right-width: 0 !important;
    border-left-width: 1px !important;
    border-right-style: none !important;
    border-left-style: solid !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: var(--mdc-shape-large, 0) !important;
  }

  * :global(.mdc-drawer--animate) {
    transform: translateX(100%) !important;
  }
  * :global(.mdc-drawer--opening) {
    transform: translateX(0) !important;
  }
  * :global(.mdc-drawer--closing) {
    transform: translateX(100%) !important;
  }
</style>
