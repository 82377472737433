<script>
  import Tab, {Label} from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import VerticalResizer from 'src/building-blocks/vertical-resizer.svelte';
  import ManagerRepliesTable
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/manager-replies-and-rewards/manager-replies-table.svelte';
  import RewardsTable
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/manager-replies-and-rewards/rewards-table.svelte';
  import PreviousFeedbackTable
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/manager-replies-and-rewards/previous-feedback-table.svelte';
  import ContextMenu from 'tttl-core-ui/src/components/context-menu/ContextMenu.svelte';
  import ContextListItem from 'tttl-core-ui/src/components/context-menu/ContextListItem.svelte';
  import List from '@smui/list/styled';
  import Button from 'tttl-core-ui/src/components/Button.svelte';

  /***** manager-replies-rewards-details Component *****
   * Contains both the manager replies and the reward details table for a questionnaire.
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param address {Object} - The object returned from the address endpoint
   * @param incident {Object} - The object returned from the incident endpoint, this is optional
   * @param customerRewards {Array[]} An array of objects from the customer rewards endpoint with rewards expanded
   * @param filteredMerchantSurveys {Array} - An array containing all the customer's surveys for this merchant minus flagged feedback
   * @param flaggedSurveys {Array} - An array containing all the flagged customer's surveys for this merchant
   * @param surveyId {String} - The id for the current survey the drawer is open to
   */

  export let autoId = 'manager-replies-rewards-details';
  export {className as class};
  export let messageLogs;
  export let address;
  export let incident;
  export let customerRewards;
  export let filteredMerchantSurveys;
  export let flaggedSurveys;
  export let surveyId;

  let className = '';
  let resizeMenu;
  let repliesHeaderElement;
  let showButtonElement;

  let tabs = ['Previous Feedback', 'Manager Replies', 'Reward Details'];
  let activeTab = tabs[0];

  // 155 is about 2 and change rows
  //Keep track of the heights for each tab
  let tabHeights = {
    'Manager Replies': {
      height: 155,
      minHeight: 155,
      maxHeight: 155
    },
    'Previous Feedback': {
      height: 155,
      minHeight: 155,
      maxHeight: 155
    },
    'Reward Details': {
      height: 155,
      minHeight: 155,
      maxHeight: 155
    }
  };

  let tableElement;
  //The total number of records for each tab
  let tabRowCounts = {
    'Previous Feedback': 0,
    'Manager Replies': 0,
    'Reward Details': 0
  };

  let selectedRowCounts = {
    'Previous Feedback': 2,
    'Manager Replies': 2,
    'Reward Details': 2
  };


  $: {
    if (tableElement && activeTab) {
      const activeHeights = tabHeights[activeTab];
      //Need the plus 40 from the padding
      activeHeights.maxHeight = Math.max(tableElement.getBoundingClientRect().height + 40, activeHeights.minHeight);
      tabHeights = tabHeights;
    }
  }

  $:{
    tabRowCounts['Previous Feedback'] = filteredMerchantSurveys?.length - 1 || 0;
    tabRowCounts['Previous Feedback'] = tabRowCounts['Previous Feedback'] < 0 ? 0 : tabRowCounts['Previous Feedback'];
    tabRowCounts['Manager Replies'] = messageLogs?.length || 0;
    tabRowCounts['Reward Details'] = customerRewards?.length || 0;
    tabRowCounts = tabRowCounts;
  }

  function pageSizeChanged(pageSize) {
    selectedRowCounts[activeTab] = pageSize;

    if (pageSize === 'All') {
      tabHeights[activeTab].height = tabHeights[activeTab].maxHeight;
    } else {
      const activeHeights = tabHeights[activeTab];
      activeHeights.height = Math.min(30 + pageSize * 50, activeHeights.maxHeight);
    }
    tabHeights = tabHeights;

    const currentScrollOffset = repliesHeaderElement.closest('.mdc-drawer__content').scrollTop;
    const currentButtonOffset = showButtonElement.offsetTop;
    window.requestAnimationFrame(() => {
      if (showButtonElement.offsetTop < currentScrollOffset) {
        // If the user is going from a large set of records to a small number, keep the scroll offset from the button
        repliesHeaderElement.closest('.mdc-drawer__content').scrollTop = (currentScrollOffset - currentButtonOffset) + showButtonElement.offsetTop;
      } else {
        repliesHeaderElement.closest('.mdc-drawer__content').scrollTop = currentScrollOffset;
      }
    });
  }

</script>

<div class="{className}" data-cy={autoId}>
  <div class="replies-header" bind:this={repliesHeaderElement}>
    <TabBar tabs={tabs} let:tab bind:active={activeTab}>
      <Tab {tab} minWidth>
        <Label>{`${tab} (${tabRowCounts[tab]})`}</Label>
      </Tab>
    </TabBar>
  </div>

  <VerticalResizer
    minHeight={tabHeights[activeTab].minHeight}
    maxHeight={tabHeights[activeTab].maxHeight}
    bind:height={tabHeights[activeTab].height}
    isInteractive={false}>

    <div class="tabs-content-wrapper">
      {#if activeTab === 'Previous Feedback'}
        <PreviousFeedbackTable {address} {filteredMerchantSurveys} {surveyId} bind:element={tableElement}/>
      {/if}
      {#if activeTab === 'Manager Replies'}
        <ManagerRepliesTable {messageLogs} {address} {incident} {filteredMerchantSurveys} bind:element={tableElement}
                             {flaggedSurveys}/>
      {/if}
      {#if activeTab === 'Reward Details'}
        <RewardsTable {customerRewards} {address} {filteredMerchantSurveys} {flaggedSurveys}
                      bind:element={tableElement}/>
      {/if}
    </div>
  </VerticalResizer>
  <div class="resizer-select-wrapper" bind:this={showButtonElement}>
    {#if tabRowCounts[activeTab] > 2}
      <Button
        autoId="page-size-selector"
        type="raised"
        color="secondary"
        on:click={() => {
        resizeMenu.setOpen(true);
      }}
      >

        <Label>
          <div class="show-button-contents">
            <div>
              Show {selectedRowCounts[activeTab]}
            </div>
            <div class="material-icons">arrow_drop_down</div>
          </div>
        </Label>
      </Button>

      <ContextMenu bind:menu={resizeMenu}>
        <List>
          {#each Array(Math.floor(tabRowCounts[activeTab]/5)) as _, i}
            <ContextListItem on:click={() => pageSizeChanged(i*5+5)}>
              <span data-cy="show-{i*5 + 5}-items">
                {i*5+5}
              </span>
            </ContextListItem>
          {/each}
          <ContextListItem on:click={() => pageSizeChanged('All')} data-cy="show-all-item">
            <span data-cy="show-all-items">
              All
            </span>
          </ContextListItem>
        </List>
      </ContextMenu>
    {/if}

  </div>
</div>

<style lang="scss">
  .replies-header {
    display: flex;
    align-items: center;
  }

  .tabs-content-wrapper {
    padding-top: 20px;
  }

  .resizer-select-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 10px;
  }

  .show-button-contents {
    display: flex;
    align-items: center;
  }

  // This should all be moved into a reusable table component in core ui
  * :global(.mdc-tab__text-label) {
    font-size: 14px;
  }

  * :global(.mdc-tab__content) {
    padding-left: 35px;
    padding-right: 35px;
  }

  * :global(.mdc-tab-indicator .mdc-tab-indicator__content--underline) {
    border-top-width: 4px;
  }

  * :global(.mdc-tab-scroller__scroll-content) {
    border-bottom-color: #ccc;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  * :global(tbody .mdc-data-table__row) {
    border: none !important;
  }

  * :global(.header-label.mdc-data-table__header-cell-label) {
    font-size: 12px !important;
  }

  * :global(.mdc-data-table__cell) {
    font-size: 14px !important;
  }

</style>

