<script>
  import {onDestroy, onMount} from 'svelte';

  /***** vertical-resizer Component *****
   * A component that wraps content and allows the user to manually resize it
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param slot {SvelteSlots} - The content that is resizeable
   * @param height {Number} - The current height in pixels, note that this can be a two way bind
   * @param maxHeight {Number} - The maximum height in pixels
   * @param minHeight {Number} - The minimum height in pixels
   * @param isInteractive {Boolean} - If true, the user can drag the resizer
   */

  export let autoId = 'vertical-resizer';
  export {className as class};

  let className = '';

  export let maxHeight = 600;
  export let minHeight = 155;
  export let height = 155;
  export let isInteractive = true;

  let resizeWrapperElement;
  let resizeHandlerElement;
  let isInteracting = false;
  let resizeButtonElement;

  // I'm using an observer here rather than using the "svelte" way because waiting for the svelte digest causes some
  // UI hiccups,
  const observer = new MutationObserver(updateResizerButton);

  onMount(() => {
    updateResizerButton();
    observer.observe(resizeWrapperElement, {childList: false, attributes: true, subtree: false});

    //This is hacky, but there's an intermittent bug where the starting size isn't set right when the drawer is first loaded
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        updateResizerButton();
      });
    }, 200)
  });

  onDestroy(() => {
    observer?.disconnect();
  });

  $: {
    if (minHeight && maxHeight && height && resizeWrapperElement && resizeButtonElement) {
      updateResizerButton();
    }
  }

  function mouseDownHandler() {
    if (!isInteractive) {
      return;
    }
    isInteracting = true;
  }

  function mouseUpHandler() {
    if (!isInteractive) {
      return;
    }
    isInteracting = false;
  }

  function updateResizerButton() {
    if (resizeHandlerElement && resizeButtonElement) {
      const resizeButtonTop = resizeHandlerElement.offsetTop - 7;
      resizeButtonElement.style.top = `${resizeButtonTop}px`;
    }
  }

  function moveHandler(event) {
    if (!isInteracting || !isInteractive) {
      return false;
    }

    let cursorY;

    // Mobile touch events are handled slightly differently
    if (event.touches) {
      cursorY = event.touches[0].clientY;
      event.preventDefault();
    } else {
      cursorY = event.clientY;
    }


    const calculatedHeight = cursorY - resizeWrapperElement.getBoundingClientRect().y;

    //Need to handle if the previous tab was larger, so don't auto jump to max, let them do it themselves
    if (calculatedHeight > maxHeight && height <= maxHeight) {
      height = maxHeight;
    } else if (calculatedHeight < minHeight) {
      height = minHeight;
    } else {
      height = calculatedHeight;
    }
  }

</script>


<div class="vertical-resizer {className}" data-cy={autoId}
     on:mousemove={moveHandler}
     on:touchmove={moveHandler}

     on:mouseleave={mouseUpHandler}
     on:mouseup={mouseUpHandler}

     on:touchleave={mouseUpHandler}
     on:touchcancel={mouseUpHandler}
     on:touchend={mouseUpHandler}
>
  <div class="resizer-wrapper" bind:this={resizeWrapperElement} style="height: {height}px;">
    <slot></slot>
  </div>
  <div class="resize-handle"
       class:hidden={!isInteractive}
       bind:this={resizeHandlerElement}
       on:mousedown|preventDefault={mouseDownHandler}
       on:touchstart|preventDefault={mouseDownHandler}
       data-cy="vertical-resizer-handle"
  >
    <div class="resize-button" bind:this={resizeButtonElement} class:active={height < maxHeight}>
      <div class="material-icons-outlined">
        menu
      </div>
    </div>
  </div>
</div>


<style lang="scss">
  .resizer-wrapper {
    overflow-y: hidden;
  }

  .hidden {
    display: none;
  }

  .resize-handle {
    width: 100%;
    user-select: none;
    border-top-color: #cccccc;
    border-top-width: 1px;
    border-top-style: solid;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 1px;
    //Used to cheat on the mouse up handler if the user drags down quickly
    padding-bottom: 15px;
  }

  .resize-button {
    cursor: pointer;
    width: 145px;
    height: 16px;
    background-color: #ced1d9;
    border-radius: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .resize-button .material-icons-outlined {
    font-size: 20px;
    color: #FFFFFF;
    line-height: 16px;
  }

  .resize-button.active {
    background-color: #0071e8;
  }

</style>

