import {headers} from 'src/services/auth';

const baseURL = (merchantId) => `${window.INTEGRATIONS_ENGINE_URL}integrations/api/merchants/${merchantId}/preferences`;

export function getSurveyFrequency(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/guest-survey-frequency`);

    return fetch(url.toString(), {
        method: 'GET',
        headers: headers,
    }).then(result => result.json());
}

export function getSurveyDelay(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/guest-survey-delay`);

    return fetch(url.toString(), {
        method: 'GET',
        headers: headers,
    }).then(result => result.json());
}

export function getDeviceLimit(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/guest-device-limit`);

    return fetch(url.toString(), {
        method: 'GET',
        headers: headers,
    }).then(result => result.json());
}

export function getSendingFeedback(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/v2/disable-sending-feedback-emails`);

    return fetch(url.toString(), {
        method: 'GET',
        headers: headers,
    }).then(result => result.json());
}

export function getReceiptScanning(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/v2/receipts-processor`);

    return fetch(url.toString(), {
        method: 'GET',
        headers: headers,
    }).then(result => result.json());
}

export function updateSurveyFrequency(merchantId, durationInMinutes) {
    const url = new URL(`${baseURL(merchantId)}/guest-survey-frequency?durationInMinutes=${durationInMinutes}`);
    
    return fetch(url.toString(), {
        method: 'PUT',
        headers: headers
    }).then(result => result);
}

export function updateSurveyDelay(merchantId, durationInMinutes) {
    const url = new URL(`${baseURL(merchantId)}/guest-survey-delay?durationInMinutes=${durationInMinutes}`);
    
    return fetch(url.toString(), {
        method: 'PUT',
        headers: headers,
    }).then(result => result);
}

export function updateDeviceLimit(merchantId, durationInMinutes) {
    const url = new URL(`${baseURL(merchantId)}/guest-device-limit?durationInMinutes=${durationInMinutes}`);
    
    return fetch(url.toString(), {
        method: 'PUT',
        headers: headers,
    }).then(result => result);
}

export function updateSendingFeedback(merchantId, action) {
    const url = new URL(`${baseURL(merchantId)}/v2/disable-sending-feedback-emails/${action}`);

    return fetch(url.toString(), {
        method: 'PUT',
        headers: headers,
    }).then(result => result);
}

export function updateReceiptScanning(merchantId, action) {
    const url = new URL(`${baseURL(merchantId)}/v2/receipts-processor/${action}`);

    return fetch(url.toString(), {
        method: 'PUT',
        headers: headers,
    }).then(result => result);
}
   
export function deleteSurveyFrequency(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/guest-survey-frequency`);

    return fetch(url.toString(), {
        method: 'DELETE',
        headers: headers,
    }).then(result => result);
}

export function deleteSurveyDelay(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/guest-survey-delay`);

    return fetch(url.toString(), {
        method: 'DELETE',
        headers: headers,
    }).then(result => result);
}

export function deleteDeviceLimit(merchantId) {
    const url = new URL(`${baseURL(merchantId)}/guest-device-limit`);

    return fetch(url.toString(), {
        method: 'DELETE',
        headers: headers,
    }).then(result => result);
}