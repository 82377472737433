<script>
  /***** operational-category-reason Component *****
   * Shows an operational category reason and colors it based on it being positive or negative
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param label {String} - The text to display for the reason
   * @param impact {Number} - -1 for a bad impact, 1 for a positive, 0 is not supported at this time
   */

  export let autoId = 'operational-category-reason';
  export {className as class};
  export let label;
  export let impact;

  let className = '';

  $: ratingClass = impact === 1 ? 'positive' : 'negative';


</script>

<div class="operational-category-reason {className} {ratingClass}" data-cy={autoId}>
  {label}
</div>


<style lang="scss">
  .operational-category-reason {
    &.positive {
      color: #0f7344;
      border-color: #0f7344;
    }

    &.negative {
      color: var(--tattle-fail-color);
      border-color: var(--tattle-fail-color);
    }

    text-align: center;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 14px;
  }

</style>

