import {headers, baseURL} from 'src/services/auth';

/**
 * Retrieves transactions
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<Response>} A promise resolving to an array of transactions
 */
export function getTransactions(params) {
  const url = new URL(`${baseURL}/v2/api/transactions`);
  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.transactions);
}
