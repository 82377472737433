import { writable } from 'svelte/store';

export const globalFilterBarStore = writable({
  selectedLocations: [],
  allLocations: [],
  selectedGroups: [],
  questionnaires: [],
  shifts: {},
  dateRange: [],
  lastRefreshTimestamp: null
});
