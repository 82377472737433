import {baseURL, headers} from 'src/services/auth';

/**
 * Retrieves the survey for a given id
 * @param {String} surveyId The id for the survey
 * @returns {Promise<Object>} An object containing an array of snapshots
 */
export function getSurvey(surveyId) {
  return fetch(new URL(`${baseURL}/v2/api/survey/${surveyId}`), {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json());
}

/**
 * Retrieves the survey for a given set of parameters
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<Array>} An array of surveys that match the parameters
 */
export function getSurveyWithParameters(params) {
  const url = new URL(`${baseURL}/v2/api/survey`);
  let tail = '';

  // Have to do this because url search params will escape any + signs in the sort
  if (params.order) {
    if (Object.keys(params).length > 1){
      tail = `&order=${params.order}`;
    } else {
      tail = `?order=${params.order}`;
    }
    delete(params.order);
  }
  if (params.flag) {
    if (Object.keys(params).length > 1){
      tail += `&flag=${params.flag}`;
    } else {
      tail += `?flag=${params.flag}`;
    }
    delete(params.flag);
  }

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString() + tail, {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.survey);
}


/**
 * updates the survey location for a given id
 * @param {String} surveyId The id for the survey
 * @param {Object} locationId The new loc for the survey
 * @returns {Promise<Object>} An object containing the updated survey
 */
 export function updateSurveyLocation(surveyId, locationId) {
  const url = new URL(`${baseURL}/v2/api/survey/${surveyId}`);   // no baseURL ???
  const data = {locations_id: locationId};

  return fetch(url, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(data)
  })
    .then(result => result.json());
}


// POST https://tattle.local.com/v2/api/survey/2591393/flag
// {user_id: 1584150, comment: "This is a test flag"}
/**
 * updates the survey location for a given id
 * @param {String} surveyId The id for the survey
 * @param {Object} locationId The new loc for the survey
 * @returns {Promise<Object>} An object containing the updated survey
 */
 export function flagSurveyFeedback(surveyId, userId, comment) {
  const url = new URL(`${baseURL}/v2/api/survey/${surveyId}/flag`);
  const data = {user_id: userId, comment: comment};

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  })
    .then(result => result.json());
}

/**
 * Retrieves the survey for a given set of parameters
 * @param {String} surveyId The id for the survey
 * @param {Object} params An object containing any parameters to add to the url
 * @returns {Promise<Array>} An array of surveys that match the parameters
 */
 export function getSingleSurveyWithParameters(surveyId, params) {
  const url = new URL(`${baseURL}/v2/api/survey/${surveyId}`);
  let tail = '';

  // Have to do this because url search params will escape any + signs in the sort
  if (params.expand) {
    if (Object.keys(params).length > 1){
      tail = `&expand=${params.expand}`;
    } else {
      tail = `?expand=${params.expand}`;
    }
    delete(params.expand);
  }

  if (params.order) {
    if (Object.keys(params).length > 1){
      tail = `&order=${params.order}`;
    } else {
      tail = `?order=${params.order}`;
    }
    delete(params.order);
  }
  if (params.flag) {
    if (Object.keys(params).length > 1){
      tail += `&flag=${params.flag}`;
    } else {
      tail += `?flag=${params.flag}`;
    }
    delete(params.flag);
  }

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString() + tail, {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json());
}
