<script>
  import Tooltip, {Wrapper, Content} from '@smui/tooltip';

  /***** summaryTile Component *****
   * Displays a summary and count for a given field
   *
   ******Parameters ******************************************************************************
   * @param autoId {String} - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param title {String} - The string to go next to the checkbox
   * @param count {String} - The formatted number to show below the checkbox
   * @param includeStar {Boolean} - If true, adds a star after the count
   * @param tooltip {Slot} - If provided, provides a question mark icon with the provided pop over info
   */

  export let autoId = 'summaryTile';
  export {className as class};

  export let title;
  export let count;
  export let includeStar = false;

  let className = '';

</script>

<div class="summary-tile {className}" data-cy={autoId}>
  <div class="summary-header">
    {title}
    {#if $$slots.tooltip}
      <Wrapper rich>
        <i class="fa fa-question-circle"></i>
        <Tooltip style="z-index: 9999;">
          <Content>
            <slot name="tooltip"></slot>
          </Content>
        </Tooltip>
      </Wrapper>
    {/if}
  </div>
  <div class="summary-value">
    {count}
    {#if includeStar}
      <i class="fa fa-star"></i>
    {/if}
  </div>
</div>


<style lang="scss">
  .summary-tile {
    background-color: #f7fafb;
    display: flex;
    flex-direction: column;
  }
  .summary-header {
    display: flex;
  }
  * :global(.mdc-tooltip-wrapper--rich) {
    margin-left: 7px;
  }
  .summary-value {
    text-align: center;
  }
</style>

