<script>
  import ManagerRepliesAndRewards
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/manager-replies-and-rewards/manager-replies-and-rewards.svelte';

  /***** enhanced-profile-replies-details-section.svelte Component *****
   * Contains the manager replies and reward details
   *
   ******Parameters ******************************************************************************
   * @param class {string} - Any classes to add to the component
   * @param tattle {Object} - The tattle object
   * @param location {Object} - The object returned from the location endpoint
   * @param address {Object} - The object returned from the address endpoint
   * @param incident {Object} - The object returned from the incident endpoint, this is optional
   * @param customerRewards {Array[]} An array of objects from the customer rewards endpoint with rewards expanded
   * @param filteredMerchantSurveys {Array} - An array containing all the customer's surveys for this merchant minus flagged feedback
   * @param flaggedSurveys {Array} - An array containing all the flagged customer's surveys for this merchant
   */

  const autoId = 'enhanced-profile-replies-section';
  export {className as class};

  export let tattle;
  export let messageLogs;
  export let address;
  export let incident;
  export let customerRewards;
  export let filteredMerchantSurveys;
  export let flaggedSurveys;



  let className = '';
</script>

<div class="enhanced-profile-replies-section {className}" data-cy={autoId}>
  <ManagerRepliesAndRewards
    surveyId={tattle.id}
    {messageLogs}
    {address}
    {incident}
    {customerRewards}
    {filteredMerchantSurveys}
    {flaggedSurveys}
  />
</div>


<style lang="scss">
  .enhanced-profile-replies-section {
    padding: 30px 20px 0;
  }


</style>

