<script>
  import {onMount} from "svelte";
  import DataTable from "tttl-core-ui/src/components/DataTable.svelte";
  import {Head, Body, Row, Cell, Label} from '@smui/data-table/styled';
  import IconButton from '@smui/icon-button';
  import TattleScoreDisplay from 'src/building-blocks/tattle-score-display.svelte';
  import {parseServerDate} from "src/services/utility/dateParser";


  /***** rewards-table Component *****
   * Displays the rewards generated from a given customer's survey
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param customerRewards {Array[]} An array of objects from the customer rewards endpoint with rewards expanded
   * @param address {Object} - The address object returned from the address endpoint
   * @param filteredMerchantSurveys {Array} - An array containing all the customer's surveys for this merchant minus flagged feedback
   * @param flaggedSurveys {Array} - An array containing all the flagged customer's surveys for this merchant
   * @param element {Element} - The dom element for this component (used to calculate height for the resizer)
   */

  export let autoId = 'rewards-table';
  export {className as class};

  export let customerRewards;
  export let address;
  export let filteredMerchantSurveys;
  export let flaggedSurveys;
  export let element;

  let className = '';

  let sort = 'date_time_ready';
  let sortDirection = 'ascending';
  let dateColumnElement;

  let rows = [];

  $: {
    rows = [];
    if (customerRewards?.length > 0 && filteredMerchantSurveys?.length > 0) {
      for (let i = 0; i < customerRewards.length; ++i) {
        const survey = filteredMerchantSurveys?.find((survey) => survey.id === customerRewards[i].customer_questionnaire_id) || flaggedSurveys?.find((survey) => survey.id === customerRewards[i].customer_questionnaire_id);

        rows.push(customerRewards[i]);
        rows[i].cer = survey?.rating;
        rows[i].score = survey?.score;

        rows[i].dateIssued = formatToDate(rows[i].date_time_ready);
        rows[i].timeIssued = formatToTime(rows[i].date_time_ready);

        rows[i].type = rows[i]?.reward.label || 'Unknown';
        rows[i].redeemedText = rows[i].is_redeemed === 0 ? 'Not Redeemed' : 'Redeemed';
      }
      handleSort();
      rows = rows;
    }
  }


  onMount(() =>{
    // Setting sortDirection descending doesn't update the internal store, so we cheat and set it on mount
    dateColumnElement?.getElement()?.click();
  });


  function formatToDate(datetimeString) {
    const dateObject = parseServerDate(datetimeString);
    return dateObject.toFormat('MM/dd/yy');
  }

  function formatToTime(datetimeString) {
    let dateObject = parseServerDate(datetimeString);
    //If there is no address timezone, just don't display it, should never happen
    if (address?.timezone) {
      dateObject = dateObject.setZone(address.timezone);
    }
    return dateObject.toFormat('h:mm a ZZZZ');
  }

  function handleSort() {
    rows = rows.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]];
      let first, second;

      if (sortDirection === 'ascending') {
        first = aVal;
        second = bVal;
      } else {
        first = bVal;
        second = aVal;
      }

      if (typeof aVal === 'string') {
        return first.localeCompare(second);
      }
      return first - second;
    });
    rows = rows;
  }


</script>

<div class="{className}" data-cy={autoId} bind:this={element}>
  <DataTable sortable bind:sort bind:sortDirection on:MDCDataTable:sorted={handleSort} class="rewards-table">
    <Head slot="head">
      <Row>
        <Cell columnId="type" class="align-left">
          <Label class="header-label align-left">
            Type
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="date_time_ready" class="align-left" bind:this={dateColumnElement}>
          <Label class="header-label align-left">
            Date Issued
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="timeIssued" class="align-left">
          <Label class="header-label align-left">
            Time
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="is_redeemed">
          <Label class="header-label">
            Status
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="cer">
          <Label class="header-label">
            CER
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
        <Cell columnId="score">
          <Label class="header-label">
            Score
          </Label>
          <IconButton class="material-icons">arrow_drop_up</IconButton>
        </Cell>
      </Row>
    </Head>
    <Body slot="body">
    {#each rows as row}
      <Row>
        <Cell style="font-weight: 500;" class="align-left">{row.type}</Cell>
        <Cell class="align-left">{row.dateIssued}</Cell>
        <Cell class="align-left">{row.timeIssued}</Cell>
        <Cell>
          <div class="redemption-text {row.is_redeemed === 0 ? 'no-redemption': 'redeemed'}">
            <div class="inner-redemption-text">
              {row.redeemedText}
            </div>
          </div>
        </Cell>
        <Cell>
          <TattleScoreDisplay class="score-display" starRating={row.cer}/>
        </Cell>
        <Cell>
          <TattleScoreDisplay class="score-display percentage" percentage={row.score}/>
        </Cell>
      </Row>
    {/each}
    </Body>
  </DataTable>
  {#if rows.length === 0}
    <div class="no-rewards" data-cy="no-rewards">
      <div class="no-rewards-wrapper">
        <div class="no-rewards-title">
          <span>
            No Rewards
          </span>
        </div>
      </div>
    </div>
  {/if}

</div>


<style lang="scss">
  * :global(.rewards-table) {
    border-style: none;
  }

  * :global(.rewards-table .score-display .score-box) {
    height: 30px;
  }

  * :global(.rewards-table .align-left) {
    text-align: left !important;
  }

  * :global(.rewards-table th.align-left span) {
    padding-left: 0 !important;
  }


  * :global(.rewards-table.tattle-table th.mdc-data-table__header-cell) {
    padding: 0;
  }

  * :global(.rewards-table.tattle-table table tbody tr) {
    height: 40px;
  }

  * :global(.rewards-table.tattle-table table tbody tr td.mdc-data-table__cell) {
    padding: 0;
    height: 40px;
  }

  * :global(.rewards-table.tattle-table .header-label) {
    font-size: 10px;
    //This is needed because the sort button is 28 px
    padding-left: 28px;
  }

  * :global(.rewards-table .tattle-score-display.score-display) {
    min-width: 0;
  }

  * :global(.rewards-table .score-display) {
    height: auto;
    border: none;
    width: auto;
  }

  * :global(.rewards-table .score-display .score-box) {
    height: 23px;
    width: 36px;
  }

  * :global(.rewards-table .score-display.percentage .score-box) {
    width: 46px;
  }

  .redemption-text {
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 7px;

    &.no-redemption {
      background-color: #ededed;
    }

    &.redeemed {
      background-color: #01b5eb;
      color: white;
    }
  }

  .no-rewards {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
  }

  .no-rewards-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: var(--tattle-subtitle-font-size);
  }

</style>

