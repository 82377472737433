<script>
    import List, {Item, Separator, Text} from '@smui/list/styled';
    import '@smui/list/bare.css';
    import './../../theme/colors.css';

    /***** ContextListItem.svelte Component *****
     * This is the invididual list item for the ContextMenu.  SMUI list item is used as a base.

     ******Parameters ******************************************************************************
     * @param on:click (function) - a function that fires when the list item is clicked
     * @param on:mouseenter (function) - fires when the mouse enters the element
     * @param on:mouseleave (function) - fires when the mouse leaves the element
     * @param class (String) - Any classnames needed
     * @param autoId - Cypress test autoId for the list item
     * @slot slot -  The contents of the item
     */
    export let autoId;
    export {className as class};

    let className;

    let element;

    export function getElement() {
        return element.getElement();
    }

</script>

<Item
        class={className}
        bind:this={element}
        on:click
        on:mouseenter
        on:mouseleave
        data-cy={autoId}>
    <slot/>
</Item>

<style lang="scss">

</style>