<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  const clickButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch('click');
  }
</script>

<div class="tag-menu-wrapper">
  <div class="tag-menu-content color-survey-tag" >
    <div class="tag-menu-tooltip" on:click={clickButton} >
      <span class="tag-tooltip-label">
        Edit Survey Tags
      </span>
      <span class="material-icons tag-icon" tabindex="0">sell</span>
    </div>
  </div>
</div>

<style lang="scss">
  .tag-menu-wrapper {
    flex: 1;
    margin: auto;
  }

  .tag-menu-content {
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    width: 130px;
  }

  .tag-menu-tooltip {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .tag-tooltip-label {
    text-wrap: nowrap;
  }

  .color-survey-tag {
    color: #0071E8;
  }
  
  .tag-icon {
    background-color: #0071E8;
    border-color: #0071E8;
    display: inline;
    color: white;
    border-radius: 50%;
    font-size: 14px;
    border-width: 2px;
    border-style: solid;
    margin-left: 5px;
    cursor: pointer;
  }

</style>