<script>
  import {replyTooltipStore} from 'src/stores/replyTooltipStore';
  import {afterUpdate} from 'svelte';
  import {onDestroy} from 'svelte';

  /***** reply-tooltip.svelte Component *****
   *
   * Tooltip popover for the Manager Replies section
   *
   * This tooltip positions itself based on the anchorElem (email icon) in the store
   *
   ******Parameters ******************************************************************************
   * @param autoId (string) - The auto id for Cypress E2E testing

   replyTooltipStore:

   anchorElem: target,
   isVisible: true,
   data: {
      messageType: row.message_type,
      htmlContent: row.message,
      dateSent: row.month_date_sent
    }

  */

  export let autoId = 'reply-tooltip';

  // for self-positioning
  const tooltipPos = {top: 0, left: 0};
  const offsetX = 0;
  const offsetY = 24;


  // TODO: This is really brittle and will most likely break if we have more than one drawer
  // switch to using parents of the current element
  function calculatePosition(anchorElem) {
    // this element - might not be in DOM yet
    let tooltip = document.getElementById('reply-tooltip');
    if (!tooltip) return;
    let tooltipRect = tooltip.getBoundingClientRect();

    let tooltipWidth = tooltipRect.width;
    let tooltipHeight = tooltipRect.height;

    // the envelope icon in this case
    let anchorElemRect = anchorElem.getBoundingClientRect();
    let anchorCenterX = Math.round(anchorElemRect.x) + anchorElemRect.width / 2;
    let anchorCenterY = Math.round(anchorElemRect.y) + anchorElemRect.height / 2;

    let drawer = document.getElementsByClassName('mdc-drawer')[0];    // strange!
    if (!drawer) return;
    let drawerRect = drawer.getBoundingClientRect();
    let drawerX = Math.round(drawerRect.x);
    let drawerY = Math.round(drawerRect.y);

    let drawerContent = document.getElementsByClassName('mdc-drawer__content')[0];
    if (!drawerContent) return;

    let availSpaceAbove = anchorCenterY - drawerY - offsetY - drawerContent.scrollTop;
    let newLeft = anchorCenterX - tooltipWidth / 2;              // center horizontally always
    let newTop = 0;

    // place above only if room otherwise always below
    if (availSpaceAbove >= tooltipHeight) {
      newTop = anchorCenterY - tooltipHeight - offsetY + drawerContent.scrollTop;        
    } else {
      newTop = anchorCenterY + offsetY + drawerContent.scrollTop;
    }

    // adjust placement to be relative to drawer not screen
    newTop -= drawerY;
    newLeft -= drawerX;

    tooltipPos.top = newTop;
    tooltipPos.left = newLeft;
  }


  afterUpdate(() => {
    let anchorElem = $replyTooltipStore.anchorElem;
    let isVisible = $replyTooltipStore.isVisible;
    let data = $replyTooltipStore.data;

    if (!anchorElem) return;        // not launched from UI yet
    if (!isVisible) return;
    if (!data) return;

    calculatePosition(anchorElem);

    // move to new pos while retaining visibility
    data.top = tooltipPos.top;
    data.left = tooltipPos.left;
    replyTooltipStore.set({
      anchorElem: anchorElem,
      isVisible: isVisible,
      data: data,
    })

  });

  const hideTooltip = () => {
    let data = $replyTooltipStore.data;
    replyTooltipStore.set({
      anchorElem: null,
      isVisible: false,
      data: data,
    })
  }

  onDestroy(() => {
    hideTooltip();
  })

</script>

{#if $replyTooltipStore.data}
  <div class="reply-tooltip"
       class:is-showing={$replyTooltipStore.isVisible}
       id="reply-tooltip"
       data-cy={autoId}
       style={`top: ${tooltipPos.top}px; left: ${tooltipPos.left}px`}
       on:click={hideTooltip}
  >
    <div class="tooltip-title" data-cy={autoId + '-title'} on:click={hideTooltip}>{$replyTooltipStore.data.messageType}
      Reply
      <div class="material-icons close-button" data-cy={autoId + '-close-button'}>close</div>
    </div>
    <div class="scroll-content" data-cy={autoId + '-scroll-content'}>
      <div class="html-content" data-cy={autoId + '-html-content'}>{@html $replyTooltipStore.data.htmlContent}</div>
      <div class="date-sent" data-cy={autoId + '-date-sent'}>{$replyTooltipStore.data.dateSent}</div>
    </div>
  </div>
{/if}

<style lang="scss">

  .reply-tooltip {
    position: absolute;
    // do not set height
    max-height: 350px;
    width: 500px;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 9999;
    color: #212529;
    background-color: #ffffff;
    font-family: "Helvetica Neue", sans-serif;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    /* scaling messes up getBoundingClientRect */
    transform: scale(1);
    transition: opacity .5s, transform .2s;
    cursor: default;
  }

  .tooltip-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 15px;
    text-align: left;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 500;
    line-height: 19px;
    color: #212529;
    background-color: var(--tattle-slideout-secondary-header-color);
    border-bottom: 1px solid #ebebeb;
  }

  .close-button {
    height: 22px;
    width: 22px;
    cursor: default;
  }

  .scroll-content {
    max-height: 310px; /* 40 less than main max-height 350 */
    overflow-y: auto;
  }

  .html-content {
    width: 100%;
    padding: 15px;
    text-align: left;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 21px;
    color: #212529;
  }

  .date-sent {
    width: 100%;
    padding: 0px 15px 15px 15px;
    text-align: left;
    font-size: 12px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 16px;
    color: #707070;
  }

  .is-showing {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

</style>
