<script>
  import FeedbackDetails
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/feedback-details-sections/feedback-details.svelte';
  import OrderDetails
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/feedback-details-sections/order-details.svelte';
  import LowCategoryPerformanceDetails
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/feedback-details-sections/low-category-performance-details.svelte';
  import PhotoAttachments
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/feedback-details-sections/photo-attachments.svelte';
  import MenuItemLevelFeedback
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/feedback-details-sections/menu-item-level-feedback.svelte';
  import MenuItems
    from 'src/pages/sidebars/enhanced-profile-sidebar/sections/feedback-details-sections/menu-items.svelte';

  /***** enhanced-profile-feedback-details-section.svelte Component *****
   * Displays the feedback section for the enhanced profile
   *
   ******Parameters ******************************************************************************
   * @param class {string} - Any classes to add to the component
   * @param tattle {Object} - The full tattle object
   * @param menuItems {Object} -  The menu items from this response's transaction
   * @param menuItemLevelFeedback {Object} -  The menu item level feedback from this response's Item Level Feedback survey
   */

  const autoId = 'enhanced-profile-feedback-section';
  export {className as class};

  export let tattle;
  export let survey;
  //Note that this can be undefined
  export let transaction;
  // for autocomplete choices
  export let locations;
  export let menuItems;
  export let menuItemLevelFeedback;

  let className = '';


</script>

<div class="enhanced-profile-feedback-section {className}" data-cy={autoId}>
  <FeedbackDetails class="section-wrapper" {tattle} {survey} {transaction} {locations}/>
  {#if transaction}
    <OrderDetails class="order-details-component section-wrapper" {transaction} {survey}/>
  {/if}
  {#if menuItems?.length > 0}
    {#if menuItemLevelFeedback?.data?.length > 0}
      <MenuItemLevelFeedback class="section-wrapper menu-item-level-feedback-component" menuItemLevelFeedback={menuItemLevelFeedback}/>
    {:else}
      <MenuItems class="section-wrapper menu-items-component" menuItems={menuItems}/>
    {/if}
  {/if}
  {#if survey?.recommendations?.categories && survey?.recommendations?.categories.length > 0}
    <LowCategoryPerformanceDetails class="section-wrapper low-category-performance-details" categories={survey.recommendations.categories}/>
  {/if}
  {#if survey?.photo}
    <PhotoAttachments class="section-wrapper photo-attachments-component" photo={survey.photo} />
  {/if}
</div>


<style lang="scss">
  .enhanced-profile-feedback-section {
    display: flex;

    border-color: #cccccc;
    border-style: solid;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  .enhanced-profile-feedback-section :global(.section-wrapper){
    border-left-width: 0 !important;
    flex-grow: 1;
  }
</style>

