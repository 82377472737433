<script>
  import {afterUpdate} from 'svelte';
  import Button, {Label} from '@smui/button';
  import MenuItemsModal from 'src/pages/sidebars/enhanced-profile-sidebar/modals/menu-items-modal.svelte';

  /***** menu-items Component *****
   * Displays a list of menu items
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param menuItems {Object} -  The menu items from this response's transaction
   */

  export let autoId = 'menu-items';
  export {className as class};

  export let menuItems;

  let className = '';
  let menuItemsElement;
  let showMoreOption = false;
  let showMenuItemsModal = false;
  let maxHeight = 270;


  afterUpdate(() => {
    getHeight(menuItemsElement);
  });

  function getHeight(element) {
    if (element != null) {
      showMoreOption = element.getBoundingClientRect().height >= maxHeight;
    } 
  }

</script>


<div class="menu-items-wrapper {className}" data-cy={autoId}>

  <div class="menu-items-header-title">
    Menu Items
  </div>

  <div class="menu-items-section" style="max-height: {maxHeight}px;" bind:this={menuItemsElement}>
    {#each menuItems as item}
      <div class="menu-item">
        <div class="menu-item-label">{item.itemLabel}</div>
        {#if item.note}
          <div class="menu-item-note">{item.note}</div>
        {/if}
      </div>
    {/each}
  </div>

  {#if showMoreOption}
    <div class="show-button">
      <Button on:click={() => { showMenuItemsModal = true; }}>
        <Label>More...</Label>
      </Button>
    </div>
  {/if}

</div>

<MenuItemsModal bind:show={showMenuItemsModal}
                menuItems={menuItems}/>


<style lang="scss">

  .menu-items-header-title {
    font-weight: 500;
    user-select: none;
    height: 52px;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 52px;
    margin-bottom: 20px;
  }

  .menu-items-section {
    font-size: var(--mdc-typography-caption-font-size);
    user-select: none;
    margin: 0px 30px;
    text-align: left;
    overflow-y: hidden;
    line-height: 14px;
    min-width: 150px;
  }

  .menu-item {
    padding-bottom: 15px;
  }

  .menu-item-label {
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding-bottom: 5px;
  }

  .menu-item-note {
    color: #828899;
  }

  .menu-items-wrapper {
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    max-width: 300px;
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .show-button {
    display: flex;
    justify-content: center;
    border-top: 1px solid #ccc;
  }

</style>
