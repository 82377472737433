<script>
  /***** photo-attachments Component *****
   * Shows a header along with a provided thumbnail image
   *
   ******Parameters ******************************************************************************
   * @param autoId (String) - The auto id for Cypress E2E testing
   * @param class {String} - Any classes to add to the component
   * @param photo {Object} - The .full and .thumb urls for the survey.photo image
   */

  import Dialog from '@smui/dialog';
  import IconButton from 'tttl-core-ui/src/components/IconButton.svelte';

  export let autoId = 'photo-attachments';
  export {className as class};
  export let photo;

  let className = '';
  let isDialogOpen = false;

  // show larger photo in a SMUI dialog
  // full path is same but "/thumb" is removed - still using explicit path here
  const onThumbnailClick = () => {
    isDialogOpen = true;
  }

  const onClose = () => {
    isDialogOpen = false;
  }


</script>

<div data-cy="photo-attachments-section" class="photo-attachments-wrapper {className}">
  <div data-cy="photo-attachments-title" class="photo-attachments-header">
    Photo Attachments
  </div>
  <div class="thumbnail-wrapper">
    <img data-cy="photo-attachments-thumbnail-photo" class="img-thumbnail" src={photo.thumb} alt="Customer Photo" on:click={onThumbnailClick} draggable=false />
  </div>

  <Dialog data-cy="photo-attachments-fullsize-container" class="photo-dialog"
    bind:open={isDialogOpen}
  >
    <div class="photo-header">
      <div data-cy="photo-attachments-fullsize-title" >Customer Photo</div>
      <IconButton autoId="photo-attachments-fullsize-close-button" class="material-icons close-button" on:click={onClose}>close</IconButton>
    </div>
    <div class="image-holder">
      <img data-cy="photo-attachments-fullsize-photo" class="img-fullsize" src={photo.full} alt="Customer Photo" draggable=false />
    </div>
  </Dialog>
</div>


<style lang="scss">
  .photo-attachments-wrapper {
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    max-width: 180px;
    border-top-width: 0;
    border-bottom-width: 0;
  }

  .photo-attachments-header {
    font-weight: 500;
    padding-bottom: 10px;
    user-select: none;
    height: 52px;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 52px;
    margin-bottom: 20px;
  }

  .thumbnail-wrapper {
    display: flex;
    align-items:flex-start;
    justify-content: flex-start;
    padding: 0;
    border: none;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
  }

  .img-thumbnail {
    max-width: 100px;
    max-height: 100px;
    border-radius: 10px;
    padding: 0px;
    border: none;
  }

  /* photo needs to correctly scale into a fixed sized box */
  * :global(.photo-dialog) {
    border-radius: 4px;
  }

  * :global(.mdc-dialog .mdc-dialog__surface) {
    max-width: 630px;
    width: 630px;
    max-height: 630px;
    height: 630px;
  }

  .photo-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 500;
    color: #1b1e21;
    padding: 7px 15px 7px 15px;
    border-bottom: 1px solid #e9ecef;
  }

  .image-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 520px;
    margin: auto;
  }

  .img-fullsize {
    max-width: 600px;
    max-height: 520px;
  }

  * :global(.close-icon) {
    color: #808080;
    font-size: 18px;
    margin-right: 5px;
  }


</style>

