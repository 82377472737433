<script>
  import { fade } from 'svelte/transition';
  import LayoutGrid, { Cell } from "@smui/layout-grid";
  import { getAllIntegrations } from "../../services/integrationsEngineApi/integrations";
  import IntegrationTile from "./integrationTile.svelte";
  
  export const pageId = "integrations";
  export let merchantId;
  export let ngStateService;

  let integrations, yourIntegrations, moreIntegrations, futureIntegrations = [];
  getAllIntegrations(merchantId).then(result => {
    integrations = result.sort((a, b) => a.label.localeCompare(b.label));
    yourIntegrations = integrations.filter(x => x.active);
    moreIntegrations = integrations.filter(x => !x.active && !x.comingSoon);
    futureIntegrations = integrations.filter(x => !x.active && x.comingSoon);
  });
</script>

{#if integrations && integrations.length}
<div in:fade>
  <h3 data-cy="integrations-title-your-integrations">Your Integrations</h3>
  {#if !yourIntegrations.length}
    <p>You currently have no active integrations.</p>
  {:else}
    <LayoutGrid class="p-x-0">
      {#each yourIntegrations as integration}
        <Cell class="integration-tile-cell">
          <IntegrationTile {integration} {merchantId} {ngStateService} />
        </Cell>
      {/each}
    </LayoutGrid>
  {/if}

  <h3 data-cy="integrations-title-more-integrations">{#if yourIntegrations.length}More {/if}Integrations</h3>
  <LayoutGrid class="p-x-0">
    {#each moreIntegrations as integration}
      <Cell class="integration-tile-cell">
        <IntegrationTile {integration} {merchantId} {ngStateService} />
      </Cell>
    {/each}
  </LayoutGrid>

  {#if futureIntegrations.length}
  <h3 data-cy="integrations-title-future-integrations">Coming Soon</h3>
    <LayoutGrid class="p-x-0">
      {#each futureIntegrations as integration}
        <Cell class="integration-tile-cell">
          <IntegrationTile {integration} {merchantId} {ngStateService} />
        </Cell>
      {/each}
    </LayoutGrid>
  {/if}
</div>
{/if}

<style global lang="scss">
  .integration-tile-cell,
  .integration-tile,
  .integration-tile > .mdc-card__primary-action {
    height: 100%;
  }

  .p-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
</style>
