import {headers, baseURL} from 'src/services/auth';

/**
 * Retrieves the message templates
 * @param params {Object} An object containing any parameters to add to the url
 * @returns {Promise<any>} A promise that resolves to the array of message templates
 */
export function getMessageTemplates(params) {
  const url = new URL(`${baseURL}/v2/api/message-templates`);
  let tail = '';

  //Have to do this because url search params will escape any + signs in the sort
  if (params.sort) {
    if (Object.keys(params).length > 1){
      tail = `&sort=${params.sort}`;
    } else {
      tail = `?sort=${params.sort}`;
    }
    delete(params.sort);
  }

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString() + tail, {
    method: 'GET',
    headers: headers,
  })
    .then(result => result.json())
    .then(result => result._embedded.message_templates);
}
