<script>
    import IconButton from '@smui/icon-button';

    /***** IconButton.svelte Component *****
     * Based on the Svelte Material Icon Button: https://sveltematerialui.com/demo/icon-button/
     *
     ******Parameters ******************************************************************************
     * @param on:click - fired whenever the button is clicked
     * @param autoId (string) - The auto id for Cypress E2E testing
     * @param class {string} - Any classes to add to the component
     * @slot - The contents of the icon button
     */

    export let autoId;
    export {className as class};

    let className;

</script>

<div>
    <IconButton class={className} data-cy={autoId} on:click>
        <slot/>
    </IconButton>
</div>


<style lang="scss">

</style>

